@import '../../assets/scss/variables';

@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}

%backgroundBorder {
  background: #feffff;
  border: 1px solid #d3dee8;
  border-radius: 6px;
}

.talentReviewStatus {
  font-family: 'Rubik', sans-serif;
  padding: 23px 19px;
  min-height: 351px;
  @extend %backgroundBorder;

  .talentReviewTitle {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin-bottom: 52px;
    color: #3f3c4c;

    @include flex(flex-start, space-between);
  }

  .talentReviewList {
    text-align: center;

    .talentReviewItem {
      @include flex(stretch, space-between);
      text-decoration: none;

      p {
        &:first-child {
          font-family: $inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #3f3c4c;
          pointer-events: none;
        }

        &:last-child {
          font-family: $inter;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #185c9b;
          cursor: pointer;

          &:hover {
            color: #68b3f2;
          }
        }
      }
    }
  }

  .talentReviewTotal {
    text-align: center;
    margin-bottom: 52px;

    .totalNumber {
      cursor: pointer;
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 38px;
      color: #185c9b;
      &:hover {
        color: #68b3f2;
      }
    }

    .totalTitle {
      font-family: $inter;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #3f3c4c;
    }
  }
}
