@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;
$btnColor: #344563;
$white: #fff;
$colorTextSize: #b1b9c6;
$tableColor: rgba(151, 151, 151, 0.5);
$borderItemColor: #d9d9d9;

.view_note {
  max-width: 1063px !important;
  font-family: $inter;

  .modal_header {
    padding: 40px 40px 34px 40px;
    width: 1063px;
    border: none;
  }

  .content {
    padding: 20px 24px 20px 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 0 15px;

    .button {
      @include flex(center, center);
      cursor: pointer;
      background-color: white;
      height: 36px;
      color: #00a19c;
      gap: 10px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 6px;
      margin-right: 0px;
      padding: 0 10px;
      border: 1px solid #00a19c;
    }

    .button_add {
      @include flex(center, center);
      cursor: pointer;
      background-color: #00a19c;
      height: 36px;
      color: $white;
      gap: 10px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 6px;
      margin-right: 0px;
      padding: 0 10px;
    }
  }

  .modal_body {
    padding: 25px 20px 25px 25px;
  }

  .disclaimer_title {
    font-weight: 700;
    font-size: 16px;
  }

  .disclaimer {
    margin: 0 40px 14px 40px;

    .info_disclaimer {
      color: $teal;
      margin-right: 0.4ch;
      font-weight: 600;
      position: relative;

      .records {
        position: absolute;
        padding: 12px;
        top: 23px;
        left: 5px;
        transform: translateX(-50%);
        color: #161616;
        font-size: 12px;
        background-color: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 0;
        visibility: hidden;

        &::after {
          content: '';
          position: absolute;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 8px solid $white;
          left: 50%;
          top: -7px;
          display: block;
          width: 12px;
          height: 8px;
          transform: translateX(-50%);
        }

        ul {
          padding: 0;
          margin: 0;

          &:first-child {
            margin-top: 4px;
          }
        }

        li {
          font-weight: 400;
          margin-left: 20px;
        }
      }

      img:hover {
        & + .records {
          height: fit-content;
          visibility: visible;
          z-index: 1;
        }
      }
    }
  }

  .menu {
    .content_general {
      padding: 20px 16px;
      width: 100%;
      border-radius: 6px;
      height: 100%;

      .content_document_left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        .circle {
          border-radius: 50px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .circle_active {
          border-radius: 50px;
          width: 8px;
          height: 8px;
          background: white;
        }
      }

      .content_document_right {
        width: 100%;

        .content_file {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          gap: 10px;
          width: 100%;
          position: relative;
          padding-right: 24px;

          img {
            width: 20px;
            height: 23px;
          }

          .file_info {
            display: flex;
            align-items: center;
            gap: 10px;

            .file {
              min-width: 40px;
              min-height: 44px;
              border-radius: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .file_name {
              font-family: $inter;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #3f3c4c;
              display: inline-block;
              word-break: break-all;
              padding-right: 10px;
            }
          }
        }

        .info_size_lasted_file {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 12px;
          color: $colorTextSize;

          .file_size {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .content_footer {
    padding: 1rem 2.2rem 1rem 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .button_cancel {
      @include flex(center, center);
      cursor: pointer;
      background-color: $white;
      width: 110px;
      height: 36px;
      color: #00a19c;
      gap: 10px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 4px;
      margin-right: 0px;
      border: 1px solid #00a19c;
    }

    .button_delete {
      @include flex(center, center);
      cursor: pointer;
      background-color: #ff787f;
      width: 110px;
      height: 36px;
      color: $white;
      gap: 10px;
      font-weight: 600;
      font-size: 14px;
      border-radius: 6px;
      margin-right: 0px;
      padding: 0 10px;
    }
  }
}
