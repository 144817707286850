@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
.dropdown {
  position: relative;
  font-family: $inter;
  @include showText(12px, 700, none, 16px);
  background: #fbfdff;
  @include flex(center, space-between);
  &Btn {
    border: 1px solid #00A19C;
    padding: 7px 16px; ////Top
    background: #fbfdff;
    @include flex(center, space-between);
    cursor: pointer;
    border-radius: 4px;
    height: 29px;
    width: fit-content;
    color: #00A19C;

    img {
      width: 7px;
      height: 4px;
      margin-left: 9px;
      filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);

    }
  }
  &Content {
    position: absolute;
    background: white;
    font-weight: 500;
    top: 30px;
    width: 100%;
    right: 0;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
  &Item {
    font-family: $inter;
    @include showText(14px, 500, none, 22px);
    cursor: pointer;
    color: $dark-blue;
    z-index: 100;
    &:hover {
      background: white;
    }
    height: 41px;
    @include flex(center, center)
;
  }
}

.disable-all-option {
  color: gray !important;
  cursor: no-drop;
}