@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.progress_meeting_details {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 23px 32px;
  margin-bottom: 20px;
  font-family: $inter;

  .step_children {
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 20px;
  }

  .title_progress_meeting {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #344563;
  }

  .progress_bar {
    position: relative;
    background: #e0e0e0;
    width: 100%;
    height: 13px;
  }

  .progress_bar_completed {
    position: absolute;
    background: #8b5ca7;
    width: 50%;
    height: 13px;
  }

  .step {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    font-family: $inter;
  }

  .step_1 {
    position: absolute;
    bottom: -11px;
    left: -1px;
    // z-index: 10;
  }

  .step_2 {
    position: absolute;
    bottom: -11px;
    left: 50%;
  }

  .step_3 {
    position: absolute;
    bottom: -11px;
    left: 100%;
  }

  .circle_active_completed {
    background: #1bbcb7;
    box-shadow: 0px 2px 3px rgba(75, 74, 78, 0.2);
    border-radius: 100px;
    width: 38px;
    height: 38px;

    &::before {
      position: absolute;
      content: ' ';
      border-right: 4px solid white;
      border-bottom: 4px solid white;
      top: 6px;
      left: 15px;
      width: 10px;
      height: 20px;
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .circle_active_pending {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #1bbcb7;
    box-shadow: 0px 2px 3px rgba(75, 74, 78, 0.2);
    border-radius: 100px;
    width: 38px;
    height: 38px;
    color: white;
  }

  .circle_inactive {
    display: flex;
    justify-content: center;
    align-items: center;

    background: #ffffff;
    color: #f1f2f3;
    border: 1px solid #dfe1e3;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(75, 74, 78, 0.2);
    border-radius: 100px;
    width: 38px;
    height: 38px;
  }

  .name_phase {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}