@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
.talentSketch {
  &__filter {
    font-family: $inter;
    @include flex(flex-start, flex-start);
  }
  &__h3 {
    font-weight: 600;
    color: $dark-gray;
    font-size: 24px;
    margin-bottom: 16px;
  }
  &__list {
    list-style-type: none;
    @include flex(flex-start, flex-start, row, wrap);
    padding-left: 0;
    margin-bottom: 3px;
  }
  &__item {
    font-size: 14px;
    color: $teal;
    padding: 7px 16px;
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;
    margin-right: 14px;
    margin-top: 5px;
  }
}
