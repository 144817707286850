@import '../../../../../assets/scss/variables';

.body {
  .rowInput {
    display: flex;
    gap: 17px;
    padding-bottom: 31px;
    align-items: center;
  }

  .inputContainer {
    flex-grow: 1;
  }
}

.optWrapper {
  display: flex;
  gap: 18px;
  align-items: center;
  padding: 5px 10px;
  min-height: 52px;

  .optLeft {
    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
  }

  .optRight {
    font-family: $inter;

    .optName {
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;
      color: $black;
      line-height: 20px;
    }

    .optText {
      font-size: 12px;
      color: $grey;
      line-height: 16px;
    }
  }
}
