@import '../../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;
  padding: $pdcPrintPadding;
}

.title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  color: #3f3c4c;
  margin-bottom: 16px;
  align-items: center;
}

.cardTitle {
  font-family: $inter;
  font-size: 18px;
  font-weight: 700;
  color: #3d3d3d;
  margin-bottom: 12px;
}

.flexContainer {
  display: flex;
}

.tableHeading {
  flex: 1;
  display: flex;
  padding: 16px;
  align-items: center;
  border: 1px solid #d3dee8;
  color: #ffffff;
}

.heading1 {
  background: #00a19c;
}

.heading2 {
  background: #ff7e01;
}

.tableCell {
  flex: 1;
  display: flex;
  padding: 16px;
  align-items: center;
  border: 1px solid #e7edf3;
  min-height: 230px;
}
