@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.dropdown {
  // width: 73px;
  position: relative;
  &__select {
    border: 1px solid #00A19C;
    border-radius: 4px;
    height: 29px;
    cursor: pointer;
    background: white;
    p {
      margin-bottom: 0;
      @include showText(12px, 700, none, 16px);
      font-family: $inter;
      color: #00A19C;
    }
    img {
      width: 7px;
      height: 4px;
      margin-left: 8px;
      filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);

    }
    width: 100%;
    padding: 7px 12px 6px 17px;
    @include flex(center, space-between);
  }
  &__list {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #ffffff;
    // padding: 14px 0 0 26px;
    width: 181px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 10;
  }
  &__item {
    &:first-child {
      padding-top: 16px;
    }
    padding-bottom: 16px;
    padding-left: 22px;
  }
  &__apply {
    border-top: 1px solid #d8d8d8;
    @include flex(center, flex-end);
    padding: 8px 7px 8px 0px;
  }
  &__btn {
    border: none;
    outline: none;
    width: 97px;
    height: 19px;
    vertical-align: middle;
    border-radius: 22px;
    background-color: $teal;
    font-family: $inter;
    @include showText(10px, 400, none, 10px);
    color: white;
  }
}
