@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.search_notFound {
  height: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.search_list {
  list-style-type: none;
  margin: 0;
  width: 100%;
  min-height: 36px;
  border-radius: 3px;
  border: 1px solid #e9edf0;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 0;
  background-color: #fff;
  color: #000;
  position: absolute;
  bottom: 0;
  z-index: 10;
  transform: translateY(102%);
  @include flex(flex-start, center, column);

  .dot_flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin-left: 28px;
  }

  .dot_flashing::before,
  .dot_flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot_flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot_flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
}
