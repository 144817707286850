@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.form_wrapper {
  background: #e2f1fc;
  border: 1px solid #003f9a;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0 4px 4px 0;
  border-left-width: 4px;
  border-color: #003f9a;
  padding: 20px 12px;
  position: relative;
}

.title_info {
  font-family: $inter;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 700;
  font-size: 16px;
  color: #003f9a;
}

.content {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #003f9a;
  padding: 10px 0 0 30px;
  margin: 0;
}

.content_li {
  font-weight: 400;
  margin: 8px 0 0 25px;
}

.only_one_item {
  margin-left: 0 !important;
  list-style: none !important;
}

.close_btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
