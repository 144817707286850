@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.main {
  padding: 33px 26px 70px 54px;
  background-color: white;
  position: relative;
}

.grBtn {
  position: absolute;
  right: 0;
  bottom: -54px;
  height: 30px;
  gap: 8px;

  button {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
  }

  .cancel {
    color: #00a19c;
    border: 1px solid #e9edf0;
  }

  .add {
    color: white;
    background-color: #00a19c;
    border: 1px solid #00a19c;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #3f3c4c;
}

.customize {
  label {
    color: #525252;
    text-transform: none;
    font-weight: 500;

    &::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
    }
  }

  .input {
    height: 44px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #d3dee8;
    border-radius: 4px;
  }

  .input:focus {
    border-color: #00a19c;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .input:disabled {
    background: white;
    color: #787587;
  }
}

.positionCodeError {
  border-color: #f85032;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 48px;
  margin-top: -10px;
}

.subText {
  display: block;
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5e6c84;
}
