@import '../../../../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  padding: 26px 33px;
  border-radius: 6px;
  margin-bottom: 32px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
  }

  label {
    color: $Gray32;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: none;
  }

  .positionName {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;

    &:read-only {
      background-color: #e0e0e0;
    }
  }

  input {
    font-size: 14px;
  }
}
