@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.search_item {
  padding: 15px;
  border-bottom: 1px solid $borderColor;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: $light-pink;
  }
  .individual {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $textColor;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .name {
      color: $nameTextColor;
      font-weight: 600;
      line-height: 16px;
      p {
        margin-bottom: 0;
      }
    }
  }
}
