.notFound {
  width: 100%;
  margin: 24px 0px;
}

.noField {
  font-size: 14px;
  font-weight: 700;
  color: #8896a7;
}

.staffId {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
}

.textField {
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #999a9c;
}
