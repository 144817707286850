@import '../../../../assets/scss/variables';

.control {
  width: 100%;
  margin-bottom: 22px;
  cursor: pointer;

  .btnPrint {
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #00a19c;

    background-color: #fbfdff;
    border: 1px solid #00a19c;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 8px 16px;
    height: 32px;

    &:hover:enabled,
    &:active:enabled {
      background-color: #eaf4fd;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
