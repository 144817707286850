@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.container {
  @include flex(flex-start, flex-start, column);
  padding-bottom: 30px;
  width: 100%;
  background: $light-blue;
  border-radius: 6px;
}

.container_print {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  border-radius: 6px;
}

.wrapperHeader {
  width: 100%;
  @include flex(center, space-between);
  margin-bottom: 36px;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3d3d3d;
}

.groupTopBtn {
  @include flex(center);
}

.wrapperContent {
  @include flex(stretch, space-between);
  width: 100%;
  background-color: $white;
}

.editButton {
  align-self: center;
  cursor: pointer;
}

.wrapperNonContent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 71px 0;
  background: $white;
}
