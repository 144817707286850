@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';

.title {
  color: #3d3d3d;
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 14px;
}

.sub_title {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #525252;
}

.margin {
  margin-bottom: 30px;
}

.btn {
  &:active {
    transform: translateY(1px);
  }
  &:hover {
    cursor: pointer;
  }
}
