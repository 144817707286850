@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.btn {
  width: 110px;
  height: 40px;
  padding: 12px 30px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  @include flex(center, center);
  &__label {
    font-family: $inter;
    @include showText(14px, 400, none, 16.94px);
    letter-spacing: 0.58px;
    &--blue {
      color: $dark-blue;
    }
    &--white {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  &__bg {
    &--white {
      background-color: $white;
    }
    &--blue {
      background-color: #00a19c;
    }
  }
  &__margin-left {
    margin-left: 16px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}
