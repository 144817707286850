@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.edge {
  padding: 20px 12px;
  background: #f4f5f8;
  border-radius: 6px;
  margin-bottom: 18px;
  &__h3 {
    @include showText(24px, 600, none, 29.05px);
    font-family: $inter;
    color: $dark-gray;
    margin-bottom: 20px;
  }
  &__header {
    margin-bottom: 20px;
    background: rgba(0, 0, 0, 0.0001);
  }
  &__filter {
    @include flex(stretch, flex-end);
    height: 29px;
  }
  &__dropcs {
    margin-left: 20px;
  }
  &__chart {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
.feedback {
  padding: 25px 26px 18px 26px;
  background: #f4f5f8;
  font-family: $inter;
  &__header {
    padding-bottom: 65px;
    @include flex(center, space-between);
  }
  &__h3 {
    @include showText(24px, 600, none, 29.05px);
    letter-spacing: 0.3px;
    color: $dark-gray;
  }
  &__list {
    @include flex(stretch);
    padding-bottom: 16px;
  }
  &__item {
    width: 232px;
    // height: 263px;
    border-radius: 6px;
    border: 1px solid #cdc9c9;
    position: relative;
    padding: 19px 18px 20px 22px;
    background-color: white;
    &:not(:first-child) {
      margin-left: 33px;
    }
  }
  &__ball {
    position: absolute;
    width: 76px;
    height: 76px;
    background-color: $green;
    border-radius: 50%;
    @include flex(center, center);
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    @include showText(14px, 700, none, 16.94px);
    font-family: $inter;
    letter-spacing: 0.3;
    color: white;
  }
  &__title {
    @include showText(14px, 700, none, 16.94px);
    letter-spacing: 0.3px;
    color: $dark-blue;
    text-align: end;
  }
  &__type,
  &__year {
    @include showText(12px, 700, none, 14.52px);
    letter-spacing: 0.3px;
    color: $dark-blue;
    margin-bottom: 0;
  }
  &__year {
    margin-bottom: 24px;
  }
  &__score {
    @include showText(12px, 700, none, 14.52px);
    color: $dark-blue;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
  }
  &__desc {
    @include showText(13px, 400, none, 16px);
    color: #000;
  }
  &__viewMore {
    margin-top: 20px;
    cursor: pointer;
    @include flex(center, flex-end);
    p {
      margin-bottom: 0;
      font-family: $inter;
      @include showText(12px, 700, uppercase, 20px);
      color: $dark-blue;
      margin-right: 5px;
    }
  }
  &__arrowRight {
    margin-right: 10px;
    width: 11px;
    height: 11px;
  }
  &__pen {
    width: 15px;
    height: 15px;
  }
  .model-header {
    width: 1000px;
  }
  &__viewMoreText {
    @include flex(center, center);
  }
}
