.name {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.0168em;
  color: #4f5f6e;
  display: block;
}

.email{  
  letter-spacing: 0.0168em;
  color:#95A3B2;
}

.textField {
  font-size: 14px;
  letter-spacing: 0.0168em;
  color: #7A7A7A;
}
