@import '../../assets/scss/variables';

.wrapper {
  font-family: $inter;
  width: fit-content;

  .applyBtn {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    padding-top: 0;
  }

  .suffixIcon {
    border: none;
    background-color: transparent;
    display: block;
    padding: 0;
    margin: 0;
  }

  .collapsed {
    transform: rotate(180deg);
  }

  :global {
    .ant-tree-select {
      color: #3f3c4c !important;
    }

    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      width: 0;
      height: 0;
      user-select: none;
      padding: 0;

      .ant-select-selection-overflow {
        display: none;
      }
    }

    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: $teal;
      border-color: $teal;
    }

    .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
      border-color: $teal;
    }

    .ant-select-tree-checkbox-checked::after {
      border: 1px solid $teal;
    }

    .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
      background-color: $teal;
    }

    .ant-select-selection-search {
      display: none;
    }

    .ant-select-tree {
      padding: 12px;
    }

    .ant-select-tree-switcher {
      margin: 0 8px 0 6px;
      width: 12px;
      transform: translateY(-2px);
    }

    .ant-select-tree-checkbox {
      margin: 4px 8px 0 0px;
    }

    .ant-select-tree-checkbox-inner {
      border-radius: 4px;
    }

    .ant-select-tree-list-holder {
      overflow-x: hidden;
    }

    .ant-select-tree-title {
      color: #3f3c4c !important;
      font-weight: 400;
      font-size: 14px;
    }

    .ant-select-tree-node-content-wrapper {
      padding-left: 0;
      flex: none !important;
      max-width: 300px;
      word-break: break-word;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: $teal;
    }

    .ant-radio-inner::after {
      background-color: $teal;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $teal;
    }

    .ant-radio-wrapper {
      color: #3f3c4c;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
      width: 100%;
    }

    .ant-select-tree-list-scrollbar-vertical {
      visibility: visible !important;
      background-color: #c4c4c4;
      width: 2px !important;

      .ant-select-tree-list-scrollbar-thumb {
        background: #4a4a4a80 !important;
      }
    }
    
    .ant-select-tree-switcher-icon{
      width: 10px;
      height: 10px;
    }
  }
}
