@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.collapse {
  width: 100%;

  th {
    padding: 0;
  }

  .collapseHeader {
    height: 33px;
    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 20px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: transform 333ms ease-out;
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }

    .animate {
      -ms-transform: rotate(0);
      transform: rotate(0);
    }
  }

  .collapseBody {
    min-height: 15px;
    border-collapse: separate;
    border-spacing: 12px;
    display: block;
    border: 1px solid #d3dee8;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 18px;
    padding-bottom: 0;
    transition: all 0.35s ease;
    overflow: hidden;

    tr {
      td {
        width: 33%;
        height: 1px;
      }
    }
  }

  .icon {
    position: absolute;
    right: 0;
    transform: translate(-60px, -28px);
    cursor: pointer;
    user-select: none;
    z-index: 1;
  }

  .image {
    position: relative;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .name {
    color: #50a4b9;
    font-family: $inter;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .card_box {
    height: 100%;
    font-family: $inter;
    font-size: 13px;
    font-weight: 400;
    color: #525252;
    border: 1px solid #e7e7f0;
    border-radius: 6px;
    padding: 10px;
    .number {
      font-weight: bold;
      font-size: 14px;
      color: #3f3c4c;
      line-height: 20px;
    }

    span {
      font-weight: 500;
    }

    .icon_card {
      img {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .left {
      padding: 10px;
      .position {
        font-size: 8px;
      }
    }

    .right {
      padding: 10px;
    }
  }

  .recommendation {
    &__name {
      display: inline;
      text-decoration: none;
      font-family: $inter;
      @include showText(14px, 600, none, 20px);
      color: $recommnendationText;
      &:hover {
        text-decoration: none;
        color: #0056b3;
      }
    }
  }

  .mainAgendaStatus {
    padding: 6px 12px;
    border-radius: 4px;
    color: #3d3d3d;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
    background-color: #ebebeb;
    margin-top: 5px;
  }
}

@media print {
  body {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }

  .container {
    border: 1px solid #d3dee8;
    border-radius: 6px;
    border-top: none;
    margin-bottom: 18px;

    .printTable {
      tbody {
        display: table-row-group !important;
        max-height: fit-content !important;
        border: none;
        td {
          padding: 8px;
        }
      }
    }
  }
}

@page {
  size: 'A4';
  margin-top: 20px;
}
