@import '../../../../../../../assets/scss/variables';

.wrapper {
  background-color: #f4f5f8;
  padding: 26px 33px;
  border-radius: 6px;
  margin-bottom: 24px;
  position: relative;

  td > div {
    height: 60px;

    & > div,
    & > label,
    & > button,
    & > input {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #787587;
  }

  .deleteCriteriaBtn {
    margin-right: -20px;
    cursor: pointer;
  }

  .edgeContent {
    height: 17px;
    line-height: 17px;
    letter-spacing: 0.3px;
    margin-bottom: 43px;
  }

  .magicInput {
    padding: 0 11px;
    width: 78px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &:disabled {
      background: #f5f5f5;
      border-color: #d3dee8;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .deleteBtn {
    width: 38px;
    height: 38px;
    background: #fbfdff;
    border: 1px solid #e9edf0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    &:hover {
      background: #e7e7e7;
    }

    &:not(:disabled):active {
      transform: translateY(-40%);
    }

    &:disabled {
      background: $bright-gray;
      border: 1px solid $bright-gray;
      cursor: no-drop;
    }
  }
}

.setContainer {
  padding: 39px 28px 0;
  border-radius: 6px;
  background: #ffffff;
}

// .setHeader {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
// }

.title {
  margin-bottom: 20px;
  color: #3f3c4c;
  font-size: 28px;
  font-family: $inter;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.12px;
}

.mainTitle {
  margin-bottom: 20px;

  h3 {
    color: #677c91;
    font-size: 24px;
    font-family: $inter;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.12px;

    span {
      color: #3f3c4c;
    }
  }
}

.setStatus {
  color: #7a7a7a;
  font-size: 12px;
  font-family: $inter;
  font-weight: 500;

  span {
    &:first-child {
      margin-right: 24px;
    }
  }
}
