@import '../../../../../../../assets/scss/variables';
@import '../../../../../../../assets/scss/mixin';

.title {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.sub_title {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #525252;
}

.margin {
  margin-bottom: 30px;
}
.btn {
  padding: 12px 16px;
  width: 120px;
  height: 44px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #d3dee8;
  border-radius: 4px;
  margin-right: 10px;

  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
}

.disabled {
  padding: 12px 16px;
  width: 120px;
  height: 44px;
  border: 1px solid #d3dee8;
  border-radius: 4px;
  color: #ffffff;
  background: #afdddc;

  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.confirm {
  background: #009089;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
}

.btn_upload {
  padding: 12px 16px;
  width: 120px;
  height: 44px;
  background: #fff;
  border: 1px solid #00746b;
  border-radius: 4px;
  color: #00746b;

  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
}

.upload {
  width: 100%;
  min-height: 400px;
  background: #ffffff;
  border: 1px dashed #26afab;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.title_upload {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.0192em;

  /* Light/Gray/800 */

  color: #3d3d3d;
}
.message_upload {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0192em;
  color: #7a7a7a;
}

.or {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 0.0192em;

  /* Light/Gray/800 */

  color: #3d3d3d;
}

.img {
  pointer-events: all;

  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
}
