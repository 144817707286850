@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

$colorChoose: #344563;
$white: #ffffff;

.dropdown {
  position: relative;
  font-family: $inter;
  font-weight: 500;
  color: $colorChoose;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &Select__label {
    @include showText(18px, 700, none, 21.78px);
    letter-spacing: 0.1px;
    color: $dark-blue;
    font-family: $inter;
    margin-bottom: 0;
  }
  &Btn {
    // box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    // border: 1px solid #e9edf0;
    padding: 12px 14px 11px 12px; ////Top
    // background: #fbfdff;
    background: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 6px;
    // max-height: 40px; //Top
    &Lg {
      max-height: 40px; //Top
      padding: 12px 14px 11px 5px; ////Top
      width: fit-content;
    }
    &Sm {
      max-height: 36px; //small
      padding: 10px 10px 9px 12px; ////Smal
    }
    img {
      padding-left: 6px;
      padding-top: 4px;
    }
  }
  &Content {
    position: absolute;
    // padding: 10px;
    background: $white;
    font-weight: 500;
    top: 40px;
    width: 100%;
    right: 0;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    &Lg {
      width: 255px; ///Top
    }
    &Sm {
      width: 220px; ///Top
    }
  }
  &Item {
    font-family: $inter;
    font-weight: 500;
    // padding: 5px 8px 5px 14px;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    &:hover {
      background: #e5e5e5;
    }
    &:first-child {
      // margin-top: 10px; ///TOp
    }
    &Lg {
      padding: 10px 29px 10px 16px; /// TOp
      height: 41px; ///Top
      &:last-child {
        height: 41px; ///Top
        padding: 10px 29px 10px 16px; /// TOp
      }
    }
    &Sm {
      height: 35px; ///Top
      padding: 0px 45px 0px 16px; /// TOp
      &:last-child {
        margin-bottom: 0;
        height: auto;
        padding-bottom: 6px;
      }
    }
  }
}
