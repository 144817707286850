@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.container {
  margin: 32px 0;
}
.container_print {
  margin-top: 38px;
}
.title {
  font-family: $inter;
  font-size: 18px;
  font-weight: 700;
  color: #3f3c4c;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 700px 300px;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    'line_1st line_2nd line_3rd recommendation'
    'agreed_to_drop agreed_to_drop agreed_to_drop recommendation';
  overflow: hidden;
}

.grid_expand_1st {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 350px 350px 300px;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    'line_2nd line_1st line_1st recommendation'
    'line_3rd line_1st line_1st recommendation'
    'agreed_to_drop agreed_to_drop agreed_to_drop recommendation';
  overflow: hidden;
}

.grid_expand_2nd {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 350px 350px 300px;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    'line_1st line_2nd line_2nd recommendation'
    'line_3rd line_2nd line_2nd recommendation'
    'agreed_to_drop agreed_to_drop agreed_to_drop recommendation';
  overflow: hidden;
}

.grid_expand_3rd {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 350px 350px 300px;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    'line_1st line_3rd line_3rd recommendation'
    'line_2nd line_3rd line_3rd recommendation'
    'agreed_to_drop agreed_to_drop agreed_to_drop recommendation';
  overflow: hidden;
}

.line_1st {
  grid-area: line_1st;
  display: grid;
  grid-template-rows: min-content;
}

.line_2nd {
  grid-area: line_2nd;
  display: grid;
  grid-template-rows: min-content;
}
.line_3rd {
  grid-area: line_3rd;
  display: grid;
  grid-template-rows: min-content;
}
.recommendation {
  grid-area: recommendation;
  display: block;
}
.agreed_to_drop {
  grid-area: agreed_to_drop;
  display: grid;
  grid-template-rows: min-content;
}

.view_btn {
  color: #00a19c;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-right: 20px;

  padding: 5px 10px;
  border: 1px solid #00a19c;
  border-radius: 6px;

  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
}
