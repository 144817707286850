@import "../../../../../assets/scss/variables";
@import "../../../../../assets/scss/mixin";

.modal {
  max-width: 1345px !important;

  .modal_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #344563;
    font-family: $inter;
    border: none;
    padding: 40px 80px;

    div {
      font-weight: 600;
      font-size: 18px;
      margin: 10px 0;
    }

    button {
      position: absolute;
      right: 0;
      transform: translate(-80px, 15px);
    }
  }

  .message {
    width: 100%;
    padding: 0 80px;
  }

  .table {
    padding: 0 80px;
    margin-bottom: 50px;

    table {
      border: 1px solid #e7e7f0;
      border-radius: 6px;
      font-family: $inter;
      font-size: 14px;
    }

    th {
      padding: 15px 0;
      text-align: center;
      font-weight: 500;
      border-bottom: 1px solid #e7e7f0;
    }
    td {
      padding: 15px 20px;
      text-align: center;
      font-weight: 400;
      border-bottom: 1px solid #e7e7f0;
      vertical-align: middle;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;

      div {
        padding: 10px 20px;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        font-family: $inter;
        width: 130px;
        cursor: pointer;
        border: 1px solid #00a19c;
        border-radius: 6px;

        &:active {
          transform: translateY(1px);
        }
      }

      .version {
        background-color: white;
        color: #00a19c;
      }

      .main {
        background-color: #00a19c;
        color: white;
        display: flex;
        justify-content: space-around;
      }

      .btn_disable {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    .firstCol {
      text-align: left;
      // padding-left: 20px;
    }
  }
}

.versionHistory {
  &__header {
    @include flex(center, space-between);
    padding-bottom: 31px;
    .btn {
      @include flex(center);
      padding: 8px 16px;
      height: 30px;
      border-radius: 4px;
      cursor: pointer;
      font-family: $inter;
      width: fit-content !important;
      @include showText(12px, 600, none, 600);
      &__save {
        background: #00a19c;
        color: white;
        border: 1px solid #00a19c;
        margin-left: 15px;
      }
      &__delete {
        color: white;
        background-color: $bright-gray;
        border: 1px solid $bright-gray;
      }
    }
  }
  &__barSearch {
    @include flex(center);
    width: 584px;
  }
}
