@import '../../../../assets/scss/variables';

.section {
  margin-bottom: 20px;
  background-color: #fff;
  width: 100%;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  font-family: $inter;
}
