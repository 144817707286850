@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.disclaimer {
  font-family: $inter;
  font-size: 14px;
  color: #9f9eae;
  padding: 0 0 24px 24px;

  span {
    font-weight: 500;
  }
}
