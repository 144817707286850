
.noValueSection {
  position: relative;
  height: 386px;
  text-align: center;

  img {
    margin-top: 100px;
  }

  h3 {
    font-weight: 700;
    font-size: 20px;
    color: #3f3c4c;
  }

  span {
    font-weight: 500;
    font-size: 13px;
    color: #9f9eae;
  }
}
