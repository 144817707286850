@import '../../../../../assets/scss/variables';

.noField {
  font-family: $inter;
  font-size: 14px;
  color: #8896a7;
  font-weight: 700;
}

.staffName {
  display: flex;
  font-family: $inter;
  font-weight: 700;
  color: #3f3c4c;
  letter-spacing: 0.3px;
  font-size: 14px;
  align-items: center;

  img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    margin-right: 20px;
  }
}

.textField {
  font-family: $inter;
  font-size: 13px;
  font-weight: 400px;
  color: #999a9c;
}

.deleteButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fbfdff;
  border: 1px solid #e9edf0;
  width: 32px;
  height: 32px;
  padding: 6px 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;

  :active {
    transform: translateY(2px);
  }
}
