.roleCode {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
}

.textField{
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #999A9C;
}
