@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;

.documents {
  margin: 26px 0;
  border-radius: 6px;
  background-color: $white;
  padding: 40px 30px 17px;
  font-family: $inter;

  .view_more {
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    text-align: end;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      background: url('../../assets/img/pets-chevron-right.svg');
      background-position: center;
      width: 11px;
      height: 11px;
      position: absolute;
      transform: translate(50%, 30%);
    }
  }
}

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .content_general {
    display: flex;
    margin-top: 30px;
    font-family: $inter;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 35px;
    }

    .content_general_file {
      display: flex;
      align-items: center;
      gap: 10px;

      .content_file {
        min-height: 44px;
        min-width: 44px;
        border: 2px solid $colorText;
        box-sizing: border-box;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .content_name {
        // margin-left: 20px;

        .file_name {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $colorText;

          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 200px;
        }

        .file_size {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #b1b9c6;
        }
      }
    }

    .icon_download {
      color: $colorText;
      width: 20px;
      height: 23px;
      cursor: pointer;
    }
  }
}
