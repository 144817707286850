@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

.SectionName {
  font-family: $inter;
  @include showText(24px, 600, none, 29.05px);
  color: #5e6c84;
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  table {
    margin-bottom: 0;
  }
  &Thead th {
    font-family: $inter;
    @include showText(15px, 700, none, 18px);
    color: #344563;
  }
  &Body {
    font-family: $inter;
    @include showText(14px, 400, none, 16px);
    color: #344563;
  }
}

.elipse {
  width: 18px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 100%;
  position: relative;
}

.crossbar {
  width: 274px;
  height: 6px;
  background: #c4c4c4;
}

.crossbarFirst {
  width: 10px;
  height: 6px;
  background: #c4c4c4;
}

.circleActive {
  width: 53px;
  height: 53px;
  border: 5px solid #00a19c;
  position: relative;
  border-radius: 100%;
}

.elipseActive {
  width: 18px;
  height: 18px;
  background: #00a19c;
  border-radius: 100%;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.font {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  color: #344563;
}

.fontActive {
  font-family: $rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #00a19c;
}

.chart {
  margin-top: 10px;
  padding: 20px;
}

.layoutRow {
  display: flex;
  flex-direction: row;
}

.layoutColumn {
  display: flex;
  flex-direction: column;
}
.bold {
  font-family: $inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #344563;
}
