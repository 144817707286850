@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.not_found {
    margin-top: 24px;
    margin-bottom: 24px;
  
    img {
      width: 100%;
      user-select: none;
      -webkit-user-drag: none;
    }
  }