@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.container {
  font-family: $inter;
  font-style: normal;
  flex: none;
  flex-grow: 0;

  .sp_aims {
    width: 1235px;
    height: 38px;
    left: 296px;
    top: 348px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #3f3c4c;
  }

  .bg_badge {
    display: -webkit-box;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    margin: 16px 0 16px 0;
    gap: 4px;
    width: 1027px;
    height: 32px;
    background: #dff1f1;
    order: 0;

    .badge {
      width: 101%;
      height: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #00847c;
      order: 1;
    }
  }

  .ratio_calculation {
    width: 1235px;
    height: 83px;
    left: 20px;
    top: 152px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #787587;
  }
}
