@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.search_item {
  padding: 17px 25px 7px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: $light-pink;
  }

  .individual {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $textColor;
    display: flex;

    .avatar {
      padding-right: 25px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-top: 8px;
      }
    }

    .info {
      color: $silver;
      font-weight: 400;

      .name {
        font-weight: 600;
        color: $black;
      }

      p {
        margin-bottom: 8px;
        line-height: 12px;
        font-size: 12px;
      }
    }
  }
}
