.editButton {
    color: #3f3c4c;
    margin-left: 10px;
    font-size: 12px;
  }
  
  .deleteButton {
    color: #ff787f;
    margin-left: 10px;
    font-size: 12px;
  }