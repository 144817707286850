@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.search_box {
  position: relative;
  margin-top: 10px;

  .position_input {
    width: 100%;
    height: 44px;
    border-radius: 2px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;
  }

  .position_input::-webkit-input-placeholder {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    user-select: none;
  }

  .position_input::-webkit-search-cancel-button {
    /* Remove default */
    // -webkit-appearance: none;
    /* Now your own custom styles */
    cursor: pointer;
    margin-right: 10px;
  }

  .lookup {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
}
