@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
$colorChoose: #344563;

.maxWidth {
  width: 1287px;
  margin: auto;
}

.tooltipContainer {
  div[role='tooltip'] {
    border-radius: 4px;
    padding: 12px;
    width: fit-content;
  }
}

.tooltip {
  font-family: $inter;
  display: grid;
  grid-template-rows: repeat(5, auto);
  color: $white;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;

  & > div:first-child {
    display: flex;
    gap: 8px;
  }

  span {
    font-weight: 600;
  }
}

.control {
  width: 100%;
  margin-bottom: 22px;

  .title_page {
    font-family: $inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
  }

  .label_input {
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #525252;
  }
}

.breadcrumb {
  @include flex(center, flex-start);
  margin-top: 10px;
  p {
    font-family: $inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $light_green;
  }

  img {
    transform: translateY(-40%);
  }

  .link_active {
    font-family: $inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    text-transform: capitalize;
  }
}

.table {
  padding: 0;
  border: 1px solid #d3dee8;
  background: #feffff;
  font-family: $inter;

  &_header {
    @include flex(flex-start, flex-start);
    font-family: $inter;
    padding: 15px 20px;
    border-bottom: 1px solid #d3dee8;
    font-weight: 700;
    font-size: 14px;
    color: #8896a7;
    gap: 8px;
  }

  .body_position {
    @include flex(center, center);
    gap: 8px;
    padding: 15px 20px;
    border-bottom: 1px solid #d3dee8;

    .position_name {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.3px;
      color: #3f3c4c;
    }

    .font_general {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.3px;
      color: #3f3c4c;
    }

    .font_dimmed {
      color: #999a9c;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.3px;
    }

    img {
      cursor: pointer;
    }
  }
}

.pagination {
  margin-top: 36px;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
}

.dropdown {
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: $colorChoose;
  background: #fbfdff;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;

  &Btn {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    color: #00a19c;
    border: 1px solid #e9edf0;
    padding: 12px 10px;
    background: $white;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 6px;
    width: 100%;
    height: 41px;
    max-height: 41px;
    gap: 6px;

    span:first-child {
      margin-bottom: 2px;
    }
    span:last-child {
      padding-left: 5px;
    }
  }
  &BtnCalendar {
    @include flex(center, flex-end);
    padding-bottom: 28px;
  }
  &Content {
    position: absolute;
    background: $white;
    font-weight: 500;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 185px;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    &DatePicker {
      position: absolute;
      right: -20px;
      z-index: 1;
      top: 50px;
      // display: none;
      visibility: hidden;
    }
  }
  &Item {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    padding: 10px 8px 0px 14px;
    cursor: pointer;
    transition: all 0.2;
    &:hover {
      background: white;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
}
.btnCancel {
  width: 73px;
  height: 30px;
  border: 1px solid $teal;
  color: $teal;
  font-family: $inter;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnApply {
  width: 73px;
  height: 30px;
  border: 1px solid $teal;
  color: white;
  font-family: $inter;
  font-size: 12px;
  font-weight: 600;
  background-color: $teal;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.dropdownBtnCalendar {
  margin-top: 10px;
}
