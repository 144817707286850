@import '../../../../../assets/scss/variables';

.cardTitle {
  font-family: $inter;
  font-size: 18px;
  font-weight: 700;
  color: #3d3d3d;
}

.flexContainer {
  display: flex;
}

.tableHeading {
  flex: 1;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  border: 1px solid #d3dee8;
  color: #ffffff;
}

.heading1 {
  background: #00a19c;
}

.heading2 {
  background: #ff7e01;
}

.tableCell {
  flex: 1;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  border: 1px solid #e7edf3;
}
