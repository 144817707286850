@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
.dropdown {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: $dark-blue;
  background: #fbfdff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Btn {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #e9edf0;
    padding: 12px 14px 11px 23px; ////Top
    background: #fbfdff;
    @include flex(center, space-between);
    cursor: pointer;
    border-radius: 6px;
    &Normal {
      max-height: 40px; //Top
      padding: 12px 14px 11px 23px; ////Top
    }
    &SM {
      max-height: 36px; //small
      padding: 10px 10px 9px 12px; ////Smal
    }
    .dropdownSelectLabel {
      @include showText(14px, 500, none, 16.59px);
      font-family: $rubik;
      color: $dark-blue;
      margin-bottom: 0;
    }
    .dropdownSelectLabel__Hidden {
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      padding-left: 17px;
    }
  }
  &Content {
    position: absolute;
    // padding: 10px;
    background: white;
    font-weight: 500;
    top: 40px;
    width: 100%;
    right: 0;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
    &__active {
      opacity: 1;
      visibility: visible;
    }
    &Normal {
      width: 255px; ///Top
    }
    &SM {
      width: 220px; ///Top
    }
    &SSM {
      width: fit-content;
    }
    .dropdownItemLabel {
      @include showText(14px, 500, none, 22px);
      font-family: $rubik;
      color: $dark-blue;
      margin-bottom: 0;
    }
  }
  &Item {
    font-family: $rubik;
    font-weight: 500;
    // padding: 5px 8px 5px 14px;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    transition: all 0.25s ease;
    @include flex(center);
    &:hover {
      background: $light-pink;
    }
    &:first-child {
      margin-top: 10px; ///TOp
    }
    &Normal {
      padding: 0px 29px 0px 16px; /// TOp
      height: 41px; ///Top
    }
    &SM {
      min-height: 35px; ///Top
      padding: 0px 45px 0px 16px; /// TOp
    }
  }

  .imgColor{
    filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
  }
}
