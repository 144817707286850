@import '../../assets/scss/variables';

.layout_container {
  width: 1600px;
  min-height: calc(100vh - 114px);
  display: flex;
  justify-content: flex-start;
  background-color: $bgSideColor;
  border-left: 1px solid #e5e5e5;

  .sidebar_show {
    // width: 100%;
    animation: width 0.3s linear;
    max-width: 249px;
  }
  .sidebar_hide {
    width: 82px;
    animation: width 0.3s linear;
  }
}

.layout_content {
  width: 1300px;
  margin: 50px 30px 0 20px;
  padding-bottom: 20px;
}
