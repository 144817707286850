@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.modal {
  &__body {
    padding: 1rem 33px !important;
  }
  &__header {
    border-bottom: none;
    padding: 16px 33px 5px 33px !important;
  }
  &__sub_title {
    font-family: $inter;
    @include showText(18px, 600, none, 21.78px);
    color: $dark-blue;
  }
  &__size {
    max-width: 1283px;
  }
  &__row1 {
    @include flex(center, space-between);
    &__mb {
      margin-bottom: 10px;
    }
  }
  &__group {
    &:first-child {
      width: 533px;
    }
    &:nth-child(2) {
      width: 387px;
    }
    &:nth-child(3) {
      width: 117px;
    }
    &:nth-child(4) {
      width: 117px;
    }
    &__row2 {
      width: 100%;
    }
  }
  &__btn {
    @include flex(center, flex-end);
    margin-top: 32px;

    .disabled_hover:hover{
      color: #ffffff  ;
    }
  }


}

.dropdown {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 44px;
  position: relative;
  &Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }
  &Content {
    position: absolute;
    width: 100%;
    &DatePicker {
      position: absolute;
      right: 0;
      z-index: 1;
      // min-width: 300px;
    }
  }
}
.text_placeholder {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #8d8d8d;
}

.input {
  width: 100%;
  height: 44px;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-indent: 16px;
}
.input::-webkit-input-placeholder {
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8d8d8d;
}

.time_input {
  text-indent: 0;
  text-align: center;
}
.error {
  border-color: red;
}
.text__error {
  @include showText(11px, 400, none, 21.78px);
  font-family: $inter;
  color: $danger;
}
