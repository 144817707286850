@import '../../assets/scss/variables';

.wrapper {
  width: 100vw;
  font-family: $inter;
  margin: 0;

  .leftSide {
    position: relative;
    background-color: $teal;
    min-height: 100vh;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .rightSide {
    background-color: $white;
    min-height: 100vh;
    padding: 75px 90px;

    .content {
      max-width: 586px;

      .logo {
        position: relative;
        background-color: $teal;
        border-radius: 100%;
        width: 128px;
        height: 128px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 74px;
        }
      }

      .title {
        color: #009089;
        font-weight: 700;
        font-size: 48px;
        margin-top: 60px;
        width: 500px;
        line-height: 58px;
      }

      .detail {
        margin-top: 30px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #575757;

        br {
          content: ' ';
          display: block;
          margin: 8px 0;
        }

      }
    }

    .loginBtn {
      width: 130px;
      height: 52px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 60px;
    }

    .contact {
      color: #2d2b39;
      font-weight: 500;
      font-size: 13px;
      margin-top: 60px;

      span {
        color: #009089;
      }
    }

    .loading {
      transform: translateY(25px);
    }
  }
}
