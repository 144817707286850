@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.tooltip {
  &_icon {
    position: absolute;
    bottom: 0;
    left: 58%;
    background: white;
    border-radius: 50%;
  }

  &_view_list {
    left: 64%;
    bottom: -7px;
  }

  &_content {
    padding: 10px;
  }

  &_status_type {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-bottom: 8px;
  }

  &_status_content {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    padding-left: 20px;
    display: inline-block;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }

  &_in_active {
    &::before {
      background-color: #00a19c;
    }
  }

  &_suspended {
    &::before {
      background-color: #fc930d;
    }
  }
}
