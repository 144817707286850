@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.positions {
  color: #525252;
  font-family: $inter;
  margin-bottom: 26px;
  .title {
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
    // color: #344563;

    .box_hierarchy {
      background: #ffffff;
      border: 2px solid #cccdcf;
      box-sizing: border-box;
      border-radius: 8px;

      position: absolute;
      z-index: 10;
      padding: 23px 36px;
      width: 714px;
      max-height: 354px;
      transform: translate(10px, 10px);

      &::before {
        position: absolute;
        content: '';
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #cccdcf;
        top: -9px;
      }

      &_title {
        font-family: $inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;

        display: flex;
        align-items: center;

        color: #344563;
      }

      .section_1 {
        // background: #50a4b9;
        border: 1px solid #cccdcf;
        box-sizing: border-box;
        border-radius: 6px;

        display: flex;
        align-items: center;
        padding: 13px;
        height: 83px;
        width: 100%;

        .text_hierarchy {
          font-family: $inter;
          font-style: normal;
          font-size: 11px;
          line-height: 20px;

          display: flex;
          align-items: center;

          color: #ffffff;
        }
      }

      .line_vertical {
        border: 2px solid #cccdcf;
        transform: rotate(90deg);
        width: 52px;
        margin-top: 23px;
        margin-bottom: 23px;
      }
    }

    &_ellipsis {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      max-width: 150px;

      .box_hierarchy {
        background: #ffffff;
        border: 2px solid #cccdcf;
        box-sizing: border-box;
        border-radius: 8px;

        position: absolute;
        z-index: 10;
        padding: 23px 36px;
        width: 714px;
        max-height: 354px;
        transform: translate(10px, 10px);

        &::before {
          position: absolute;
          content: '';
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #cccdcf;
          top: -9px;
        }

        &_title {
          font-family: $inter;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;

          display: flex;
          align-items: center;

          color: #344563;
        }

        .section_1 {
          // background: #50a4b9;
          border: 1px solid #cccdcf;
          box-sizing: border-box;
          border-radius: 6px;

          display: flex;
          align-items: center;
          padding: 13px;
          height: 83px;
          width: 100%;

          .text_hierarchy {
            font-family: $inter;
            font-style: normal;
            font-size: 11px;
            line-height: 20px;

            display: flex;
            align-items: center;

            color: #ffffff;
          }
        }

        .line_vertical {
          border: 2px solid #cccdcf;
          transform: rotate(90deg);
          width: 52px;
          margin-top: 23px;
          margin-bottom: 23px;
        }
      }
    }
  }
  .planning_date {
    font-weight: 500;
    font-size: 12px;
    // margin: 10px 0;
    margin-bottom: 30px;
  }
  .fontMinimal {
    font-weight: 500;
    font-size: 12px;
    // margin: 10px 0;
    margin-bottom: 8px;
  }
  .fontMedium {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 14px;
  }
  .divider {
    width: 1px;
    margin-right: 50px;
    background-color: #c4c3d1;
  }

  .textArea {
    // padding-right: 8px;
    textarea {
      resize: none;
      border: 1px solid #9fb0c2;
      width: 100%;
      padding: 12px 16px;
      height: 132px;
      border-radius: 4px;
    }
  }

  .textAreaInput::-webkit-input-placeholder {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    color: #c4c3d1;
  }

  .state {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 19px;
    background: #ffffff;

    border: 1px solid #50a4b9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 14px;
    padding: 5px 8px;

    .font_state {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;

      color: #50a4b9;
    }
  }
}


.positionListWrapper {
  @media print {
    padding-top: 20px;
  }
}

.positionTitle {
  color: var(--Grays-500, #525252);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-bottom: 15px;
}
