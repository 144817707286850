@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';


.pill {
    margin-top: 22px;
}

.pillItem {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    background-color: #EDF1F8;
    padding: 10px 11px;
    border-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    margin-right: 8px;
    margin-top: 5px;

    span {
        margin-right: 9px;
        margin-top: 0;
        margin-bottom: 0;
    }

    img {
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

}