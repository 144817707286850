@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.header {
  background-color: #344563;
  border: 1px solid #344563;
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 8px 0;

  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.succession_view {
  border: 1px solid #e7e7f0;
  width: 100%;
  padding: 20px;
}
