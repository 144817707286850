.wrapper {
  margin-top: 40px;
  margin-bottom: 50px;

  .notFound {
    width: 100%;
  }

  .table {
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #3f3c4c;
      line-height: 28px;
      letter-spacing: 0.389px;
      margin-bottom: -4px;
    }
  }

  .noText {
    color: #8896a7;
  }

  .dateText {
    color: #999a9c;
  }
}
