.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 4px;
}

.playButton img {
  width: 60px;
  height: 60px;
}

.durationLabel {
  position: absolute;
  bottom: 30px;
  left: 30px;
  background-color: #292929;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.videoElement {
  width: 100%;
  display: block;
  border-radius: 4px;
}
