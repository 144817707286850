@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.breadcrumb {
  @include flex(center, flex-start);
  text-decoration: none;
  flex-wrap: wrap;
  margin-top: 10px;

  p {
    font-family: $inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $light_green;
  }

  img {
    transform: translateY(-40%);
  }

  .talent_profile {
    font-family: $inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    word-break: break-word;
  }
}