@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.talent_search_form {
  max-width: 610px !important;
  overflow: auto;

  .talent_search_content {
    padding: 53px 63px 64px;
  }

  .modal_header {
    border: none;
    margin-bottom: 30px;

    h1 {
      font-family: $inter;
      font-size: 20px;
      line-height: 24px;
      margin: 0 auto;
    }

    button {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }

  .modal_body {
    overflow: initial !important;
    z-index: 100;
  }

  .title_search {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #525252;
  }

  .content_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_profile_name {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_profile_unit {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_active {
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #00a19c;
  }

  .text_no_active {
    background: rgba(52, 69, 99, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .text_no_data {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .talent_search_button {
    padding-top: 44px;
    border-top: solid 1px #d3dee8;
    margin-top: 44px;
  }

  .add_talent_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    height: 40px;
    background: #00a19c;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;

    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }

  .search_list {
    width: auto;
  }
}
