@import '../../../../../../assets/scss/variables';

.setContainer {
  padding: 39px 28px 0;
  border-radius: 6px;
  background: #ffffff;
}

.setHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.mainTitle {
  margin-bottom: 20px;

  h3 {
    color: #677c91;
    font-size: 24px;
    font-family: $inter;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.12px;

    span {
      color: #3f3c4c;
    }
  }
}

.setStatus {
  color: #7a7a7a;
  font-size: 12px;
  font-family: $inter;
  font-weight: 500;

  span {
    &:first-child {
      margin-right: 24px;
    }
  }
}

.groupButton {
  display: flex;
  align-items: center;

  .asMainToggle {
    position: relative;
    padding-right: 40px;

    &::after {
      content: '';
      background: #d6d6d6;
      width: 1px;
      height: 100%;
      position: absolute;
      right: 20px;
    }
  }

  .toggleLabel {
    color: #181818;
    font-size: 14px;
    font-family: $inter;
    font-weight: 500;
    line-height: 20px;
    margin-left: 10px;
  }
}

.commonBtn {
  font-size: 12px;
  font-family: $inter;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
}

.editBtn {
  color: #3f3c4c;
}

.duplicateBtn {
  color: #00a19c;
}

.deleteBtn {
  color: #ff787f;
}

.bottomBtnBar {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mainLabel {
  text-align: center;
  font-size: 16px;
  font-family: $inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--light-accent-700, #5b3075);
  background: var(--light-accent-100, #ebe1f1);
  padding: 4px 12px;
  border-radius: 4px;
  margin-left: 20px;
}

.tooltipContent {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0144em;
  color: #ffffff;
  padding: 10px;

  span {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
}

.customRunBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--light-primary-400, #6ac2be);
  color: var(--base-white, #fff);
  font-size: 12px;
  font-family: $inter;
  font-weight: 500;
  line-height: 16px;
  min-width: 75px;
  cursor: no-drop;
}
