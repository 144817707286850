@mixin flex(
  $algin: flex-start,
  $justify: flex-start,
  $direction: row,
  $warp: nowrap
) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin showText(
  $fontSize: 14px,
  $fontWeight: 400,
  $textTransform: none,
  $lineheight: 20px
) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  text-transform: $textTransform;
  line-height: $lineheight;
}
