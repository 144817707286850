@import '../../assets/scss/variables';

.searchNotFound {
  &Wrapper {
    width: 100%;
    height: 803px;
    border: 1px solid $geyser;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $white;
    margin-top: 20px;
  }

  &Icon {
    text-align: center;
    margin-bottom: 18px;

    img {
      width: 172px;
      height: 144px;
    }
  }

  &Content {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #9f9eae;
    max-width: 450px;
  }
}
