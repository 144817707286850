@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.container {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  align-items: center;
  gap: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;

  .card_title {
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    text-align: center;

    .color_child {
      font-weight: 500;
      color: #504689;
    }
  }

  .ratio_badge {
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #007724;

    .ratio_large_badge {
      flex-direction: row;
      padding: 4px 12px;
      width: 49px;
      height: 32px;
      background: #e5f4e7;
      order: 1;
    }
  }

  .card_child {
    font-size: 14px;
    line-height: 17px;

    .color_weight {
      font-weight: 500;
      color: #504689;
      &:hover {
        color: #5daef1 !important;
      }
    }
  }

  .ratio_badge_child {
    display: initial;
    flex-direction: row;
    padding: 4px 8px;
    width: 35px;
    height: 24px;

    .ratio_large_child {
      width: 19px;
      height: 16px;
      font-size: 12px;
      letter-spacing: 0.25px;
      color: #f09900;
      order: 1;
    }
  }

  .badge_child {
    display: inline-table;
    width: 19px;
    height: 16px;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #0b882e;

    .large_badge {
      display: flex;
      flex-direction: row;
      padding: 4px 8px;
      width: auto;
      height: 24px;
      background: #e5f4e7;
      order: 1;
    }
  }

  .badge_red {
    display: initial;
    flex-direction: row;
    padding: 4px 8px;
    width: 33px;
    height: 24px;
    background: #ffebee;

    .large_red {
      width: 17px;
      height: 16px;
      font-size: 12px;
      letter-spacing: 0.25px;
      color: #da2228;
      order: 1;
    }
  }
}
