@import '../../../../../../../assets/scss/variables';
@import '../../../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.collapse {
  position: relative;

  .collapseHeader {
    width: 100%;
    height: 33px;
    margin-top: 10px;

    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 20px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3f3c4c;
    font-size: 12px;
    font-family: $inter;
    font-weight: 400;

    div {
      width: 50%;
      padding: 10px;
      border: 1px solid #e7e7f0;
      border-bottom: 2px solid #e7e7f0;
      text-align: center;
      box-sizing: border-box;
      transition: border-bottom 333ms ease-in-out;
      cursor: pointer;
    }

    .active {
      border-bottom: 2px solid #50a4b9;
      color: #50a4b9;
      font-weight: 600;
    }
  }

  .body {
    padding: 20px 20px;
    background-color: #fffaef;
    border: 1px solid #e7e7f0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    font-family: $inter;
    font-weight: 400;
    font-size: 12px;
    color: #50a4b9;

    div {
      padding: 8px 12px;
      border: 1px solid #50a4b9;
      border-radius: 4px;
      cursor: pointer;
    }

    .activeFilter {
      color: white;
      background-color: #50a4b9;
    }
  }
}
.recommendation {
  &__name {
    text-decoration: none;
    font-family: $inter;
    @include showText(11px, 400, none, 20px);
    color: $recommnendationText;
    &:hover {
      text-decoration: none;
    }
  }
}
.table {
  margin-top: 10px;
  // max-height: 700px;
  // overflow-y: overlay;

  table {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    width: 100%;
  }

  td {
    text-align: center;
    padding: 0;
  }

  tbody {
    display: block;
    max-height: 1000px;
    border-bottom: 1px solid #e7e7f0;
    border-radius: 6px;
    overflow-y: overlay;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  tbody > tr:last-child {
    border-bottom: none;
  }
  .tableHeader {
    background-color: #50a4b9;
    border: 1px solid #50a4b9;
    padding: 10px;
    color: white;
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    height: 30px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    .nameHeader {
      text-align: left;
    }
  }

  .tableBody {
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    height: 30px;
    border: 1px solid #e7e7f0;
    cursor: pointer;
    .name {
      font-weight: 600;
      color: #50a4b9;
      text-align: left;
    }

    img {
      transition: transform 333ms ease-out;
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    .animate {
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  .tableBodyCard {
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    height: 30px;
    border: 1px solid #e7e7f0;

    .name {
      font-weight: 600;
      color: #50a4b9;
      text-align: left;
    }

    .card_box {
      font-family: $inter;
      font-size: 13px;
      font-weight: 400;
      color: #525252;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      div {
        margin-bottom: 5px 0;
      }

      .number {
        font-weight: bold;
        font-size: 14px;
        color: #3f3c4c;
        line-height: 20px;
      }

      span {
        font-weight: 500;
      }

      .icon_card {
        img {
          margin-left: 10px;
        }
      }

      .left {
        background-color: #d9f2f7;
        padding: 10px;
        font-weight: 400;
        width: 25%;

        .position {
          font-size: 10px;
          margin-top: 5px;
          font-weight: 500;
        }
      }

      .right {
        padding: 10px;
        display: flex;
        flex-direction: column;
        text-align: left;
        font-weight: 400;
        background-color: #fff;
        width: 75%;

        span {
          font-weight: 500;
        }
      }

      .image {
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
      }
    }
  }
}

.collapse {
  position: relative;

  .collapseBody {
    border: 1px solid #d3dee8;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-sizing: border-box;

    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 100%;
      border-radius: 6px;
    }
    &__line {
      height: 100%;
    }
  }

  .collapseHeader {
    width: 100%;
    height: 33px;
    margin-top: 10px;

    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 20px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: transform 333ms ease-out;
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }

    .animate {
      -ms-transform: rotate(0);
      transform: rotate(0);
    }
  }

  .icon {
    position: absolute;
    right: 0;
    transform: translate(-60px, -28px);
    cursor: pointer;
    user-select: none;
    z-index: 1;
  }
  .left {
    font-family: $inter;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }
}

.switch {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  user-select: none;

  img {
    transition: transform 333ms ease-out;
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .animate {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}
