@import '../../../../../assets/scss/variables';

.customLink {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0168em;
  color: $light_green;
  cursor: pointer;
}

.customMonth {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0168em;
  color: #7a7a7a;
  cursor: auto;
}
