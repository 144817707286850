@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.checkbox {
  position: relative;
  &__input {
    display: none;
    &:checked + .checkbox__label {
      &::after {
        visibility: visible;
        opacity: 1;
      }
      &::before {
        box-shadow: 0px 1px 1px rgba(0, 161, 156, 0.1), inset 0px 2px 0px rgba(0, 161, 156, 0.06);
        background-color: $teal;
        border: none;
      }
    }
  }
  &__label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    @include showText(14px, 500, none, 22px);
    font-family: $inter;
    color: $dark-blue;
    &::before {
      ///bg
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 4px;
      ///Uncheck
      background: #ffffff;
      border: 2px solid #dcdce6;
      transition: 0.25s linear;
    }
    &Empty {
      &::before {
        top: 0;
        transform: translateY(-65%);
      }
    }
    &::after {
      //Tick
      position: absolute;
      content: '';
      width: 9px;
      height: 6px;
      transform: rotate(-45deg);
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: 38%;
      left: 3px;
      transition: 0.25s linear;
      border-bottom-left-radius: 2px;
      visibility: hidden;
      opacity: 0;
    }
  }
}
.customCheckbox {
  margin-bottom: 5px;
}
.disabled {
  .checkbox__input {
    display: none;
    &:checked + .checkbox__label {
      &::after {
        visibility: visible;
        opacity: 0.7;
      }
    }
  }
  .checkbox__label {
    opacity: 0.7;
    cursor: default;
  }
}

.customCheckbox {
  .checkbox__input {
    &:checked + .checkbox__label {
      &::before {
        background-color: $teal;
      }
    }
  }
}
