.dropdownBtn {
  display: flex;
  gap: 10px;
  padding-left: 5px;
  font-size: 12px;
  color: #3f3c4c;
}

.deleteBtn {
  color: #ff787f;
}

.editBtn {
  padding-left: 7px;
}
