@import '../../assets/scss/variables';
$bgTargetProgessBar: #dff1f1;
$bgTargetColorBar: #41b2ae;
$bgColorBarSmall: #e4e8f3;
$colorGreen: #6fc27b;
$colorYellow: #f6db7d;
$colorRed: #eb6f70;
$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$colorBorderPerson: #d3dee8;
$white: #ffffff;
$gray: #999a9c;
$silver: #e9edf0;
$colorTitle: #8896a7;
$colorValue: #344563;

.pagination {
  width: 100%;
  font-family: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  .pagination_left {
    gap: 5px;
    span {
      font-weight: 800;
    }
  }
  .pagination_right {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $colorBlueBolder;

    .page {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      user-select: none;

      &:hover {
        background-color: #fff;
      color: #00A19C;
      }
    }
    .page_active {
      background-color: #00A19C;
      color: $white;
      border: 1px solid transparent;
      cursor: default;

      &:hover{
      border: 1px solid #00A19C;

      }
    }

    .disabled:hover {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
}
