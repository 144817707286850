@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.main {
  padding: 33px 26px 70px 54px;
  background-color: white;
  position: relative;
}

.grBtn {
  position: absolute;
  right: 0;
  bottom: -54px;
  height: 30px;
  gap: 8px;

  button {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
  }

  .cancel {
    color: #00a19c;
    border: 1px solid #e9edf0;
  }

  .add {
    color: white;
    background-color: #00a19c;
    border: 1px solid #00a19c;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #3f3c4c;
}

.customize {
  label {
    color: #525252;
    text-transform: none;
    font-weight: 500;
  }

  .input {
    height: 44px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #d3dee8;
    border-radius: 4px;
  }

  .input:focus {
    border-color: #00a19c;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  }

  .input:disabled {
    background: white;
    color: #787587;
  }
}

.positionName {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  text-indent: 10px;

  &:read-only {
    background-color: #e0e0e0;
  }
}
