@import '../../../../assets/scss/variables';
.wrapper {
  font-family: $inter;
  color: $Concrete;
  font-size: 12px;
  padding: 7px 12px;
  text-align: left;

  .title {
    font-weight: 600;
    font-size: 12px;
    color: $Concrete;
  }

  ul {
    padding-left: 10px;
    margin-bottom: 8px;
    list-style-image: url(../../../../assets/img/oval_list.svg);
    list-style-position: outside;
  }

  li {
    padding-left: 10px;
  }

  .releaseChecklist {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background-color: #0b882f;
  }
}
