@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

$colorChoose: #344563;
$white: #ffffff;

.dropdown {
  position: relative;
  font-family: $inter;
  font-weight: 500;
  color: $colorChoose;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_select_label {
    @include showText(14px, 700, none, 24px);
    letter-spacing: 0.1px;
    color: #3f3c4c;
    font-family: $inter;
    margin-bottom: 0;
  }

  &_btn {
    border: 1px solid #e9edf0;
    padding: 12px 14px 11px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    min-width: 100px;
    height: 24px;
    gap: 10px;

    img {
      color: #bababa;
    }
  }

  &_disabled {
    background: #edf1f8;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    cursor: no-drop;
  }

  &_content {
    position: absolute;
    background: $white;
    font-weight: 400;
    top: 26px;
    min-width: 200px;
    right: 15px;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    border: 1px solid #d3dee8;
  }

  &_item {
    font-family: $inter;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    padding: 11px 29px 12px 16px;
    border-bottom: 1px solid #d3dee8;
    height: 37px;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background: #e5e5e5;
    }
    &:first-child {
      // margin-top: 10px;
    }
  }
}
