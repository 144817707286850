@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

$colorChoose: #344563;
$white: #ffffff;
@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}
.container {
  background-color: #ffffff;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(4, 290px);
}

.filter_list_wrapper {
  div:last-child {
    margin-bottom: 0px;
  }
}

.btn_container {
  display: flex;
  margin-top: 20px;
  gap: 16px;
}

.btn_filter {
  padding: 10px 20px;
  border: 1px solid #00a19c;
  border-radius: 6px;
  color: #007874;
  top: 0;
  cursor: pointer;
  width: fit-content;
  height: 32px;
  font-size: 12px;
  font-family: inter;
  font-weight: 500;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.btn_filter_disabled {
  color: #8896a7;
  background-color: #f5f5f5;
  border-color: #d3dee8;
}

.active {
  background-color: #bde7e5;
}

.filter_title {
  width: 290px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
  padding: 12px 16px;
  background: #ffffff;
  color: #8896a7;
  font-family: $inter;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter_icon {
  height: 16.67px;
}
