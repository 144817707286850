@import '../../assets/scss/variables';

.container {
  position: relative;

  .nofication {
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-right: 30px;
    
    .nofication_number {
      position: absolute;
      padding: 3px 7px;
      font-family: "Rubik", sans-serif;
      font-weight: 500;
      font-size: 9px;
      color: #ffffff;
      background-color: #DA2228;
      text-align: center;
      border-radius: 50%;
      top: 0;
      transform: translate(25%, -40%);
      box-shadow: 0 0 0 1px #00a19c;
    }
  }
  .detail {
    width: 500px;
    position: absolute;
    top: 50px;
    right: -190px;
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #E9EDF0;
    border-radius: 6px;
  }

  .active {
    background-color: #dff1f1;
    border-radius: 6px;
  }
}
