@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';

.collapse {
  .collapseBody {
    border: 1px solid #d3dee8;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-sizing: border-box;
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 100%;
      border-radius: 6px;
    }
    &__line {
      height: 100%;
    }
  }

  .collapseHeader {
    width: 100%;
    height: 33px;
    margin-top: 10px;

    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 20px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: transform 333ms ease-out;
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }

    .animate {
      -ms-transform: rotate(0);
      transform: rotate(0);
    }
  }

  .icon {
    position: absolute;
    right: 0;
    transform: translate(-60px, -28px);
    cursor: pointer;
    user-select: none;
  }

  .icon_settings {
    position: absolute;
    right: 0;
    transform: translate(-60px, -29px);
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 20px;
  }
}

.card_box {
  // width: 49%;
  font-family: $inter;
  font-size: 13px;
  font-weight: 400;
  color: #525252;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  .approve {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    text-align: center;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
  }

  .image,
  .viewImage {
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .viewImage {
    img {
      border: solid 1px white;
    }
  }

  .name {
    color: #50a4b9;
    font-family: $inter;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 20px;
  }

  .number,
  .viewNumber {
    font-weight: 900;
    font-size: 14px;
    color: #3f3c4c;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .viewNumber {
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }

  span {
    font-weight: 400;
  }
  .icon_card {
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .left {
    background-color: #d9f2f7;
    padding: 0;
    .sp_counter {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }

  .right {
    margin-top: 10px;
    width: 100%;
  }

  .mainAgendaStatus {
    padding: 6px 8px;
    border-radius: 4px;
    color: #3d3d3d;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
    background-color: #ebebeb;
    width: fit-content;
  }
}
.additionalInput {
  padding: 10px;
  border: 1px solid #cbd6e2;
  background: #f0f5fa;
  border-radius: 6px;
  margin: 0;
  margin-top: 10px;

  ul {
    margin: 0;
    padding: 0;
    padding-left: 15px;
    list-style: none;
    li {
      color: var(--Light-Blue-Grays-800, #262d36);
      font-family: $inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15.5px;
      margin-bottom: 4px;
      display: flex;
      gap: 10px;
      
      span {
        word-wrap: break-word;
      }
    }
  }
}

.recommendation {
  &__name {
    display: inline;
    text-decoration: none;
    font-family: $inter;
    @include showText(14px, 600, none, 20px);
    color: $recommnendationText;
    &:hover {
      text-decoration: none;
    }
  }
}

.tooltip {
  div:global(.ant-tooltip-inner) {
    border-radius: 4px;
    background: #181818;
    padding: 12px;
  }
  span:global(.ant-tooltip-arrow-content) {
    background: #181818;
  }
  .platformName {
    font-family: $inter;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 600;
    }
  }
}

.approve_label {
  padding: 5px 10px;
  border: 1px solid #e7e7f0;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
}

.bold_title {
  font-weight: bold;
}

.tooltipContainer {
  display: grid;
  gap: 12px;
}
