@import '../../../../assets/scss/variables';

.mobility_list {
  margin-bottom: 20px;

  .notFound {
    width: 100%;
  }
}

.simulation_name {
  color: #3f3c4c;
  font-size: 14px;
  font-weight: 700;
}

.last_update_by,
.last_update_date {
  color: $gray;
  font-size: 13px;
  text-align: center;
}

.endorsed {
  margin-left: 8px;
  margin-bottom: 2px;
  border-radius: 4px;
}
