@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.OtherExperience {
  background: $light-blue;
  border-radius: 6px;
  padding: 28px 26px 21px 24px;
  &__header {
    @include flex(flex-start, space-between);
    margin-bottom: 19px;
  }
  &__h3 {
    @include showText(24px, 600, none, 29.05px);
    letter-spacing: 0.3px;
    font-family: $inter;
    color: $dark-gray;
    margin-bottom: 0;
    line-height: 40px;
    vertical-align: middle;
  }
  &__table {
    background-color: white;
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    table {
      width: 100%;
    }
    thead {
      tr {
        box-shadow: inset 0px -1px 0px #ececec;
      }
    }
    tr {
      &:not(:last-child) {
        background: rgba(0, 0, 0, 0.0001);
        box-shadow: inset 0px -1px 0px #ececec;
      }
    }
    p {
      margin-bottom: 0;
      color: $dark-blue;
      font-family: $inter;
    }
    th {
      p {
        @include showText(14px, 500, none, 16.94px);
      }
      padding: 17px 0 13px 0;
      &:first-child {
        padding-left: 26px;
        width: 366px;
      }
    }
    td {
      padding: 9px 0 24px 0;
      p {
        @include showText(14px, 400, none, 16.94px);
      }
      &:first-child {
        padding-left: 26px;
        padding-right: 10px;
      }
      &:last-child {
        padding-right: 29px;
      }
    }
  }

  .editBtn {
    background-color: #fbfdff;
    width: 40px;
    height: 40px;
    border: 1px solid $silver;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    &:active {
      transform: translateY(2px);
    }

    img {
      width: 14px;
    }
  }

  .addBtn {
    display: block;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: 0;
  }

  .btn {
    width: 100%;
    height: 54px;
    background: $dark-blue;
    border-radius: 6px;
    border: 1px solid #e9edf0;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  }
  .TextBtn {
    font-family: $inter;
    font-weight: 700;
    font-size: 14px;
    color: white;
  }
  .btn:hover {
    background: #e9edf0;
  }
  &__editTag {
    text-decoration: underline;
    font-family: $inter;
    @include showText(10px, 600, none, 12.1px);
    color: $dark-blue;
    line-height: 12px;
  }
  &__image {
    width: 10px;
    height: 10px;
  }
}

.body {
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3;
    margin-bottom: 29px;
  }

  .content {
    display: flex;
    justify-content: flex-end;
  }
}
