@import '../../../../assets/scss/variables';

.columnTitle {
  font-family: $rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #999a9c;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.wrapperColumn,
.listColumnName,
.columnNameItem {
  display: flex;
  flex-wrap: wrap;
}

.wrapperColumn {
  align-items: flex-start;
}

.columnNameItem {
  background: white;
  border: solid 1px #d3dee8;
  border-radius: 4px;
  padding: 12px 16px;
  margin-right: 10px;
  min-width: 200px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  span {
    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #181818;
  }
}

.columnNameLabel {
  font-family: $inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  border-radius: 16px;
  margin-right: 10px;
  padding: 8px;
}

.addColumnBtn {
  border: 1px solid#00A19C;
  border-radius: 4px;
  background: white;
  width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #00a19c;

  img {
    margin-right: 10px;
  }
}
