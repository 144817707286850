@import '../../../../../../../assets/scss/variables';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.deleteButton {
  color: #ff787f;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.positionCode {
  color: #3f3c4c;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.positionName {
  color: #3f3c4c;
  font-weight: 700;
}

.approvedDate {
  color: $gray;
  font-size: 13px;
}

.inputBusiness {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
}

.inputBusinessErorr {
  border: 1px solid #ff787f;
}

.customImg {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.font {
  font-size: 13px;
  font-weight: 400;
  color: #999a9c;
}

.matchingCriteria {
  font-size: 14px;
  font-weight: 700;
  color: #00a19c;
}

.table_except_name {
  color: #999a9c;
  font-family: $inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
}
.table_name {
  color: var(--base-black, #181818);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.action {
  font-family: $inter;
  font-weight: 600;
  font-size: 12px;
  width: 100px;
  height: 32px;

  background-color: #00a19c;
  color: #fff;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  &:active {
    transform: translateY(1px);
  }
}

.disabled {
  font-family: $inter;
  font-weight: 600;
  font-size: 12px;
  width: 100px;
  height: 32px;

  background-color: #e5e5e5;
  color: #b1b1b1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.avatar_wrapper {
  position: relative;
}
