@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.max_width {
  width: 1287px;
  margin: auto;
  font-family: $inter;
}

.show_exporting {
  overflow: hidden;
  transition-duration: 300ms;
}

.control {
  width: 100%;

  .title {
    font-family: $inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    margin: 20px 0 30px;
  }

  .subTitle {
    font-family: $rubik;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #999a9c;
  }
}

.not_found {
  margin: 20px auto;

  img {
    width: 100%;
  }
}

.fieldWrapper {
  position: relative;
}

.checkedIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 10;
}

.auditLogTab {
  display: flex;
  color: #000;
  gap: 20px;
  width: 100%;
  position: relative;
  font-family: $inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 30px;

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    transform: translateY(42px);
  }

  .active {
    color: #009089;
    padding-bottom: 4px;
    border-bottom: 3px solid #009089;
  }
}
