@import 'variables';

h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 5px;
}

h2,
h3,
h4,
h5 {
  color: $primary;
}

h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $secondary;
}

.lead-paragraph {
  font-weight: 300;
  font-size: 24px;
  color: $black;
  line-height: 34px;
  height: 34px;
  padding: 0px;
  margin: 0px;
}

.paragraph {
  font-weight: 300;
  font-size: 18px;
  color: $black;
  line-height: 28px;
  height: 28px;
  padding: 0px;
  margin: 0px;
}

.pTitle {
  font-size: 15px;
  font-weight: 700;
}



.ant-select-dropdown {
  border-radius: 6px;
}
.ant-dropdown-menu {
  min-width: 150px;
}
.ant-table-filter-dropdown-btns {
  justify-content: flex-end;
  padding: 8px 7px;

  .ant-btn.ant-btn-link.ant-btn-sm {
    display: none;
  }

  .ant-btn.ant-btn-primary.ant-btn-sm {
    border: none;
    outline: none;
    width: 97px;
    height: 20px;
    border-radius: 22px;
    background-color: #344563;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 400;
    text-transform: none;
    line-height: 18px;
    color: white;

    span {
      display: none;
    }
    &:after {
      content: 'Apply';
    }
  }
}
