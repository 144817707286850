$colorChoose: #344563;
$white: #ffffff;
@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}
.dropdown {
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: $colorChoose;
  background: #fbfdff;
  border: 1px solid #e9edf0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  @include flex(center, space-between);

  &Subbtn {
    color: #00A19C;
    img {
      filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
    }
  }
  &Btn {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    background: #fbfdff;
    display: flex;
    align-items: center;
    // cursor: pointer;
    justify-content: space-between;
    border-radius: 6px;
    font-size: 14px;
    line-height: 17px;
    color: #00A19C;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;

    &Lg {
      max-height: 40px; //Top
      padding: 12px 14px 11px 23px; ////Top
    }

    img {
      filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
      padding-left: 17px;
    }
  }
  &Content {
    position: absolute;
    background: $white;
    font-weight: 500;
    top: 44px;
    right: 0;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
    transition: all 0.2s ease;
  }
  &Item {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    padding: 11px 26px 11px 16px;
    width: 448px;
    cursor: pointer;
    line-height: 17px;
    position: relative;
    &flex {
      @include flex(center, space-between);
    }
    // box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 4px;
  }
  &ContentItemSub {
    margin-bottom: 0;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    line-height: 24px;
    cursor: default;
  }
  &SubBtn {
    color: #00A19C;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #e9edf0;
    padding: 12px 14px 11px 23px; ////Top
    background: #fbfdff;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 6px;
    max-height: 40px; //Top
    // padding: 12px 14px 11px 23px; ////Top
  }
  &ContentSub {
    position: absolute;
    // padding: 10px;
    background-color: white;
    font-weight: 500;
    top: 58px;
    right: 26px;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 100;
    width: 255px; ///Top
    max-height: 286px;
    overflow: auto;
    &Disabled {
      background-color: #EDF1F8;
    cursor: no-drop;
    }
  }
  &ItemSub {
    font-family: 'Rubik', sans-serif;
    // border: 1px solid red;
    font-weight: 500;
    padding: 10px 8px 10px 14px;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    &:hover {
      background: white;
    }
  }
}
.dropdownSub {
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  @include flex(center, space-between);
  padding: 13px 26px 13px 16px;
  width: 255px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  z-index: 1;
  &BeforeSelect {
    color: #d9d9d9;
    img {
      color: #d9d9d9;
    }
  }
  &Disabled {
    background-color: #EDF1F8;
    cursor: no-drop;
  }

  img {
    filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
  }
}

.dropdownSubItemOption {
  width: 100%;
  @include flex(center, space-between);
}
