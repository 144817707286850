@import '../../assets/scss/variables';

$cell-width: 54px;
$cell-height: 56px;

.wrapper {
  background-color: $white;
  display: flex;
  flex-direction: column;
  font-family: $inter;
  padding: 20px 12px;

  .desc {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    font-family: $inter;
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 700;
    letter-spacing: 0.0144em;

    .name {
      display: flex;
      gap: 9px;
      align-items: center;

      .effective {
        display: block;
        width: 26px;
        height: 26px;
      }
    }
  }

  .dot {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    z-index: 1;
  }

  .ladder_wrapper {
    display: flex;
    flex-direction: column;

    .ladder {
      display: flex;
      justify-content: flex-end;

      span {
        display: block;
        width: $cell-width;
        height: $cell-height;
        background-color: #e7edf3;
        color: #4f5f6e;
        font-weight: 700;
        font-size: 12px;
        line-height: $cell-height;
        letter-spacing: 0.173px;
        text-align: center;

        &.hidden {
          background-color: transparent;
        }

        &.upper {
          background: #c9d5e3;
        }
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    background-color: #f2f4f8;

    .row {
      display: flex;
      align-items: center;

      .parent {
        width: 104px;
        padding-left: 12px;
        line-height: 112px;
        text-align: left;
        vertical-align: middle;
        color: $white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.235px;
      }

      .child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .child_wrapper {
          display: flex;
          justify-content: left;
          border-bottom: 1px solid #e7edf3;

          .child_name {
            margin-left: 12px;
            display: flex;
            gap: 18px;
            align-items: center;
            width: 100%;

            .child_no {
              flex-shrink: 0;
              width: 40px;
              height: 40px;
              line-height: 40px;
              border-radius: 8px;
              color: $black;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 0.173px;
              text-align: center;
              vertical-align: middle;
            }

            .child_title {
              line-height: 20px;
              vertical-align: middle;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0.0168em;
              color: #3d3d3d;
            }
          }

          .value_wrapper {
            background-color: $white;
            display: flex;

            .value_cell {
              position: relative;
              display: flex;
              gap: 6px;
              justify-content: center;
              align-items: center;
              width: $cell-width;
              height: $cell-height;

              .dot {
                width: 10px;
                height: 10px;
              }

              .effectiveProficiency {
                width: 100%;
                height: 100%;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}
