@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.positions {
  color: #525252;
  font-family: $inter;
  margin-bottom: 34px;
  .headWrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 210px;
  }
  .viewSuccessionPlanButton {
    position: absolute;
    top: 10px;
    right: -10px;
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.235px;
    color: #3f3c4c;
    cursor: pointer;
    padding: 0 5px 5px;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    color: rgb(52, 69, 99);
    cursor: pointer;
  }
  .planning_date {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 30px;
  }
  .fontMinimal {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 8px;
  }
  .fontMedium {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 14px;
  }
  .divider {
    width: 1px;
    margin-right: 50px;
    background-color: #c4c3d1;
  }

  .textArea {
    textarea {
      resize: none;
      border: 1px solid #9fb0c2;
      width: 100%;
      padding: 12px 16px;
      height: 132px;
      border-radius: 4px;
    }
  }

  .textAreaInput {
    &::-webkit-input-placeholder {
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #c4c3d1;
    }

    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }

  .state {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 19px;
    background: #ffffff;

    border: 1px solid #50a4b9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 14px;
    padding: 5px 8px;

    .font_state {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;

      color: #50a4b9;
    }
  }

  .description {
    margin-top: 34px;
    font-size: 14px;
    .title {
      @extend .fontMedium;
      margin-bottom: 16px;
    }
    .content {
      color: #787587;
      line-height: 17px;
      ul {
        margin-bottom: 8px;
      }
    }
  }
}
.tooltip {
  padding-top: 6.313709px;
  div:global(.ant-tooltip-inner) {
    padding: 15px 27px;
    border-radius: 6px;
    font-family: $inter;
    font-weight: 600;
    font-size: 12px;
    color: $Concrete;
  }
  div:global(.ant-tooltip-arrow) {
    top: -15.586291px;
  }
}

.positionListWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.positionTitle {
  color: var(--Grays-500, #525252);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
