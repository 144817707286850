@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.agendaItem {
  position: relative;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  padding: 16px 17px 23px 24px;
  .avatar_user {
    img {
      box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      width: 40px;
      height: 40px;
    }
  }

  &__wrapContent {
    position: relative;
    width: 100%;
  }

  &__Status {

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #005db9;
    background: #e2f1fc;
    padding: 4px;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }

  &__content {
    font-family: $inter;
    color: $dark-blue;
    @include showText(13px, 400, none, 16px);
    max-width: 88%;
  }
}

.exists_paper_ready{
  border: 1px solid #F08800;
}

.btn_in_progress {
  height: 24px;
  min-width: 130px;

  border-radius: 4px;
  background: #fff7e5;
  color: #e56e18;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.25px;
}

.btn_finalized {
  height: 24px;
  min-width: 130px;

  border-radius: 4px;
  background: #e5f4e7;
  color: #007724;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.25px;

  img {
    width: 13px;
    height: 13px;
  }
}

.checkbox {
  &__input {
    position: absolute;
    z-index: 10;
    top: 50%;
    width: 16px;
    height: 16px;
    opacity: 0;
    transform: translateY(-50%);
    cursor: pointer;

    &:checked + .checkbox__label {
      &::after {
        visibility: visible;
        opacity: 1;
      }

      &::before {
        box-shadow: 0px 1px 1px rgba(19, 31, 21, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.06);
        background-color: #00a19c;
        border: none;
      }
    }
  }

  &__label {
    cursor: pointer;
    position: relative;
    padding-left: 40px;
    @include showText(14px, 500, none, 22px);
    font-family: $inter;
    color: #00a19c;

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: #ffffff;
      border: 2px solid #dcdce6;
      transition: 0.25s linear;
    }

    &::after {
      position: absolute;
      content: '';
      width: 9px;
      height: 6px;
      transform: rotate(-45deg);
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      top: -4px;
      left: 3px;
      transition: 0.25s linear;
      border-bottom-left-radius: 2px;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.agenda_type {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #00807c;
  font-family: $inter;
}