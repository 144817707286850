@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.viewSusccessionPlanPopup {
  max-width: 1420px !important;

  .viewSusccessionPlanContent {
    padding: 20px;
  }

  .modalHeader {
    border: none;

    .inforWrapper {
      width: 100%;
      position: relative;
    }

    .mainTitle {
      font-family: $inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #525252;
      margin-bottom: 38px;
    }

    .itemInfoAvatar {
      display: flex;
      align-items: flex-start;
    }

    .itemInfoAvatar {
      padding-right: 20px;
    }

    .mainAvatar {
      img {
        width: 90px;
        height: 90px;
        overflow: hidden;
        border-radius: 50%;
        border: solid 1px white;
      }
    }

    .close {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }

  .planning_date {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .fontMinimal {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #525252;
    padding-bottom: 12px;
  }

  .fontMedium {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 14px;
  }

  .modalBody {
    height: 100%;
    padding-top: 0 !important;
  }

  .title_search {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #525252;
  }

  .content_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_profile_name {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_profile_unit {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_active {
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #00a19c;
  }

  .text_no_active {
    background: rgba(52, 69, 99, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .text_no_data {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .talent_search_button {
    padding-top: 44px;
    border-top: solid 1px #d3dee8;
    margin-top: 44px;
  }

  .add_talent_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    height: 40px;
    background: #00a19c;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .search_list {
    width: auto;
  }

  .viewMoreBtn {
    cursor: pointer;
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
  }

  .cardBoxColumnView {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .columnViewItem {
    width: 32%;
    border: 1px solid #d3dee8;
    border-radius: 6px;
  }

  .wrapperButton {
    @include flex();
    margin-left: 36px;
  }

  .itemIfo {
    width: 960px;
  }

  .rowItem {
    display: flex;
    align-items: flex-start;

    > div {
      width: 50%;

      &:first-child {
        padding-right: 30px;
      }
    }
  }
}
