@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.collapseBody {
  border: 1px solid #d3dee8;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    border-radius: 6px;
  }

  &__line {
    height: 100%;
  }
}

.collapseHeader {
  width: 100%;
  height: 33px;

  font-family: $inter;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 0 20px;

  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    transition: transform 333ms ease-out;
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .animate {
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

.bodyWithScroll {
  background-color: $white;
  padding: 10px;
  border: 1px solid #e7e7f0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: auto;
  min-height: 190px;
  max-height: 468px;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .dragDropBox {
    width: 100%;
    padding: 40px 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: $inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #787587;
    gap: 10px;
    border-radius: 6px;
    height: 190px;
  }
}

.bodyWithoutScroll {
  padding: 10px;
  border: 1px solid #e7e7f0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 100px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.close {
  display: none;
  transition: all 0.5s ease;
}
