@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.add_new_detail {
  background-color: white;
  font-family: $inter;
  padding: 15px;
  margin-bottom: 20px;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 48px;
  margin-top: -10px;
}

.subText {
  display: block;
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5e6c84;
}