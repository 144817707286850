.wrapper {
  .section {
    background-color: white;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  @mixin skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 2s infinite;
  }

  .type1 {
    @include skeleton;
    width: 138px;
    height: 27px;
    margin-bottom: 30px;
  }

  .type2 {
    @include skeleton;
    width: 100%;
    height: 47px;
    margin-bottom: 20px;
  }

  .type3 {
    @include skeleton;
    width: 100%;
    height: 224px;
    margin-bottom: 20px;
  }

  .type4 {
    @include skeleton;
    width: 180px;
    height: 47px;
  }

  .row {
    display: flex;
    gap: 20px;
  }

  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}
