@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.filter_section {
  margin: 20px 0;
}

.search_section {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: flex-end;

  .search {
    width: 869px;
    height: 44px;
    border: 1px solid #d3dee8;
    border-radius: 6px;
    padding-left: 10px;
    margin-right: 10px;

    &::placeholder {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8D8D8D;
      opacity: 0.7;
    }
  }

  .search_input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8d8d8d;
    user-select: none;
  }

  .button {
    background-color: #00a19c;
    padding: 6px 14px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
  }

  .button:active {
    transform: translateY(2px);
  }

  .icons {
    display: flex;
    align-items: flex-end;
    transform: translate(4%, 20%);

    img {
      width: 45px;
      height: 45px;
      cursor: pointer;
      pointer-events: all;
    }

    img:active {
      transform: translateY(3%);
    }
  }
}
