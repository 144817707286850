.brand{
  color: $primary;
}

.warning{
  color: #bc242d;
}

.container{
  max-width: 100% ;
}

.no-pad{
  padding:0
}