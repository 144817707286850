@import '../../../assets/scss/variables';

.controlBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.createMobilityBar {
  background-color: $white;
  padding: 45px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #3f3c4c;
  }

  &.added {
    background-color: transparent;
    padding: 0;
    .title {
      visibility: hidden;
    }
  }
}

.listVerticalWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 38px;
}
