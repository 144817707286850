@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.container {
  @include flex(flex-start, flex-start, column);
  padding-bottom: 24px;
  width: 100%;
  background: $light-blue;
  border-radius: 6px;
  margin-bottom: 24px;
}

.container_print {
  @include flex(flex-start, flex-start, column);
  width: 100%;
  border-radius: 6px;
  margin-bottom: 24px;
  border: 1px solid #d9d9d9;
  margin-top: 32px;
}

.wrapperHeader {
  width: 100%;
  @include flex(center, space-between);
  margin-bottom: 36px;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3d3d3d;
}

.scrollContent {
  width: 100%;
  display: block;
  overflow: hidden;
}
