@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.input {
  padding: 11px 16px;
  font-family: $inter;
  @include showText(14px, 400, none, 20px);
  outline: none;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
}
