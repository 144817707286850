@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;
$grayColor: #5e6c84;
$colorBorderDropdown: #dbdfe2;
$dropdownLabel: #dbdfe2;
$placeHolder: #9ea0a5;

.searchWrapper {
  position: relative;
}

.modal_container {
  font-family: $inter;
  position: relative;

  .search_icon {
    position: absolute;
    top: 16px;
    right: 8px;
    opacity: 0.5;
  }

  .label {
    color: #3f3c4c;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .business_select {
    width: 426px;
  }

  .function_select {
    :global {
      .rc-virtual-list-holder-inner {
        margin-right: 4px;
        .ant-select-item-option:first-child {
          border-bottom: 1px solid #d6d6d6;
          background: #f2f4f8;
        }
        .ant-select-item-option {
          border-bottom: 1px solid #d6d6d6;
        }
      }

      .ant-select-dropdown {
        min-width: 948px !important;
        left: 50% !important;
        transform: translateX(-68%);
        z-index: 100;
      }

      .ant-select-selection-search {
        input {
          color: #8d8d8d;
        }
      }
      .ant-select-item-option {
        border: none;
        padding: 0 !important;

        .ant-select-item-option-content {
          color: #181818;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.235px;

          > div > div {
            padding: 12px 0;
          }
        }
      }
      .rc-virtual-list {
        padding: 10px;
      }
      .ant-select-selector {
        padding: 0px 16px;
      }
      .ant-select-selection-search-input {
        font-size: 14px;
        color: #181818;
        padding-left: 5px;
      }
      .ant-select-selection-placeholder {
        font-size: 14px;
        color: #8d8d8d;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled),
      .ant-select-item-option-active .ant-select-item-option-content > div > div {
        background-color: #e0f3f3 !important;
        color: #004846 !important;
      }
    }
  }
}

.function_header {
  display: grid;
  grid-template-columns: repeat(3, 33%);

  div {
    color: #181818;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.235px;
  }
}

.function_header_active {
  background: #c9d5e3;
}

.function_row_active {
  background: #f2f4f8;
}

.function_rows {
  display: grid;
  grid-template-columns: repeat(3, 33%);

  div {
    text-wrap: wrap;
    color: var(--base-black, #181818);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.235px;
  }
}
