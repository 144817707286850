@import '../../../assets/scss/variables';

.firstForm {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    font-weight: 600;
    font-size: 18px;
    color: #3f3c4c;
  }
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 30px 0;
}

.firstForm,
.noMobility {
  padding: 30px;
  margin-top: 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;
}
