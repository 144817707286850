@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.flex_center {
  padding: 8px;
  @include flex(center, center);
  @include showText(12px, 400, none, 18px);
}

.dropdown {
  padding: 0 2px;

  &__filters {
    padding: 7px 12px 6px 17px;
    max-height: 300px;
    overflow-y: scroll;
  }

  &__apply {
    border-top: 1px solid #d8d8d8;
    @include flex(center, flex-end);
    padding: 8px 7px 8px 0px;
  }
  &__btn {
    border: none;
    outline: none;
    width: 97px;
    height: 19px;
    border-radius: 22px;
    background-color: $teal;
    font-family: $inter;
    @include showText(10px, 400, none, 18px);
    color: white;
  }
}
