@import '../../../assets/scss/variables';

.contentWrapper {
  padding: 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .mainTitle {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 30px;
  }
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
