.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .card {
      position: relative;
      width: 100%;
    }

    &.rowReverse {
      flex-direction: row-reverse;
    }
  }

  .arrowTop {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    position: absolute;
  }
}
