.collapseBody__line {
  height: 100%;
  //   border: 1px solid #e7e7f0;
  //   border-radius: 6px;\

  border-radius: 6px;
  transition: all 0.25s ease;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}
.collapseBody__lineRecommendation {
  //   border: 1px solid #e7e7f0;
  //   border-radius: 6px;\
  font-weight: 400;
  border-radius: 6px;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}
