@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.button {
    background-color: #00A19C;
    padding: 8.5px 16px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.btnAdd {
    padding: 8px 16px;
    font-weight: 600;
}

.button:active {
    transform: translateY(2px);
}

.icons {
    transform: translate(-15%, 20%);

    img {
        width: 47px;
        height: 47px;
        cursor: pointer;
        pointer-events: all;
    }

    img:active {
        transform: translateY(3%);
    }
}

.inActiveDelBtn {
    background-color: #E7E7F0;
    color: white
}

.activeDelBtn {
    background-color: #FF787F;
}

.dot_flasing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    transform: translate(100%, 80%);
}

.dot_flasing::before,
.dot_flasing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot_flasing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot_flasing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: #9880ff;
    }

    50%,
    100% {
        background-color: #ebe6ff;
    }
}