@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.search_box {
  position: relative;

  .search_input {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-left: 24px;
    padding-right: 35px;

    &:read-only {
      background-color: #e0e0e0;
    }

    &::placeholder {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8d8d8d;
    }
  }

  .dropdownIcon {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    z-index: 10;
  }

  .dropdownIcon_edit {
    right: 17% !important;
  }

  .is_edit {
    max-width: 250px !important;
    padding-left: 30px;
    padding-right: 20px;
  }

  .error {
    border-color: red !important;
  }

  .search_input::-webkit-input-placeholder {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    user-select: none;
  }

  .search_input::-webkit-search-cancel-button {
    /* Remove default */
    // -webkit-appearance: none;
    /* Now your own custom styles */
    cursor: pointer;
    margin-right: 10px;
  }

  .lookup {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
  .search_notFound {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .search_list {
    list-style-type: none;
    margin: 0;
    width: 100%;
    min-height: 36px;
    border-radius: 3px;
    border: 1px solid #e9edf0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
    padding: 0;
    background-color: #fff;
    color: #000;
    position: absolute;
    bottom: 0;
    z-index: 10;
    transform: translateY(102%);
    @include flex(flex-start, center, column);
    .search_item {
      padding: 15px;
      border-bottom: 1px solid $borderColor;
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: $light-pink;
      }
      .individual {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $textColor;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .name {
          color: $nameTextColor;
          font-weight: 600;
          line-height: 16px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .dot_flashing {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
      margin-left: 28px;
    }

    .dot_flashing::before,
    .dot_flashing::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot_flashing::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    .dot_flashing::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: #9880ff;
      }
      50%,
      100% {
        background-color: #ebe6ff;
      }
    }
  }

  .search_list_scroll {
    list-style-type: none;
    margin: 0;
    width: 100%;
    min-height: 36px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 3px;
    border: 1px solid #e9edf0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
    padding: 0;
    background-color: #fff;
    color: #000;
    position: absolute;
    bottom: 0;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, 102%);

    .is_edit {
      width: 250px !important;
    }

    .search_item {
      padding: 15px;
      border-bottom: 1px solid $borderColor;
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: $light-pink;
      }
      .individual {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $textColor;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .name {
          color: $nameTextColor;
          font-weight: 600;
          line-height: 16px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    .dot_flashing {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
      margin-left: 28px;
    }

    .dot_flashing::before,
    .dot_flashing::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot_flashing::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    .dot_flashing::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: #9880ff;
      }
      50%,
      100% {
        background-color: #ebe6ff;
      }
    }
  }
}
