@import '../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  font-family: $inter;
  padding: 24px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: #3f3c4c;
  margin-bottom: 20px;
}

.proposals {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    font-weight: 600;
    font-size: 18px;
    color: #3f3c4c;
    margin-bottom: 8px;
  }

  .proposal {
    max-height: 72px;
    transition: all ease 0.2s;
    gap: 10px;
    overflow: hidden;

    .header {
      display: flex;
      height: 72px;
      align-items: center;
      padding: 12px 20px;
      border-radius: 8px;
      border: 1px solid #dedede;
      gap: 20px;

      .name {
        display: flex;
        width: 300px;
        gap: 20px;
        align-items: center;

        img {
          border-radius: 50%;
          width: 48px;
          height: 48px;
        }
      }

      .position {
        border-left: 1px solid #cbd6e2;
        padding-left: 20px;
        width: 500px;
      }

      .more {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type {
          padding-left: 20px;
          width: 312px;
          color: $teal;
          font-size: 12px;
          font-weight: 600;
        }

        .btnRow {
          padding: 0;
        }
      }

      .label {
        font-weight: 500;
        font-size: 12px;
        color: #7a7a7a;
      }

      .content {
        font-weight: 600;
        font-size: 18px;
        color: #3f3c4c;
      }

      .normalContent {
        color: var(--Emphasis-Body-N800, #3f3c4c);
        font-family: $inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }

    &.expand {
      max-height: fit-content;

      .header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .expandBtn {
        transform: rotateX(180deg);
      }
    }

    .expandContent {
      padding: 20px;
      background-color: #f4f5f8;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
}
