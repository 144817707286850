@import '../../assets/scss/variables';
$bgTargetProgessBar: #dff1f1;
$bgTargetColorBar: #41b2ae;
$bgColorBarSmall: #e4e8f3;
$colorGreen: #6fc27b;
$colorYellow: #f6db7d;
$colorRed: #eb6f70;
$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$colorBorderPerson: #d3dee8;
$white: #ffffff;
$gray: #999a9c;
$silver: #e9edf0;
$colorTitle: #8896a7;
$colorValue: #344563;

@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}
%backgroundBorder {
  background: #feffff;
  border: 1px solid #d3dee8;
  border-radius: 6px;
}

.talent_finder {
  width: 1300px;
  padding: 8px 130px 8px 60px;
  margin: auto;

  .title {
    .icon {
      justify-content: flex-end;
      gap: 10px;
      div {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid $silver;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
        user-select: none;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:active {
          transform: translateY(1px);
        }
      }
    }
  }

  .content {
    .grid {
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-direction: row;
      justify-content: flex-start;
      gap: 23px;
    }

    .left_card {
      flex-direction: column;
      align-items: center;
      border: 1px solid $colorBorderPerson;
      background-color: $white;
      border-radius: 6px;
      padding: 22px;
      width: 260px;
      height: 349px;
      color: $gray;
      .person {
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        height: 255px;
        font-size: 13px;
        gap: 3px;

        .avatar {
          width: 73px;
          height: 73px;
          border-radius: 50%;
        }

        .name {
          color: $colorBlueBolder;
          font-weight: 700;
          font-size: 14px;
          margin-top: 15px;
        }
        .ellipsis {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
          text-align: center;
        }
      }
    }

    .right_card {
      margin-left: 23px;
      border: 1px solid $colorBorderPerson;
      background-color: $white;
      border-radius: 6px;
      padding: 70px 60px 110px;
      width: 827px;
      height: 349px;
      font-weight: 400;
      font-size: 13px;
      color: $gray;
      font-family: $inter;

      display: flex;
      justify-content: flex-start;

      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
      }

      &_title {
        font-weight: 700;
        font-size: 14px;
        color: $colorTitle;
      }

      &_value {
        font-weight: 600;
        font-size: 16px;
        color: $colorValue;
      }
    }

    .btn_view_talent {
      width: 160px;
      height: 40px;
      border-radius: 6px;
      background-color: #00A19C;
      cursor: pointer;
      padding: 10px;
      text-align: center;
      p {
        color: $white;
        display: inline;
      }

      &:hover {
        opacity: 0.9;
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }

  .disabled {
    opacity: 0;
    visibility: hidden;
  }
  .active {
    background-color: #007bff !important;
    color: white !important;
  }

  .pagination_left {
    font-size: 16px;
    gap: 6px;

    h6 {
      font-size: 18px;
      transform: translateY(20%);
    }
  }
}

.avatar_wrapper {
  position: relative;
}
