@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.maxWidth {
  width: 1287px;
  margin: auto;
  margin-bottom: 200px
}
.Talentprofile {
  @include flex(flex-start, space-between);
  &__left {
    width: 880px;
    margin-right: 10px;
  }
  &__right {
    width: 395px;
  }
}

.control {
  width: 100%;
  margin-bottom: 50px;
  .title {
    color: $textColor;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 41px;
  }
  .listBtnLeft {
    float: right;
  }
  .itemBtn {
    font-family: $inter;
    font-weight: 500;
    line-height: 24px;
    color: #00a19c;
    background-color: white;
    border-radius: 6px;
    padding: 6px 18px;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    // &:hover {
    //   color: $white;
    // }
  }
}
.overallProfileUser {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 23px 69px 49px 28px;
}

.Card {
  padding: 26px 22px 30px 22px;
  margin-bottom: 1.5rem;
  border-radius: 6px;
}
.titleCard {
  font-family: $inter;
  font-weight: 700;
  font-size: 24px;
  color: $dark-blue;
  margin-right: 21px;
}
.TextLabel {
  @include showText(18px, 400, none, 21.78px);
  letter-spacing: 0.1px;
  color: #92929d;
  font-family: $inter;
}
.DisplayHeader {
  display: flex;
  align-items: center;
}
.distance {
  margin-top: 16px;
}

// this code of page meeting detail
.btn_meeting {
  height: 32px;
  font-family: $inter;
  font-weight: 500;
  line-height: 24px;
  color: $white;
  background-color: #00a19c;
  border-radius: 6px;
  padding: 6px 18px;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.meeting_details {
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 23px 69px 49px 28px;
  margin-bottom: 24px;
}

.header_meeting_details {
  display: flex;
  align-items: center;
  gap: 10px;

  .text_header {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .frame_status {
    background: #feffff;
    border: 1px solid #d3dee8;
    border-radius: 6px;
    min-height: 30px;
    min-width: 126px;
    display: flex;
    align-items: center;
    gap: 13px;
    padding: 8px;

    .status {
      background: #fdb924;
      border-radius: 4px;
      width: 12px;
      height: 12px;
    }
    .text_status {
      font-family: $rubik;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;

      color: #344563;
    }
  }
}
.title_body {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #525252;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  height: 44px;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-indent: 16px;
}

.input::-webkit-input-placeholder {
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8d8d8d;
}

.dropdown {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 44px;

  &Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }
  &Content {
    position: absolute;
    background: $white;
    font-weight: 500;
    top: 0;
    right: 0;
    width: 100%;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    &DatePicker {
      position: absolute;
      right: 15px;
      z-index: 1;
      top: 70px;
      // min-width: 300px;
    }
  }
  &Item {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    padding: 10px 8px 0px 14px;
    cursor: pointer;
    transition: all 0.2;
    &:hover {
      background: white;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
}

.text_placeholder {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #8d8d8d;
}

.btn_white_success_planning {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 30px;

  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.btn_green_success_planning {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 30px;

  background: #00a19c;
  border-radius: 4px;
  cursor: pointer;
}

.text_btn {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;

  text-align: center;
}
