@import '../../../../assets/scss/variables';

.modalContent {
  padding-top: 40px;

  .title {
    font-family: $inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #3f3c4c;
    margin-bottom: 24px;
  }

  .formGroup {
    display: flex;
    justify-content: space-between;

    .formField {
      width: 50%;

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  .groupButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  .errorMsg {
    font-family: $inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: #f36568;
    padding-top: 10px;
  }
}
