@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$colorChoose: #344563;
$white: #ffffff;

.dropdown {
  position: relative;
  font-family: $inter;
  font-weight: 500;
  color: $colorChoose;
  background: transparent;
  &Select__label {
    @include showText(14px, 400, none, 21.78px);
    letter-spacing: 0.1px;
    color: $dark-blue;
    font-family: $inter;
    margin-bottom: 0;
    padding-right: 10px;
  }
  &Select__placeholder {
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 10px;
    color: #8d8d8d;
    display: inline-block;
  }
  &Btn {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid $geyser;
    img {
      color: #bababa;
    }
  }
  &Content {
    position: absolute;
    background: $white;
    font-weight: 400;
    top: 50px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
  }
  &Item {
    font-family: $inter;
    font-weight: 400;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    padding: 10px 29px 10px 16px;
    border-bottom: 1px solid $geyser;
    &:last-child {
      padding: 10px 29px 10px 16px;
    }
    &:hover {
      background: #E0F3F3;
    }
  }
}

.clearIcon {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 9;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    filter: brightness(75%);
  }
}
