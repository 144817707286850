@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.meeting_details {
  max-width: 450px !important;
  font-family: $inter;

  .modal {
    padding: 15px;
  }

  .modal_header {
    border: 0;
    margin-bottom: 10px;
    &_title {
      font-family: $inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #344563;
    }
  }

  .body {
    padding: 0 1rem;
    .label {
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1px;
      color: #6b6c6f;
      display: flex;
      align-items: center;
    }
  }

  .footer {
    border: 0;
    padding: 0 1rem;

    .content {
      display: flex;
      gap: 20px;

      .button_confirm {
        @include flex(center, center);
        cursor: pointer;
        background-color: $btnColor;
        min-width: 100px;
        min-height: 36px;
        color: $white;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 0px;
      }
      .button_cancel {
        @include flex(center, center);
        cursor: pointer;
        background-color: $white;
        min-width: 100px;
        min-height: 36px;
        color: $btnColor;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        margin-right: 0px;
        border: 1px solid $btnColor;
      }
    }
  }
}
