@import '../../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;
  padding: $pdcPrintPadding;
}

.title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  color: #3f3c4c;
  margin-bottom: 16px;
  align-items: center;
}

@mixin title-cell {
  border-top: 1px solid #d3dee8;
  border-bottom: 1px solid #d3dee8;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin profile-text {
  color: #3f3c4c;
  font-family: $inter;
  font-weight: 700;
}

@mixin textarea-style {
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}

.talent_wrapper {
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #e7edf3;
}

.each_talent {
  border-radius: 6px;
  border: 1px solid #cbd6e2;
  display: grid;
  grid-template-columns: 20% 10% 1fr;
  font-family: $inter;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.item1 {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  grid-row: span 3;
  grid-column: 1;
}

.birth_name {
  font-size: 24px;
  @include profile-text;
}

.jgsg {
  font-size: 18px;
  @include profile-text;
}

.item2 {
  grid-row: 1;
  grid-column: 2;
  background-color: #00a19c;
  @include title-cell;
}

.item3 {
  grid-row: 2;
  grid-column: 2;
  background-color: #00a19c;
  @include title-cell;
}

.item4 {
  grid-row: 3;
  grid-column: 2;
  background-color: #ff7e01;
  @include title-cell;
}

.item5 {
  grid-row: 1;
  grid-column: 3;
  @include textarea-style;
  border-bottom: 1px solid #e7edf3;
}

.item6 {
  grid-row: 2;
  grid-column: 3;
  @include textarea-style;
  border-bottom: 1px solid #e7edf3;
}

.item7 {
  grid-row: 3;
  grid-column: 3;
  @include textarea-style;
}

.avatar {
  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
  }
}
