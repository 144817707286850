@import '../../../../assets/scss/variables';

.recommended {
  padding: 50px 30px;
  font-family: $inter;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 6px;

  .recommendedHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommendedTitle {
      font-weight: 700;
      font-size: 18px;
      color: #3f3c4c;
    }

    .recommendedLegendLabel {
      font-weight: 400;
      font-size: 10px;
      color: #444444;
    }
  }

  .recommendedBody {
    .columns {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.no_recommended_replacement {
  display: flex;
  justify-content: center;
  min-height: 1030px;
}

.tooltipCustom {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 10px;

  span {
    display: block;
  }
}

.firstText {
  text-transform: uppercase;
  margin-bottom: 8px;
}

.lastText {
  position: relative;
  padding-left: 10px;
  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    top: 5px;
    left: 0;
    background-color: #65c3df;
  }
}

.tooltipIcon {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  cursor: pointer;
}
