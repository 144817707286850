@import '../../../../../assets/scss/variables';

.wrapper {
  position: relative;
  display: flex;
}

.dropdown {
  font-family: $inter;
  width: 400px;
  max-height: 596px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px #0000001a;
  position: absolute;
  right: 0;
  top: 34px;
  z-index: -10;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  padding: 16px 0 16px 16px;
  border-radius: 4px;
}

.row {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  font-size: 14px;
  color: #3f3c4c;
  margin-bottom: 12px;
  line-height: 22px;
  vertical-align: middle;
  word-break: break-word;

  & > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .expandBtn {
    width: 16px;
    height: 16px;

    &.expand {
      transform: rotateZ(180deg);
    }
  }
}

.list {
  max-height: 508px;
  overflow-y: auto;
  margin-bottom: 10px;
  position: relative;
  padding-top: 24px;
  padding-right: 12px;

  &::-webkit-scrollbar {
    width: 2px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4a4a4a80 !important;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c4c4c480 !important;
  }
}

.title {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: space-between;
  line-height: 100%;
  color: #5d7083;
  position: absolute;
  width: 372px;
  top: 14px;
  background-color: $white;
  z-index: 1;
}

.mobilities {
  max-height: 0px;
  overflow: hidden;
  transition: all ease-in-out 0.2s;

  &.expand {
    max-height: 344px;
  }

  .row {
    margin-left: 26px;
  }
}

.btnPreview {
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
}
