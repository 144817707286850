@import '../../assets/scss/variables';

.footer {
  width: 1600px;
  height: 44px;
  background-color: #00A19C;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  font-family: $inter;
  display: flex;
  justify-content: center;
  align-items: center;
}
