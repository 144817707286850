@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

$colorChoose: #344563;
$white: #ffffff;

.dropdown {
  position: relative;
  font-family: $inter;
  font-weight: 500;
  color: $colorChoose;
  background: $white;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  &Select__label {
    @include showText(14px, 400, none, 21.78px);
    letter-spacing: 0.1px;
    color: $dark-blue;
    font-family: $inter;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &Btn {
    // padding: 12px 14px 11px 15px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid $geyser;
    img {
      // padding-left: 6px;
      // padding-top: 4px;
      color: #bababa;
    }
  }
  &Content {
    position: absolute;
    background: $white;
    font-weight: 400;
    top: 50px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    font-size: 14px;
    z-index: 10;
  }
  &Item {
    font-family: $inter;
    font-weight: 400;
    cursor: pointer;
    z-index: 100;
    line-height: 17px;
    padding: 10px 16px 10px 16px;
    border-bottom: 1px solid $geyser;
    &:last-child {
      height: 41px;
      padding: 10px 29px 10px 16px;
    }
    &:hover {
      background: #e5e5e5;
    }
    &:first-child {
      // margin-top: 10px;
    }
  }
}
