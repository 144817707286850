@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$BCP-color: #5b3075;
$BCP-background: #ebe1f1;

$CCP-color: #007874;
$CCP-background: #e0f3f3;

$other-color: #414e5c;
$other-background: #e7edf3;

.tooltip {
  div:global(.ant-tooltip-inner) {
    padding: 15px 27px;
    border-radius: 6px;
    font-family: $inter;
    font-weight: 600;
    font-size: 12px;
    color: $Concrete;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: rgb(52, 69, 99);
  font-family: $inter;
}
.JG_hover {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  color: rgb(52, 69, 99);
  font-family: $inter;
}

.position_label {
  // Default styles for the label
  padding: 4px 12px;
  width: 58px;
  height: 32px;
  margin-left: 15px;
  font-family: $inter;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  border-radius: 3px;
}

.BCP {
  color: $BCP-color;
  background-color: $BCP-background;
}

.CCP {
  color: $CCP-color;
  background-color: $CCP-background;
}

.other {
  color: $other-color;
  background-color: $other-background;
}
