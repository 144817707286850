@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.detaiWrapper {
  @include flex(stretch, flex-start);
}

.cardDetailItem {
  min-width: 200px;
  max-width: 200px;
  background: #ffffff;
  border-right: solid 2px #e7edf3;
  border-bottom: solid 1px #e7edf3;
}

.roleName,
.durationLabel {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  color: #4f5f6e;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0168em;
  padding: 14px 12px;
  position: relative;

  &::after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -2px;
  }
}

.roleName {
  background: #c9d5e3;

  &::after {
    background: #f2f4f8;
  }
}

.durationLabel {
  background: #e7edf3;

  &::after {
    background: #f9fafb;
  }
}

.roleLabels {
  padding: 18px 12px;
  background: #ffffff;

  ul {
    margin-bottom: 0;
    padding: 0;

    li {
      list-style: none;
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0168em;
      color: #7a7a7a;
      padding-left: 20px;
      position: relative;
      margin-bottom: 5px;
      word-break: break-word;

      span {
        color: #7a7a7a;
      }

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background-color: #7a7a7a;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 10px;
      }
    }
  }
}

.tooltipContent {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0144em;
  color: #ffffff;
  padding: 10px;

  span {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
}
