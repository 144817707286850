@import '../../../../../../assets/scss/variables';

.menuWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.groupBtn {
  display: flex;
  justify-content: space-between;
}

.searchBox {
  position: relative;

  .searchInput {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--grays-300, #8D8D8D);

    &:read-only {
      background-color: #e0e0e0;
    }

    &::placeholder {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--grays-300, #8D8D8D);
    }

    &::-webkit-input-placeholder {
      font-family: $inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
      user-select: none;
    }
  
    &::-webkit-search-cancel-button {
      cursor: pointer;
      margin-right: 10px;
    }
  }
}