@import '../../../../../../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  padding: 26px 0;
  border-radius: 6px;
  margin-bottom: 24px;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
  }
}