.defaultStyle {
  font-family: 'Inter', sans-serif;
  background: #ffffff;
  border: 1px solid #d3dee8;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
  resize: none;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  text-align: left;
  word-break: break-word;

  span {
    color: #8d8d8d;
  }
}
