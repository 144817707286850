@import '../../assets/scss/variables';

.menu_wrapper {
  .link {
    text-decoration: none;
  }

  .child_menu {
    text-decoration: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 333ms ease-out, transform 333ms ease-out;
    transform: translateY(-10%);
  }

  .show_dropdown {
    opacity: 1;
    height: auto;
    transform: translateY(0);
  }

  .title {
    padding: 15px 10px;
    font-family: $rubik;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark-gray;
    min-height: 43px;
  }

  .menu_ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 10px;
  }
  .menu_sub_position {
    padding: 0 10px !important;
  }
  .menu_li {
    width: 100%;
    padding: 10px;
    font-family: $inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    cursor: pointer;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .icon {
      img {
        width: 30px;
        height: 20px;
      }
    }

    .text {
      transform: translateY(17%);
      color: $blue-gray;
    }

    .children_name {
      margin-left: 20%;
      font-size: 14px;
      padding-bottom: 15px;
    }

    &:active {
      transform: translateY(1px);
    }

    &:hover {
      border-radius: 6px;
      background-color: #f6f7f9;

      .text {
        color: $teal;
      }
      .icon img {
        filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%)
          contrast(101%);
      }
    }
  }

  .menu_parent {
    width: 100%;
    padding: 10px;
    font-family: $inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    cursor: pointer;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    .icon {
      img {
        width: 30px;
        height: 20px;
      }
    }

    .text {
      transform: translateY(17%);
      color: $blue-gray;
      width: 90%;
    }

    .chevron {
      img {
        transition: transform 333ms ease-out;
        -ms-transform: rotate(0);
        transform: rotate(0);
      }

      .animate {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .active {
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-color: $light-blue;
    transition: 0.2s all;

    .text {
      color: #00a19c;
    }
    .icon img {
      filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%)
        contrast(101%);
    }
  }
}
