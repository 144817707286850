@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.max_width {
  width: 1287px;
  margin: auto;
  font-family: $inter;
}

.pageContainer {
  width: 100%;

  .mainTitle {
    font-family: $inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    margin: 20px 0 30px;
  }
}
