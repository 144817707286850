@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.container {
  font-family: $inter;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #2d2b39;

  .position_item {
    font-weight: 700;
    color: #41b2ae;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .text {
      font-size: 14px;
      line-height: 17px;
      padding-left: 30px;
      position: relative;
      &::before {
        content: '';
        background-color: #65c3df;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translate(-30px, 40%);
        position: absolute;
      }
    }

    .number {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #185c9b;
      cursor: pointer;

      &:hover {
        color: #5daef1 !important;
      }
    }

    &_1 {
      .text {
        &::before {
          background-color: #65C3DF;
        }
      }
    }
    &_2 {
      .text {
        &::before {
          background-color: #637AB8;
        }
      }
    }
    &_3 {
      .text {
        &::before {
          background-color: #6559AA;
        }
      }
    }
    &_4 {
      .text {
        &::before {
          background-color: #50A4B9;
        }
      }
    }

    &.isDisabled {
      color: #c4c3d1;
      font-weight: 400;

      .text {
        &::before {
          background-color: #c4c3d1;
        }
      }

      .number {
        font-weight: 400;
        color: #c4c3d1;
      }
    }
  }
}

.pipe1 {
  position: absolute;
  transform: rotate(-15deg) translate(0px, 2px);
  width: 80px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  transition: all 300ms ease-in;

  &:hover {
    transform: rotate(-15deg) translate(-7px, -4px);
  }
  &.click {
    transform: rotate(-15deg) translate(-7px, -4px);
  }
}

.pipe2 {
  position: absolute;
  transform: rotate(-15deg) translate(99%, 2px);
  width: 80px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  transition: all 300ms ease-in;

  &:hover {
    transform: rotate(-15deg) translate(107%, -3px);
  }
  &.click {
    transform: rotate(-15deg) translate(107%, -3px);
  }
}

.pipe3 {
  position: absolute;
  transform: rotate(-15deg) translate(99%, 102%);
  width: 80px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  transition: all 300ms ease-in;

  &:hover {
    transform: rotate(-15deg) translate(107%, 108%);
  }
  &.click {
    transform: rotate(-15deg) translate(107%, 108%);
  }
}

.pipe4 {
  position: absolute;
  transform: rotate(-15deg) translate(0%, 102%);
  width: 80px;
  height: 80px;
  z-index: 10;
  cursor: pointer;
  transition: all 300ms ease-in;

  &:hover {
    transform: rotate(-15deg) translate(-8%, 109%);
  }
  &.click {
    transform: rotate(-15deg) translate(-8%, 109%);
  }
}

.role {
  position: relative;
  margin-top: 50px;
  .role_close {
    position: absolute;
    height: 20px;
    width: 20px;
    top: -35px;
    right: 0;
    cursor: pointer;
  }
}

.numberOfPie {
  color: #185c9b;
  &:hover {
    color: #5daef1 !important;
  }
}
