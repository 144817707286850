@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-weight: 500;
  font-family: $inter;
  color: #3f3c4c;
  font-size: 14px;
}

.error {
  font-weight: 500;
  font-family: $inter;
  font-size: 12px;
  color: #da1e28;
}

.borderErr {
  border-color: #da1e28 !important;
}
