@import '../../.././../../assets/scss/variables';
@import '../../.././../../assets/scss/mixin';

// popup save version
.save_version {
  max-width: 800px !important;
  font-family: $inter;

  .modal_header {
    padding: 36px 30px 20px 30px;
    border: none;

    .title {
      font-family: $inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.833333px;
      margin-bottom: 0;
      color: #4a4a4a;
    }
    .subTitle {
      font-family: $inter;
      font-size: 12px;
      line-height: 24px;
      color: #4a4a4a;
      font-style: italic;
    }
  }

  .modal_body {
    padding: 15px 30px 15px 30px;
    height: 150px;

    .label_input {
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #4a4a4a;
    }

    .btn_save {
      display: flex;
      align-items: center;
      padding: 15px 16px;
      width: auto;
      height: 30px;
      font-family: $inter;
      background: #00a19c;
      color: white;
      border-radius: 4px;
      border: 1px solid #00a19c;
      cursor: pointer;
    }

    .text_error {
      @include showText(14px, 400, none, 21.78px);
      font-family: $inter;
      color: $danger;
    }
  }
}
