@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.modal {
  max-width: 655px !important;
  height: 410px;

  .modal_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #344563;
    font-family: $inter;
    border: none;
    position: relative;
    padding: 20px 30px;

    div {
      font-weight: 700;
      font-size: 24px;
      margin: 10px 0;
    }

    p {
      font-weight: 600;
      font-size: 18px;
      margin: 10px 0;
      color: #344563;
    }

    button {
      position: absolute;
      right: 0;
      transform: translate(-28px, 15px);
    }
  }

  .table {
    padding: 0 30px;
  }

  .form {
    margin-bottom: 15px;
    position: relative;

    .close_icon {
      position: absolute;
      cursor: pointer;
      img {
        width: 10px;
        height: 10px;
        position: absolute;
        right: 25px;
        top: -2px;
      }
    }

    img {
      position: absolute;
      right: 0;
      transform: translate(-20px, 30px);
    }

    .dropdown {
      border: 1px solid #d3dee8;
      border-radius: 6px;
      div {
        padding: 10px 10px;
        border: 1px solid #d3dee8;
        cursor: pointer;
        &:hover {
          background-color: $light-pink;
        }
      }
    }

    .dropdown_positions {
      border: 1px solid #d3dee8;
      border-radius: 6px;
      max-height: 230px;
      overflow: auto;
      div {
        padding: 10px 10px;
        // border: 1px solid #d3dee8;
        cursor: pointer;
        &:hover {
          background-color: $light-pink;
        }
      }
    }
  }

  .position_input {
    width: 100%;
    height: 44px;
    margin: 10px 0;
    // padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;
    cursor: pointer;
  }

  .position_input::-webkit-input-placeholder {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    user-select: none;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    font-family: $inter;
    font-weight: 500;
    font-size: 14px;

    .add {
      width: 130px;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 6px;
      padding: 10px 15px;
      color: white;
      background-color: #00a19c;
      cursor: pointer;
  
    }
  }
}
