@import '../../../assets/scss/variables';

$bgTargetProgessBar: #dff1f1;
$bgTargetColorBar: #41b2ae;
$bgColorBarSmall: #e4e8f3;
$colorGreen: #6fc27b;
$colorYellow: #f6db7d;
$colorRed: #eb6f70;
$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$colorChoose: #344563;
$white: #ffffff;

@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}

%backgroundBorder {
  background: #feffff;
  border: 1px solid #d3dee8;
  border-radius: 6px;
}

.dashboard {
  margin: auto;

  .title {
    width: 1270px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin-left: 30px;
  }

  .left {
    width: 50%;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;
  }

  .filter {
    border-radius: 6px;
    box-shadow: 0px, 2px rgba(0, 0, 0, 0.04);
    background-color: $white;
    padding: 12px;
  }

  .dashboard_content {
    width: 100%;
    background: #feffff;
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin-bottom: 40px;
  }

  .dropdown {
    position: relative;
    font-family: $rubik;
    font-weight: 500;
    color: $colorChoose;
    background: #fbfdff;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    max-width: 185px;

    &Btn {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
      color: #00a19c;
      border: 1px solid #e9edf0;
      padding: 12px 10px;
      background: $white;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      border-radius: 6px;
      width: 185px;
      height: 41px;
      max-height: 41px;
      font-weight: bold;

      span:first-child {
        margin-bottom: 2px;
      }

      span:last-child {
        padding-left: 5px;
      }
    }

    &Content {
      position: absolute;
      background: $white;
      font-weight: 500;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 185px;
      box-shadow: 0px 8px 16px 0px #0000001a;
      border-radius: 2px;

      &DatePicker {
        position: absolute;
        right: -20px;
        z-index: 1;
        top: 50px;
      }
    }

    &Item {
      font-family: $rubik;
      font-weight: 500;
      padding: 10px 8px 0px 14px;
      cursor: pointer;
      transition: all 0.2;

      &:hover {
        background: white;
      }

      &:last-child {
        padding-bottom: 10px;
      }
    }
  }
}

.talentHeader {
  @include flex(center, space-between);
  padding-bottom: 10px;
}

.talentHeaderTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
}

.talentHeaderSubTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9f9eae;
}

.titleSuccession {
  font-family: $rubik;
  font-weight: 700;
  font-size: 20px;
  color: $colorBlueBolder;
  line-height: 24px;
  margin-bottom: 8px;
}

.subSuccession {
  font-family: $rubik;
  font-weight: 400;
  font-size: 14px;
  color: $colorBlueEx;
}

.wrapperTalent {
  padding: 23px 24px 0;
  width: 100%;
  min-height: 704px;
}

.Talent {
  @extend %backgroundBorder;
  font-family: $rubik;

  .demographicTile {
    @include flex(flex-start, space-between);
    position: relative;

    .h2 {
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: #3f3c4c;
    }
  }

  .selected {
    font-family: $inter;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3f3c4c;
  }

  .NoOfTalents {
    font-size: 14px;
    line-height: 17px;
    color: $colorBlueBolder;
    font-weight: 700;
    margin-bottom: 23px;
  }

  .chartMb {
    margin-bottom: -25px;
  }
}

.totalTalent {
  p {
    &:first-child {
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 38px;
      color: #185c9b;
    }

    &:last-child {
      font-family: $inter;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #3f3c4c;
    }
  }
}

.demographicTotalTalent {
  text-align: center;
  position: absolute;
  top: 88px;
  right: 0;
}
