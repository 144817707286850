@import '../../../../assets/scss/variables';

.section {
  margin-bottom: 20px;
  width: 100%;
  font-family: $inter;
  display: flex;
  flex-direction: row;
  gap: 16px;
}
