.errorPage {
  // background-image: url('../../assets/img/login_screen.png');
  // background-repeat: no-repeat;
  // background-size: 50% auto;
  // background-position: 0% 100%;
  .brand-welcome {
    margin-top: 2em;
    h1 {
      font-size: 55px;
      line-height: 53px;
    }
  }
  .brand-description {
    margin-top: 30px;
    h4 {
      white-space: pre-wrap;
      font-size: 17px;
    }
  }
}
