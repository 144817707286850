.grBtn {
    gap: 8px;

    button {
        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
    }

    .cancel {
        color: #00A19C;
        border: 1px solid #00A19C;
    }

    .add {
        color: white;
        background-color: #00A19C;
        border: 1px solid #00A19C;
        margin-right: 8px;
    }
}