.cTabs{
  font-weight: bold;
  font-size: 11px;
  .nav-tabs{
    border-bottom: 1px solid #cccdcf;
    .nav-link{
      color: #000;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 0.5em 1em calc(0.5em - 3px);
      border: none;
      border-bottom: 3px solid transparent;
      margin-bottom: -1px;
      cursor: pointer;
      transition: border-color 0.3s, color 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .active{
      border-color: $primary;
      color: $primary;
      background: none;
    }
  }
}