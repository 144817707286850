@import '../../../../../assets/scss/variables';

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 32px;
}

.noteTitle {
  font-family: $rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999a9c;
}

.wrapper {
  background-color: $white;
  padding: 26px 33px;
  border-radius: 6px;
  margin-bottom: 32px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    margin-bottom: 24px;
  }

  label {
    color: $Gray32;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: none;
  }

  input {
    font-size: 14px;
  }
}

