.wrapper {
  background-color: #fee9c6;
  width: 72px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  color: #344563;
  font-size: 12px;
  font-weight: 700;
}
