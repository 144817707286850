@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.maxWidth {
  width: 1287px;
  margin: auto;
}
.containerModal {
  max-width: 1287px;
}
.control {
  width: 100%;
  margin-bottom: 50px;
  .title {
    color: $textColor;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 41px;
  }
  .listBtnLeft {
    float: right;
  }
  .itemBtn {
    font-family: $inter;
    font-weight: 500;
    line-height: 24px;
    color: #00A19C;
    background-color: white;
    border-radius: 6px;
    border: none;
    padding: 6px 18px;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    // &:hover {
    //   color: $white;
    // }
  }
}
