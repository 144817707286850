@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.detail_grid_section {
  margin: 20px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  row-gap: 40px;
  column-gap: 55px;

  .card {
    width: 280px;
    padding: 20px;
    border: 1px solid #D3DEE8;
    border-radius: 6px;
    background-color: #fff;

    font-family: $inter;
    font-weight: 400;
    font-size: 13px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    gap: 20px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      color: #999A9C;

      .name {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 14px;
        color: #3F3C4C;
      }
    }
    .action {
      margin-bottom: 20px;
      font-weight: 600;
      width: 160px;
      height: 40px;

      background-color: #00a19c;
      color: #fff;
      border-radius: 4px;

      display: flex;
      justify-content: center;
      align-items: center;
      
      cursor: pointer;
      user-select: none;
      &:active{
        transform: translateY(1px)
      }
    }
    .avatar_wrapper {
      position: relative;
    }
    .disabled{
      margin-bottom: 20px;
      font-weight: 600;
      width: 160px;
      height: 40px;

      background-color: #E5E5E5;
      color: #B1B1B1;
      border-radius: 4px;

      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

    }
  }
}
