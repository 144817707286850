@import '../../../../assets/scss/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 6px;
  padding: 50px 30px 0;
  position: relative;

  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 60px;

    .card {
      position: relative;
      width: 100%;
    }

    &.rowReverse {
      flex-direction: row-reverse;
    }
  }

  .arrowTop {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 10px);
    position: absolute;
  }
}

.mainTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 0;
  margin-right: 32px;
}

.radioContainer {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: none;
  min-width: 150px;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .radioCheckmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    background: #ffffff;
    border: 1px solid #d3dee8;
    border-radius: 50%;
  }

  &:hover input ~ .radioCheckmark {
    background-color: #ccc;
  }

  input:checked ~ .radioCheckmark {
    background: #009089;
    border-radius: 8px;
  }

  input:checked ~ .radioCheckmark:after {
    display: block;
  }

  .radioCheckmark:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
    transform: translate(-50%, -50%);
  }
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}

.editBtn {
  width: 169px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #00a19c;
  border-radius: 4px;
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: #00a19c;
}

.deleteBtn {
  position: absolute;
  top: 24px;
  right: 30px;
  border: none;
  background: transparent;
}

.sp_trash_top {
  width: 20px;
  height: 20px;
}

.info {
  cursor: pointer;
  margin-left: 8px;

  &:active {
    transform: translateY(1px);
  }
}

.tooltipIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.tooltipContent {
  span {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0168em;
    color: #161616;
  }
}
