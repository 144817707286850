@import '../../../../../assets/scss/variables';

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 32px;
}

.noteTitle {
  font-family: $rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999a9c;
}

.bottomBtnBar {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fullPageLoading {
  position: static;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
}


