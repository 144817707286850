@import '../../assets/scss/variables';

.wrapper {
  width: 100vw;
  display: flex;
  font-family: $inter;
  margin: 0;

  .leftSide {
    position: relative;
    background-color: $teal;
    min-height: 100vh;
    width: 52.5vw;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .rightSide {
    background-color: $white;
    min-height: 100vh;
    padding: 75px 90px;
    width: 47.5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    .content {
      margin-top: 75px;
      .title {
        color: #3f3c4c;
        font-weight: 700;
        font-size: 48px;
        margin-top: 16px;
      }

      .emailInput {
        position: relative;
        display: block;
        width: 360px;
        height: 36px;
        margin-bottom: -36px;
        border-radius: 6px;
        padding: 0 12px;
        border: 1px solid #e9edf0;
        box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
        font-size: 16px;
        z-index: 10;
        &::placeholder {
          color: #bfbfbf;
        }
      }
    }

    .loginBtn {
      width: 130px;
      height: 52px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 60px;
    }

    .loading {
      transform: translateY(25px);
    }

    .requestAccess {
      color: #2d2b39;
      text-align: center;
      font-family: $inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 60px;

      a,
      span {
        color: #009089;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .contact {
      color: #2d2b39;
      font-weight: 500;
      font-size: 13px;
      margin-top: 248px;

      a {
        color: #009089;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .step {
    color: #00908c;
    font-weight: 700;
  }

  p {
    color: $black;
    line-height: 20px;
    margin: 0;

    a {
      color: $teal !important;
      font-weight: 500;
    }
  }
}
