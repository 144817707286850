@import '../../../../../assets/scss/variables';

.textField {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #999a9c;
}

.deleteButton {
  background: #fbfdff;
  border: 1px solid #e9edf0;
  padding: 6px 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
}

.deleteIcon {
  width: 10px;
  height: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.customImg {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
