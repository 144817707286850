@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.searchInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8d8d8d;
  font-size: 14px;
  padding: 0 16px;
}

.control {
  width: 100%;
  margin-bottom: 20px;
  font-family: $inter;
  .title {
    color: $textColor;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    padding-bottom: 41px;
  }
  .listBtnLeft {
    float: right;
  }
  .itemBtn {
    font-family: $inter;
    font-weight: 500;
    line-height: 24px;
    color: #00a19c;
    background-color: white;
    border-radius: 6px;
    border: none;
    padding: 6px 18px;
    margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    // &:hover {
    //   color: $white;
    // }
  }
}
