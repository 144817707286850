.debug {
  position: fixed;
  top: 0;
  left: 10px;
  font-family: monospace;
}

.listing {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  margin: 0 10px;
}

.listing.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.listing.over {
  outline: 3px solid green;
}

.listing-item.over .item {
  background: green;
}

.listing-item.dragging {
  opacity: 0.3;
}

.item {
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 0.7rem;
  background: pink;
  margin: 1px;
  cursor: default;
}
.app2 {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
}
