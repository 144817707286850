@import '../../../../../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  padding: 26px 33px;
  border-radius: 6px;
  margin-bottom: 32px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    margin-bottom: 24px;
  }

  label {
    color: $Gray32;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: none;
  }

  input {
    font-size: 14px;
  }
}
