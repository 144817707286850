@import '../../../assets/scss/mixin';
@import '../../../assets/scss/variables';

.content {
  display: flex;
  align-items: center;
}

.toggle {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 38px;
}

.toggle input[type='checkbox'] {
  display: none;
}

.toggle .slider {
  background-color: #ccc;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: 0.4s;
  position: relative;
  border-radius: 50px;
}

.toggle .slider:before {
  background-color: #fff;
  bottom: 2px;
  left: 2px;
  content: '';
  position: absolute;
  transition: 0.4s;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  transform: translateX(0px);
}

.toggle input[type='checkbox']:checked + .slider {
  background-color: #072b68;
}

.toggle input[type='checkbox']:checked + .slider:before {
  transform: translateX(18px);
}

.hideText {
  @include showText(13px, 500, none, 15.73px);
  font-family: $inter;
  letter-spacing: 0.59px;
  color: #4a4a4a;
  margin-bottom: 0;
  width: 50px;
  padding-left: 10px;
}
