@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.label {
  @include showText(12px, 500, none, 20px);
  font-family: $inter;
  color: $Gray32;
  margin-bottom: 8px;
  position: relative;
  width: fit-content;
  &::after {
    position: absolute;
    content: '*';
    color: red;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
