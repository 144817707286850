@import '../../../../../assets/scss/variables';

.customLink {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0168em;
  color: $light_green;
  cursor: pointer;
}

.normalContent {
  @extend .customLink;

  font-weight: 400;
  color: #7a7a7a;
  cursor: initial;
}
