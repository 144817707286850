.wrapper {
  display: flex;
  justify-content: space-between;

  .search,
  .buttons {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
