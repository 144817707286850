.category {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  display: block;
}

.textField {
  font-size: 14px;
  letter-spacing: 0.0168em;
  color: #7a7a7a;
}

.editInput{
  width: 135px;
}
