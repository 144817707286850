@import '../../../../assets/scss/variables';
.modal {
  font-family: $inter;
  max-width: 610px !important;
  height: 410px;

  .modal_header {
    display: block;
    color: #3f3c4c;
    border: none;
    position: relative;
    text-align: center;
    padding: 43px 63px 0 63px;

    div {
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      margin: 10px 0;
      color: #8d8d8d;
      line-height: 20px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-28px, 15px);
    }
  }

  .table {
    padding: 16px 63px 63px 63px;
  }

  .form {
    margin-bottom: 15px;
  }

  .position_input {
    width: 100%;
    height: 44px;
    margin: 10px 0;
    // padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    text-indent: 10px;
    cursor: pointer;
  }

  .position_input::-webkit-input-placeholder {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    user-select: none;
  }

  .divider {
    width: 426px;
    min-width: 426px;
    margin: 44px auto;
  }

  .submitButton {
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
  }
}
