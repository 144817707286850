@import '../../../../../assets/scss/variables';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.deleteButton {
  color: #ff787f;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.role_level {
  color: #3f3c4c;
  font-weight: 700;
}

.role_level_sp {
  color: #3f3c4c;
  font-weight: 700;
}

.updated_by {
  color: $gray;
  font-size: 13px;
}

.inputRoleLevel {
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #D3DEE8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #3f3c4c;
  font-weight: 700;
  padding: 0px 16px 0px 16px;
}

.inputRoleLevelErorr {
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 44px;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #3f3c4c;
  font-weight: 700;
  border: 1px solid #ff787f;
  padding: 0px 16px 0px 16px;
}
