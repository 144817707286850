@import '../../../../assets/scss/variables';

.containerModal {
    max-width: 1287px;
    width: 1287px;
}
.containerPrint {
    padding: 40px 20px 20px;
}
.btnPrint {
    margin-right: 20px;
    align-items: center;
    padding: 15px 16px;
    width: auto;
    height: 30px;
    font-family: $inter;
    background: #00a19c;
    color: white;
    border-radius: 4px;
    border: 1px solid #00a19c;
    cursor: pointer;
}