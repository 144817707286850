@import '../../../../../assets/scss/variables';

.wrapper {
  font-weight: 700;
  padding: $pdcPrintPadding;

  .title {
    font-size: 24px;
    color: #3d3d3d;
    display: flex;
    justify-content: space-between;
  }

  .subTitle {
    font-size: 18px;
    margin-top: 20px;
    color: #3f3c4c;
  }
}
