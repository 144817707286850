@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;
$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.talent_search_form {
  max-width: 700px !important;

  .talent_search_content {
    padding: 53px 63px 64px;
  }

  .modal_header {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-family: $inter;
      font-size: 20px;
      line-height: 24px;
      margin: 0 auto;
    }
  }

  .modal_body {
    height: 100%;
  }

  .title_search {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #525252;
  }

  .content_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_profile_name {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_profile_unit {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_active {
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #00a19c;
  }

  .text_no_active {
    background: rgba(52, 69, 99, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .text_no_data {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .talent_search_button {
    margin-top: 44px;
    display: flex;
    justify-content: flex-end;
  }

  .add_talent_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    width: 100px;
    height: 40px;
    background: #00a19c;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .search_box {
    position: relative;
    margin-top: 10px;

    .position_input {
      width: 100%;
      height: 44px;
      border-radius: 2px;
      border: 1px solid #d3dee8;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      text-indent: 10px;
    }

    .position_input::-webkit-input-placeholder {
      font-family: $inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
      user-select: none;
    }

    .position_input::-webkit-search-cancel-button {
      /* Remove default */
      // -webkit-appearance: none;
      /* Now your own custom styles */
      cursor: pointer;
      margin-right: 10px;
    }

    .lookup {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(50%, -50%);
      cursor: pointer;
    }
    .search_notFound {
      height: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 10px;
    }
    .search_list {
      list-style-type: none;
      margin: 0;
      width: 100%;
      min-height: 36px;
      max-height: 500px;
      border-radius: 3px;
      border: 1px solid #e9edf0;
      box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
      padding: 0;
      background-color: #fff;
      color: #000;
      bottom: 0;
      z-index: 10;
      overflow-y: auto;
      .search_item {
        padding: 15px;
        border-bottom: 1px solid $borderColor;
        cursor: pointer;
        width: 100%;
        &:hover {
          background-color: $light-pink;
        }
        .individual {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #7a7a7a;
          display: flex;
          flex-direction: column;
        }
        .name {
          color: $nameTextColor;
          margin-bottom: 10px;
          font-weight: 600;
          line-height: 16px;
          p {
            margin-bottom: 0;
            color: #000;
          }
        }
      }

      .dot_flashing {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 0.5s;
        margin-left: 28px;
        margin-top: 10px;
      }

      .dot_flashing::before,
      .dot_flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }

      .dot_flashing::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
      }

      .dot_flashing::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dotFlashing {
        0% {
          background-color: #9880ff;
        }
        50%,
        100% {
          background-color: #ebe6ff;
        }
      }
    }
  }
}
