.collapseBody__line {
  border-radius: 6px;
  transition: all 0.25s ease;
  margin-bottom: 10px;

  .dragging {
    opacity: 0.7;
  }
  &__over {
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}

.collapseBodyAgree__line {
  border-radius: 6px;
  transition: all 0.25s ease;

  display: flex;
  justify-content: flex-start;
  gap: 11px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  overflow: auto;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    display: flex;
    justify-content: flex-start;
    gap: 11px;
    flex-wrap: wrap;
    overflow: auto;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}

.collapseBody__lineRecommendation {
  font-weight: 400;
  border-radius: 6px;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}
