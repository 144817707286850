@import '../../../assets/scss/variables';

.maxWidth {
  width: 1287px;
  margin: auto;

  .title {
    font-weight: 700;
    color: #3f3c4c;
    font-size: 24px;
    padding-bottom: 41px;
    margin-top: 20px;
  }

  .buttonPosition {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .button {
    background: #00a19c;
    border: 1px solid transparent;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 7px 17px;
    font-family: $inter;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    height: 32px;
    cursor: pointer;
    user-select: none;

    &:active {
      transform: translateY(1px);
    }
  }

  .buttonColorWhite {
    background-color: #fff;
    color: #00a19c;
  }

  .buttonDisable {
    background-color: #e9edf0;
    color: #fff;
    cursor: default;

    &:active {
      transform: none;
    }
  }

  .list {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 30px 20px;
    width: 100%;
    border: 1px solid #d3dee8;
    border-radius: 6px;
    font-family: $inter;

    .titleDetail {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #3f3c4c;
      margin-bottom: 20px;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #525252;
    }

    .input {
      width: 100%;
      border-radius: 4px;
      padding: 10px 16px;
      font-family: $inter;
      border: 2px solid #d9d9d9;
      font-size: 14px;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #8d8d8d;
        // opacity: 1; /* Firefox */
      }

      &:hover,
      &:focus {
        border-color: #00a19c;
        box-shadow: 0 0 0 2px rgba(0, 161, 156, 0.2);
      }
    }
    .inputError {
      border: 2px solid #ff787f;

      &:hover,
      &:focus {
        border-color: #ff787f;
        box-shadow: 0 0 0 2px rgba(0, 161, 156, 0.2);
      }
    }
  }
}

.modalChain {
  font-family: $inter;
  max-width: 1400px !important;
  height: 410px;
}

.disableHrPartner {
  svg {
    display: none;
  }
}
