@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

@mixin title-cell {
  border-top: 1px solid #d3dee8;
  border-bottom: 1px solid #d3dee8;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin profile-text {
  color: #3f3c4c;
  font-family: $inter;
  font-weight: 700;
}

@mixin textarea-style {
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}

.modalWrapper {
  .modal_header {
    border: none;
    margin-bottom: 41px;
  }

  .title_search {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #525252;
  }

  .content_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text_active {
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.3px;

    color: #00a19c;
  }

  .text_no_active {
    background: rgba(52, 69, 99, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_no_data {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #344563;
  }

  .talent_wrapper {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #e7edf3;
  }

  .each_talent {
    border-radius: 6px;
    border: 1px solid #cbd6e2;
    display: grid;
    grid-template-columns: 20% 10% 70%;
    font-family: $inter;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .item1 {
    grid-row: span 3;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
  }

  .birth_name {
    font-size: 24px;
    @include profile-text;
  }

  .jgsg {
    font-size: 18px;
    @include profile-text;
  }

  .item2 {
    grid-row: 1;
    grid-column: 2;
    background-color: #00a19c;
    @include title-cell;
  }

  .item3 {
    grid-row: 2;
    grid-column: 2;
    background-color: #00a19c;
    @include title-cell;
  }

  .item4 {
    grid-row: 3;
    grid-column: 2;
    background-color: #ff7e01;
    @include title-cell;
  }

  .item5 {
    grid-row: 1;
    grid-column: 3;
    @include textarea-style;
    border-bottom: 1px solid #e7edf3;
  }

  .item6 {
    grid-row: 2;
    grid-column: 3;
    @include textarea-style;
    border-bottom: 1px solid #e7edf3;
  }

  .item7 {
    grid-row: 3;
    grid-column: 3;
    @include textarea-style;
  }

  .btnRow {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .avatar {
    margin-right: 20px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
