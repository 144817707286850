@import '../../../../../assets/scss/variables';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.deleteButton {
  color: #ff787f;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.positionCode {
  color: #3f3c4c;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.positionName {
  color: #3f3c4c;
  font-weight: 700;
}

.approvedDate {
  color: $gray;
  font-size: 13px;
}

.inputBusiness {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d3dee8;
}

.inputBusinessErorr {
  border: 1px solid #ff787f;
}
