@import '../../../assets/scss/variables';

.wrapperContainer {
  display: flex;
  flex-direction: column;
  max-width: 1287px;
  margin: 0 auto;
}

.reportName {
  font-family: $inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.3px;
  text-align: left;
  color: #3f3c4c;
  margin-top: 20px;
  margin-bottom: 40px;

  span {
    margin-right: 20px;
  }

  img {
    cursor: pointer;
  }
}

.mainTitle {
  font-family: $inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.0216em;
  text-align: left;
  color: #3f3c4c;
}

.notFound {
  width: 100%;
  margin: 40px 0;
}

.groupColumn {
  padding: 40px 0;

  .columnTitle {
    font-family: $rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #999a9c;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    .editBtn {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      outline: none;
      font-family: $inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: #00908c;
      margin-left: auto;

      img {
        margin-right: 10px;
      }
    }
  }
}

.groupBotButton {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.customDropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: solid 1px#EBEBEB;
  border-radius: 4px;
  padding: 10px;

  img {
    margin-right: 10px;
  }

  button {
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    text-align: left;
    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0168em;
    color: #181818;
    padding: 5px;

    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }

    &:first-child {
      &:hover {
        background: #f4f5f8;
      }
      img {
        width: 16px;
      }
    }

    &:nth-child(2n) {
      color: #00908c;
      &:hover {
        background: #afdddc;
      }
    }
    &:last-child {
      color: #da2228;
      margin-bottom: 0;
      &:hover {
        background: #fef0f0;
      }
      img {
        width: 18px;
      }
    }
  }
}

.dragDrop {
  img {
    display: none;
  }

  &:hover {
    img {
      display: inline-block;
    }
  }
}

.itemWrapper {
  display: flex;
  align-items:  center;
  justify-content: space-between;
}
