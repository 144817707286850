.wrapper {
  display: flex;
  justify-content: space-between;

  .search,
  .buttons {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

.customBtn {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 32px;
}