@import '../../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;
  color: #3f3c4c;
  margin-bottom: 30px;

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .btnRow {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin: 30px 0;
  }

  .firstForm {
    background-color: $white;
    padding: 20px 30px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;

    .baseForm {
      display: flex;
      flex-direction: column;
      border: 1px solid $geyser;
      border-radius: 4px;
      padding: 20px 24px;
      padding-bottom: 30px;

      .dateInputRow {
        margin-top: 16px;
        width: 350px;
        display: flex;
        gap: 16px;
        align-items: center;

        .dateDash {
          padding-top: 24px;
        }
      }
    }

    .thumbnailForm {
      width: 330px;
      .thumbnailUpload {

        .spotlight{
          display: flex;
          gap: 6px;
          margin-top: 10px;
        }

        .uploadFile {
          padding: 16px;

          .uploadIcon img {
            width: 24px;
            height: 24px;
          }

          .boldText {
            font-size: 12px;
            margin: 8px 0;
          }

          .uploadInstruction {
            font-size: 12px;
          }

          .browseBtn {
            background-color: transparent;
            text-decoration: underline;
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .customizeBlog {
    background: $white;
    padding: 20px 30px;

    .subTitle {
      font-size: 16px;
      font-weight: 600;
    }

    .addAttachBtn {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 17px;
      margin-right: 56px;
    }
  }
}

.sortableBlock {
  .block {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;

    .left {
      border: 1px solid $geyser;
      width: 100%;
      border-radius: 4px;
      display: flex;

      textarea {
        border: none;
        padding: 16px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-end;

      .iconBtn {
        padding: 0;
        width: 40px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $geyser;
      }
    }
  }
}

.uploadContainer {
  width: 100%;

  .uploading {
    display: flex;
    gap: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2300A19CFF' stroke-width='2' stroke-dasharray='8%2c8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    padding: 10% 2%;

    .info {
      display: flex;
      justify-content: space-between;

      .fileName {
        color: #3f3c4c;
        font-weight: 600;
        font-size: 14px;
      }

      .remainTime {
        font-size: 12px;
        font-weight: 400;
        color: $teal;

        span {
          color: #3f3c4c;
          font-weight: 700;
        }
      }
    }

    progress {
      border-radius: 7px;
      width: 100%;
      height: 8px;
    }
    progress::-webkit-progress-bar {
      background-color: #ebebeb;
      border-radius: 7px;
    }
    progress::-webkit-progress-value {
      background-color: $teal;
      border-radius: 7px;
    }
  }

  input {
    display: none;
  }

  .fileContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;

    .videoElement {
      width: 100%;
      display: block;
      border-radius: 12px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
    }

    .playButton img {
      width: 60px;
      height: 60px;
    }

    .durationLabel {
      position: absolute;
      bottom: 30px;
      left: 30px;
      background-color: #292929;
      color: $white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
    }

    .actionButtons {
      position: absolute;
      bottom: 30px;
      right: 30px;
      display: flex;
      gap: 10px;
    }

    .actionButtonsThumbnail {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      gap: 10px;
      transform: translate(-50%, -50%);
    }

    .iconBtn {
      padding: 0;
      width: 40px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      max-width: 100%;
    }
  }

  .uploadFile {
    position: relative;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    background-color: #f4fbfb;
    width: 100%;
    margin: 0 auto;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2300A19CFF' stroke-width='2' stroke-dasharray='8%2c8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");

    &.isDragActive {
      border-color: #45a5a5;
    }

    .uploadIcon img {
      width: 80px;
      height: 80px;
    }

    .boldText {
      font-weight: 700;
      font-size: 16px;
      color: #3d3d3d;
      margin: 16px 0;
    }

    .uploadInstruction {
      font-size: 14px;
      color: #7a7a7a;
    }
  }
}
