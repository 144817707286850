@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';


.table {
  // margin-top: 20px;
  // max-height: 700px;
  // overflow-y: overlay;

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  input[type='checkbox']:checked {
    accent-color: #00a19c;
    padding: 1px;
  }
  
  table {
    border: 1px solid #e7e7f0;
    width: 100%;
  }

  td {
    align-self: flex-start;
    text-align: center;
    padding: 0;
  }

  thead > tr {
    position: relative;
    display: flex;
  }

  tbody {
    display: block;
    max-height: 1000px;
    border-radius: 6px;
    overflow-y: overlay;
  }

  tbody > tr:last-child {
    border-bottom: none;
  }

  &_header {
    background: #feffff;
    border-bottom: 1px solid #d3dee8;
    padding: 15px;
    color: #8896a7;
    font-family: $inter;
    font-weight: 700;
    font-size: 14px;
    height: 53px;

    .nameHeader {
      text-align: left;
    }
  }

  &_body {
    @include flex(center, flex-start);
    padding: 15px 15px;
    border-bottom: 1px solid #ececec;
    font-weight: 400;
    font-size: 14px;

    .nameBody {
      text-align: left;
    }
  }
}

.ellipsis {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.dropdownContent {
  padding: 0.5rem;
  position: absolute;
  background: $white;
  font-weight: 400;
  top: -5px;
  right: 50px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  font-size: 14px;
  z-index: 10;
  min-width: 122px;

  .function {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background: #f2f2f2;
    }
  }

  .content_edit {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: $colorBlueBolder;
  }

  .editIcon {
    width: 14px;
    height: 14px;
  }
}

.lastItem {
  top: -5px;
  right: 50px;
}

.isChecked {
  background: #f7f0ff;
  border-color: rgba(0, 0, 0, 0.03);
}

.menu_ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
}

.btn_remove {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background: #eeeeee;
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 10px;
    height: 10px;
  }
}
