@import '../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;

  .title{
    font-weight: 700 ;
    font-size: 18px;
    color: #787587;
    margin-bottom: 30px;
  }

  .textFits {
    display: flex;
    justify-content: flex-end;
    padding-right: 65px;
  }

  .fitContainer {
    display: flex;
    flex-direction: column;

    .fitRow {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e7edf3;
      padding: 20px;
      padding-right: 40px;

      &:last-child {
        border: none !important;
      }

      .fitCell{
        width: 100px;
      }
    }
  }

  .text1, .text2 {
    color: #181818;
  }

  .text1{
    font-weight: 500;
    width: 130px;
  }

  :global {
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #fff !important;
    }
  }
}
