@import '../../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;
}

.divider {
  height: 1px;
  background-color: #cbd6e2;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title {
  color: #3f3c4c;
  font-weight: 700;
  font-size: 18px;
  padding: $pdcPrintPadding;
}

.proposal {
  padding: 0 $pdcPrintPadding;
  gap: 10px;
  overflow: hidden;

  .header {
    display: flex;
    height: 72px;
    align-items: center;
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid #dedede;
    gap: 20px;

    .name {
      display: flex;
      width: 300px;
      gap: 20px;
      align-items: center;

      img {
        border-radius: 50%;
        width: 48px;
        height: 48px;
      }
    }

    .position {
      border-left: 1px solid #cbd6e2;
      padding-left: 20px;
      width: 500px;
    }

    .more {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type {
        padding-left: 20px;
        color: $teal;
        font-size: 16px;
        font-weight: 600;
      }

      .btnRow {
        padding: 0;
      }
    }

    .label {
      font-weight: 500;
      font-size: 12px;
      color: #7a7a7a;
    }

    .content {
      font-weight: 600;
      font-size: 16px;
      color: #3f3c4c;
    }
  }
}
