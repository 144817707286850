@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';
.modal {
  max-width: 1000px !important;

  .modal_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #3f3c4c;
    font-family: $inter;
    border: none;
    width: 100%;
    padding: 40px 80px;

    div {
      font-weight: 700;
      font-size: 24px;
      margin: 10px 0;
    }

    button {
      position: absolute;
      right: 0;
      transform: translate(-80px, 15px);
    }
  }
  .error {
    border: 1px solid red !important;
  }
  .table {
    padding: 0 80px;
    height: 500px;

    input {
      width: 100%;
      font-family: $inter;
      font-weight: 400;
      font-size: 14px;
      color: #8d8d8d;

      height: 44px;
      padding: 10px 15px;

      border-radius: 4px;
      border: 1px solid #d3dee8;
    }

    textarea {
      width: 100%;
      font-family: $inter;
      font-weight: 400;
      font-size: 14px;
      resize: none;
      height: 180px;
      padding: 10px 15px;

      border-radius: 4px;
      border: 1px solid #d3dee8;
    }
    .label {
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 8px;
      color: #3f3c4c;
    }

    .date {
      position: relative;
    }
    .calendar {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 5%;
    }
    .dateInput {
      background-color: #e0e0e0;
    }
    .row {
      width: 836px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      div {
        padding: 0px;
      }

      span {
        position: absolute;
        display: inherit;
        color: #d92828;
      }
      .add {
        background-color: $teal;
        border-radius: 6px;
        color: white;
        width: 180px;
        padding: 10px 30px;

        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        position: relative;
        &:active {
          transform: translateY(1px);
        }

        div {
          color: white;
        }
      }
    }

    .category {
      position: relative;
      cursor: pointer;
    }
    .arrow {
      position: absolute;
      right: 5%;
      top: 65%;
    }
    .dropdown {
      position: absolute;
      z-index: 10;
      border-radius: 6px;

      width: 100%;
      background-color: white;
      cursor: pointer;
      transform: translateY(3px);

      div {
        border: 1px solid #d3dee8;
        padding: 10px;
        width: 100%;

        &:hover {
          background-color: powderblue;
        }
      }

      div:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      div:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
