@import '../../../../assets/scss/variables';

.container {
  width: 100%;
  padding: 40px 30px;
  overflow-x: auto;
  display: flex;
  gap: 20px;
}

.drop {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e7e7f0;
}

.addPosition {
  width: 366px;
  min-height: 164px;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: $inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #787587;
  user-select: none;
  text-align: center;
  padding: 20px;
}

.addPositionError {
  border: 2px solid #ff787f;
}
