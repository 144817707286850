@import '../../../assets/scss/variables';

.title {
  color: var(--Emphasis-Body-N800, #3f3c4c);
  font-family: $inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}

.rowInput {
  display: flex;
  margin-bottom: 1rem;
  gap: 16px;

  .inputItem {
    flex: 1 1 20%;
  }
}

.wrapper {
  font-family: $inter;
}

.btnRow {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 15px;

  & > div {
    display: flex;
    gap: 10px;
  }
}
