@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.remark {
  max-width: 555px !important;
  height: 318px;
  font-family: $inter;
  .modal_header {
    border: none;

    .title {
      font-family: $inter;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.833333px;
      margin: 41px 0px 0px 34px;
      color: #4a4a4a;
    }
  }

  .table {
    margin: 30px 30px;
    padding: 0;
    border: 1px solid $tableColor;
    border-radius: 6px;

    &_header {
      @include flex(flex-start, flex-start);
      padding: 15px 20px;
      border-bottom: 1px solid #ececec;
      font-weight: 500;
      font-size: 16px;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .modal_body {
      height: 400px;
      padding: 0;
    }

    &_body {
      @include flex(flex-start, flex-start);
      padding: 15px 20px;
      border-bottom: 1px solid #ececec;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.col_1 {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ellipsis {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.dropdownContent {
  padding: 0.5rem;
  position: absolute;
  background: $white;
  font-weight: 400;
  top: -5px;
  right: 50px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  font-size: 14px;
  z-index: 10;
  height: 80px;

  .function {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background: #f2f2f2;
    }
  }
  .content_edit {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: $colorBlueBolder;
  }
  .editIcon {
    width: 14px;
    height: 14px;
  }
  .content_delete {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #ff787f;
  }
  .deleteIcon {
    width: 14px;
    height: 18px;
  }
}

.lastItem {
  top: -5px;
  right: 50px;
}

.edit_remark {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.input_remark {
  background: #ffffff;
  border: 1px solid #d3dee8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 103px;
  width: 100%;
  margin-bottom: 11px;
}

.btn_save {
  background: $teal;
  border-radius: 6px;
  height: 36px;
  width: 80px;
}

.text_btn_save {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
}

.searchInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8D8D8D;
  font-size: 14px;
  padding: 0 16px;
}

.search_list {
  list-style-type: none;
  margin: 0px 0px 21px 0px;
  width: 361px;
  border: 1px solid #e9edf0;
  padding: 5px 12px 0;
  background-color: #fff;
  color: rgba(0,0,0,0.25);
  position: absolute;
  bottom: 0;
  z-index: 10;
  transform: translateY(102%);
  height: auto;
  max-height: 303px;
  overflow-y: scroll;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px #0000001a;

  .search_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 74px;
    gap: 20px;
    padding: 5px;
    border-bottom: 1px solid $borderColor;
    cursor: pointer;

    .avatar {
      img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .individual {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $textColor;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .name {
        color: $nameTextColor;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .dot_flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    transform: translate(100%, 80%);
  }

  .dot_flashing::before,
  .dot_flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot_flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot_flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
}

.label {
  &::after {
    display: none !important;
  }
}
