@import '../../../assets/scss/variables';

.controlBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.createMobilityBar {
  background-color: $white;
  padding: 45px 30px;
  margin-bottom: 20px;
  position: relative;

  .talentType {
    position: absolute;
    top: 15px;
    right: 30px;
  }

  .talentTypeValue {
    font-family: $inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #3d3d3d;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #ebebeb;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    color: #3f3c4c;
  }
}

.noContent {
  display: flex;
  justify-content: space-between;
}

.listVerticalWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 38px;
}

.controlButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.tooltipContent {
  font-size: 12px;
  
  span {
    font-weight: 500;
  }
}
