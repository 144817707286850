@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixin";

.Personality {
  padding: 32px 17px 30px 33px;
  background-color: $light-blue;
  border-radius: 6px;
  &__h3 {
    font-family: $inter;
    @include showText(24px, 600, none, 29.05px);
    color: $dark-gray;
    padding-bottom: 17px;
  }
  &__table {
    background-color: white;
    border-radius: 6px;
    p {
      margin-bottom: 0;
    }
    table {
      width: 100%;
      tr {
        border-bottom: 1px solid #ececec;
      }
    }

    thead {
      tr {
        background: rgba(0, 0, 0, 0.0001);
      }
    }
    tr:not(:last-child) {
      background: rgba(0, 0, 0, 0.0001);
    }
    th {
      padding: 17px 0 13px 0;
      p {
        @include showText(14px, 500, none, 16.94px);
        font-family: $inter;
        color: $dark-blue;
      }
      &:first-child {
        padding-left: 65px;
        width: 322px;
      }
    }
    td {
      padding: 13px 0 11px 0;
      p {
        color: $dark-blue;
        font-family: $inter;
        @include showText(14px, 400, none, 16px);
      }
      &:first-child {
        padding-left: 65px;
      }
      &:last-child {
        padding-right: 52px;
      }
    }
  }
}
