@import '../../../../assets/scss/variables';

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 12px;
}

.errorMsg {
  font-family: $inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: #f36568;
  padding-top: 10px;
}
