@import 'variables';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  /*-ms-overflow-style: -ms-autohiding-scrollbar;*/
}

html,
body {
  font-family: $rubik;
  overflow-y: overlay !important;
  position: relative;
}

.container-fluid {
  padding-top: 50px;
  overflow: auto;
}

body {
  background-color: #f4f5f8;
}

p {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}

hr {
  border: 0px;
  height: 1px;
  background-color: #dadada;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 8px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  // background: $primary;
  background: rgba(74, 74, 74, 0.5);
}

::-webkit-scrollbar-track {
  width: 6px !important;
  height: 8px !important;
  // background-color: rgba(255, 255, 255, 0);
  background-color: rgba(196, 196, 196, 0.5);
}

//Changed base font size for all tooltip
.tooltip {
  font-size: 6px;
}

select {
  border-radius: 5px;
  padding: 3px;
  border-color: #999a9b;
}

.text-primary {
  color: $primary;
}

.mt-5 {
  margin-top: 5px;
}

// .text-secondary{}

.unselect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
