@import '../../../../../assets/scss/variables';

.positionCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;

  .positionName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    border-left: 8px solid #66b2c4;
    color: $teal;
    padding: 8px 10px 8px 12px;
    min-height: 62px;

    span {
      font-weight: 600;
    }

    img {
      cursor: pointer;
    }
  }
}

.dragDropBox {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: $inter;
  color: var(--Light-Gray-400, #a3a3a3);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.5px;
  border-radius: 4px;
  border: 1px solid var(--Light-Gray-200, #ebebeb);
  background: var(--General-White, #fff);
}
