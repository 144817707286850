@import '../../../assets/scss/variables';

.wrapper {
  background-color: white;
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 107px;
  font-family: $inter;
}

.title{
  font-weight: 700;
  font-size: 32px;
  color: #3f3c4c;
  line-height: 38.73px;
  padding-bottom: 20px;
  width: 90%;
}

.desc{
  font-weight: 500;
  font-size: 13;
  color: #9f9eac;
  line-height: 20px;
  padding-bottom: 35px;
  width: 58%;
}