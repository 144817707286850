@import '../../assets/scss/variables';

.container {
  padding-top: 30px;
  border-radius: 6px;
  background: #ffffff;
  overflow-x: auto;
}

.title1,
.title2 {
  margin-bottom: 30px;
  color: #3d3d3d;
  font-size: 20px;
  font-family: $inter;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.12px;

  span:last-child {
    color: #677c91;
  }
}

.title2 {
  font-size: 16px;
  margin-bottom: 20px;
}

.staff_infor {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #3D3D3D;
  margin-bottom: 3px;
}

.table {
  font-family: 'Inter', sans-serif;
  max-height: 800px;
  overflow-y: auto;
  width: 100%;
  color: #3f3c4c;
  min-width: 985px;
  margin-top: 20px;

  .header {
    display: flex;
    width: 100%;
  }

  .headerCell {
    display: flex;
    padding: 16px 12px;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid #e7edf3;
    background: #e7edf3;
    color: #4f5f6e;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.235px;
  }

  .body {
    display: flex;
    width: 100%;
    flex-direction: column;

    .row {
      display: flex;
      border-bottom: 1px solid #e7edf3;
    }

    .cellData {
      display: flex;
      flex-direction: column;
    }

    .cellRow {
      border-bottom: 1px solid #e7edf3;
      padding: 16px 12px;
      min-height: 55px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .criteriaCell {
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    font-weight: 700;
    color: #4f5f6e;
  }

  .matchingStatus {
    width: 12px;
    height: 12px;
    border-radius: 4px;
  }

  .total {
    display: flex;
    justify-content: space-between;
    background-color: #f4f0f8;

    .percentage {
      padding: 16px 12px;
      flex-basis: 27%;
    }
  }
}
