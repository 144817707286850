@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.max_width {
  width: 1287px;
  margin: auto;
  font-family: $inter;
}
.show_exporting {
  overflow: hidden;
  transition-duration: 300ms;
}
.control {
  width: 100%;
  .title {
    font-weight: 700;
    color: #3f3c4c;
    font-size: 24px;
    padding-bottom: 41px;
    margin-top: 20px;
  }
}
.not_found {
  margin: 20px auto;
  img {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  gap: 20px;
  background-color: #fff;
}
