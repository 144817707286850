@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.container {
  border: 1px solid #e7e7f0;
  border-radius: 6px;
  background-color: #fff;
  width: 230px;
  padding: 10px;
  font-family: $inter;
  font-weight: 500;
  font-size: 12px;
  position: relative;

  display: flex;
  justify-content: space-between;

  .position_name {
    font-weight: 600;
    color: #50a4b9;
    max-width: 172px;
  }
  .content {
    color: #525252;
    margin: 10px 0;
  }
  .approve {
    padding: 4px 8px;
    color: #00847c;
    background-color: #e0f1f1;
    border-radius: 1px solid #e0f1f1;
    border-radius: 6px;
  }
  .new {
    padding: 4px 8px;
    color: #1c3b92;
    background-color: #bcc6e1;
    border-radius: 1px solid #bcc6e1;
    border-radius: 6px;
  }
}

.collapseBodyCard__line {
  //   border: 1px solid #e7e7f0;
  //   border-radius: 6px;\

  border-radius: 6px;
  transition: all 0.25s ease;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}

.collapseBodyCard__lineRecommendation {
  //   border: 1px solid #e7e7f0;
  //   border-radius: 6px;\

  width: 100%;
  font-weight: 400;
  border-radius: 6px;
  .dragging {
    opacity: 0.7;
  }
  &__over {
    border-radius: 6px;
    box-shadow: 0px 0px 2px 5px #0000000d;
  }
}

.revertRemark{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
}
