@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.collapse {
  height: 100%;
  border-radius: 6px;
  margin-bottom: 15px;
  overflow: hidden;
  .collapseHeader {
    background-color: #637ab8;
    width: 100%;
    height: 33px;

    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 10px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    padding: 12px 10px;
  }

  .recommendationFilter {
    display: flex;
    gap: 8px;

    img {
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }

  .body {
    background-color: #fffaef;
    border: 1px solid #e7e7f0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 100%;
    transition: max-height 333ms ease-out;
  }

  .filterForm {
    overflow: hidden;
    transition: max-height 333ms ease-out;

    button:last-child {
      margin-bottom: 16px;
    }
  }

  img {
    transition: transform 333ms ease-out;
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .animate {
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .sortIcon {
    transition-duration: 0s;
    cursor: pointer;
    margin-right: 4px;

    &.sortDesc {
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  .top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    font-family: $inter;
    font-weight: 400;
    font-size: 12px;
    color: #50a4b9;

    div {
      padding: 8px 12px;
      border: 1px solid #50a4b9;
      border-radius: 4px;
      cursor: pointer;
    }

    .activeFilter {
      color: white;
      background-color: #50a4b9;
    }
  }

  .table {
    table {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      width: 100%;
    }

    td {
      text-align: center;
      padding: 0;
    }

    tbody {
      display: block;
      max-height: 960px;
      border-bottom: 1px solid #e7e7f0;
      border-radius: 6px;
      overflow-y: auto;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    tbody > tr:last-child {
      border-bottom: none;
    }
    .tableHeader {
      background-color: #50a4b9;
      border: 1px solid #50a4b9;
      padding: 10px;
      color: white;
      font-family: $inter;
      font-weight: 500;
      font-size: 10px;
      height: 30px;

      .nameHeader {
        text-align: left;
        padding-left: 6px;
      }
    }

    .tableBody {
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      height: 30px;
      border: 1px solid #e7e7f0;
      background-color: #fff;
      cursor: pointer;
      .name {
        font-weight: 600;
        color: #50a4b9;
        text-align: left;
      }
    }

    .tableBodyCard {
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      height: 30px;
      border: 1px solid #e7e7f0;

      .name {
        font-weight: 600;
        color: #50a4b9;
        text-align: left;
      }
    }
    &.filteredResult {
      tbody {
        max-height: 320px;
      }
    }
  }
}
.recommendation {
  &__name {
    text-decoration: none;
    font-family: $inter;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include showText(12px, 600, none, 20px);
    color: $recommnendationText;
    &:hover {
      text-decoration: none;
    }
  }
}

.no_data {
  font-family: $inter;
  font-weight: 500;
  font-size: 12px;
  padding: 5px;
}

.card_box {
  font-family: $inter;
  font-size: 13px;
  font-weight: 400;
  color: #525252;
  border-left: 6px solid #bdb6ea;
  padding: 10px 10px;
  background-color: #fff;
  div {
    margin-bottom: 5px 0;
  }

  .number {
    font-weight: bold;
    font-size: 14px;
    color: #3f3c4c;
    line-height: 20px;
  }

  span {
    font-weight: 500;
  }

  .icon_card {
    img {
      margin-left: 10px;
    }
  }

  .position {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
    text-align: left;
  }

  .right {
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: 400;

    span {
      font-weight: 500;
    }
  }

  .image {
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
}

.approve {
  padding: 4px 8px;
  color: #00847c;
  background-color: #e0f1f1;
  border-radius: 1px solid #e0f1f1;
  border-radius: 6px;
}
.new {
  padding: 4px 8px;
  color: #1c3b92;
  background-color: #bcc6e1;
  border-radius: 1px solid #bcc6e1;
  border-radius: 6px;
}

.noFiltered {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.173px;
  color: $black;
  text-align: center;
  line-height: 130px;
}

.filteredTableButton {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 10px;
}
