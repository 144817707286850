@import '../../../../../assets/scss/variables';

.icons {
  transform: translate(-15%, 20%);

  img {
    margin-right: -12px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    pointer-events: all;
  }

  img:active {
    transform: translateY(3%);
  }
}

.inActiveDelBtn {
  background-color: #e7e7f0;
  color: white;
}

.activeDelBtn {
  background-color: #ff787f !important;
  color: white !important;
  border-color: #ff787f !important;
}

.searchInputField {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8d8d8d;
  font-size: 14px;
  padding: 0 16px;
}

.clearFilterButton {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00847c;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}

.wrapperFilter {
  position: relative;
}

.activeIcon {
  position: absolute;
  top: 12px;
  left: 15px;
}
