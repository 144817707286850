@import '../../assets/scss/variables';

.wrapperContainer {
  font-family: $inter;
  display: flex;
  flex-direction: column;
  max-width: 1287px;
  margin: 0 auto;

  .title {
    font-size: 24px;
    color: #3f3c4c;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
  }
}
