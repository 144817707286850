@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';


.checkedFilter {
    width: 14px;
    height: 14px;
}

.checkedFilter:checked {


    outline: none;
    accent-color: #00a19c;
    -moz-outline-radius: 3px;


}

.checkedFilter:not(:checked) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border: 1px solid #cecccc;
    border-radius: 3px;

    -moz-outline-radius: 5px;
}