@import '../../assets/scss/variables';

.title {
  font-weight: 700;
  color: #3f3c4c;
  font-size: 24px;
  padding-bottom: 41px;
  margin-top: 20px;
}

.controlBar {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .searchBar {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: flex-end;

    .searchInput {
      width: 496px;
      height: 44px;
      border: 1px solid $geyser;
      border-radius: 4px;
      padding: 12px 16px;
      margin-right: 10px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }
  }

  .controlBtn {
    display: flex;
    gap: 8px;
  }
}
