@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;

.talent_review {
  margin: 26px 0;
  border-radius: 6px;
  background-color: $white;
  padding: 40px 30px 17px;
  font-family: $inter;

  .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .content {
      font-size: 13px;
      padding: 15px 0;
      border-bottom: 1px solid $gray;
      display: flex;
      justify-content: flex-start;
      gap: 20px;

      & span:nth-child(1) {
        font-weight: 700;
        margin-right: 5px;
      }

      &:last-child {
        border: none;
      }

      .platform {
        background-color: tomato;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        color: $white;
      }

      .right {
        P {
          color: $colorText;
          font-weight: 400;
          font-size: 13px;
        }
        .date {
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
  }

  .view_more {
    margin-top: 20px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    text-align: end;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      background: url('../../assets/img/pets-chevron-right.svg');
      background-position: center;
      width: 11px;
      height: 11px;
      position: absolute;
      transform: translate(50%, 30%);
    }
  }
}
