@import '../../../assets/scss/variables';

.title {
  font-weight: 700;
  color: #3f3c4c;
  font-size: 24px;
  padding-bottom: 41px;
  margin-top: 20px;
}

.buttonPosition {
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.section {
  margin-bottom: 20px;
  background-color: #fff;
  width: 100%;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  font-family: $inter;
  padding: 40px;
}

.titleDetail {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #3f3c4c;
  margin-bottom: 20px;
}

.mobilitySimulationLegendLabel {
  font-weight: 400;
  font-size: 10px;
  color: #444444;
}

.info {
  cursor: pointer;
  margin-left: 8px;

  &:active {
    transform: translateY(1px);
  }
}

.tooltipIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.tooltipContent {
  span {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0168em;
    color: #161616;
  }
}
