@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.filter_section {
  margin: 20px 0;
}

.search_section {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 2;

  .search {
    width: 496px;
    height: 40px;
    border: 1px solid #d3dee8;
    border-radius: 6px;
    padding-left: 10px;
  }

  .search_input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8d8d8d;
    user-select: none;
  }

  .filterBtn {
    width: 40px;
    height: 40px;
    padding: 12px;
    background: #fbfdff;
    border: 1px solid $teal;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin: 0 12px;
    cursor: pointer;

    &.inActive {
      border: 1px solid #bac7d5;
    }
  }

  .button:active {
    transform: translateY(2px);
  }

  .btnRow {
    display: flex;
    gap: 10px;
  }

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;

    .betweenBorder {
      height: 100%;
      border-left: 1px solid #e9edf0;
    }

    .img {
      cursor: pointer;
      pointer-events: all;
      padding: 8px 10px;
      background: $white;
      border: 1px solid #e9edf0;
      height: 16px;
      box-sizing: content-box;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: none;
      }
      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-left: none;
      }
    }

    .img:active {
      transform: translateY(3%);
    }
  }
}
