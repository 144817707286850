@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.containerModal {
  max-width: 700px;
}

.otherExperience {
  &__header {
    padding: 23px 23px 18px 30px;
  }
  &__title {
    font-family: $inter;
    @include showText(20px, 700, none, 24.2px);
    letter-spacing: 0.83px;
    color: $dark-blue;
  }

  &__form {
    padding: 46px 151px 0px 30px;
    p {
      margin-bottom: 0;
    }
  }
  &__ele {
    display: grid;
    grid-template-columns: 119px auto;
    &:not(:last-child) {
      padding-bottom: 46px;
    }
    &AlignStart {
      align-items: flex-start;
    }
    &AlignCenter {
      align-items: center;
    }
  }
  &__label {
    font-family: $inter;
    @include showText(14px, 500, none, 16.94px);
    letter-spacing: 0.5px;
    color: $Silver;
    position: relative;
  }
  &__require {
    position: relative;
    &::after {
      position: absolute;
      content: '*';
      color: red;
      top: 0;
      margin-left: 2px;
    }
  }
  &__input {
    border: 1px solid $light-gray;
    border-radius: 4px;
    color: $light-gray;
    padding: 12px;
    font-family: $inter;
    @include showText(13px, 400, none, 15.73px);
    letter-spacing: 0.54px;
  }
  &__text {
    font-family: $inter;
    color: $dark-blue;
    @include showText(13px, 400, none, 15.73px);
    letter-spacing: 0.54px;
    align-items: center;
  }
  &__textarea {
    border-radius: 4px;
    border: 1px solid $light-gray;
    font-family: $inter;
    @include showText(13px, 400, none, 16px);
    padding: 14px 12px;
    color: $dark-blue;
  }
  &__borderBtm {
    border-bottom: 1px solid #eaeaea;
  }
  &__wrapBtn {
    @include flex(center, flex-end);
    padding-bottom: 32px;
  }
}
