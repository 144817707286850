@import '../../../../../assets/scss/variables';

.proposalWrap {
  padding: $pdcPrintPadding;
  padding-top: 24px;
  padding-bottom: 0;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;
  display: flex;
  flex-direction: column;
}

.proposalList {
  .proposalItemContent {
    justify-content: space-between;
  }
}

.proposalPositionList {
  .proposalItemContent {
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

.proposalItem {
  margin-bottom: 20px;
}

.proposalItemHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }
}

.proposalItemContent {
  display: flex;
  align-items: stretch;
  gap: 20px;
  background: #fff;
  border: 1px solid var(--Light, #dedede);
  border-radius: 8px;

  .infoLeft {
    display: flex;
  }

  .avatar {
    margin-right: 20px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: solid 1px #dedede;
    }
  }

  .fieldItem {
    padding-left: 24px;
    width: 18%;
  }

  .fieldName {
    color: var(--Light-Primary-500, #00a19c);
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin-bottom: 5px;
  }

  .fieldNameDiff {
    border-radius: 4px;
    border: 1px dashed var(--color-border-disabled, #aabcd0);
    background: var(--color-background-weak, #f7fafd);
    display: flex;
    width: 320px;
    height: 28px;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    span {
      color: var(--Grays-500, #525252);
      text-align: center;
      font-family: $inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .fieldDesc1,
  .fieldDesc2 {
    color: $teal;
    font-family: $inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }

  .fieldDesc2 {
    color: var(--Light-Blue-Grays-800, #262d36);
  }

  .normalText {
    color: var(--Light-Blue-Grays-800, #262d36);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
  }

  .fieldLabel {
    color: var(--Light-Gray-500, #7a7a7a);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
  }

  .fieldValue {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .greyLabel {
    color: var(--Grays-500, #525252);
    font-family: $inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
  }

  .boldValue {
    color: var(--Light-Gray-800, #3d3d3d);
    font-family: $inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 6.5px; /* 54.167% */
  }
}

.contentItem {
  position: relative;
  width: 50%;
  
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:first-child {
    padding: 20px 0 20px 20px;
  }

  &:last-child {
    border-left: solid 1px #cbe6d2;    
    padding: 20px;
  }

  .fieldItem {
    padding-left: 0;
    width: 100%;
  }

  .fieldNameLink {
    text-decoration: none;
    font-family: $inter;
    &:hover {
      text-decoration: none;
    }
  }

  .greyLabel {
    color: var(--Light-Gray-500, #7a7a7a);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .positionName {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .positionCode {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .normalText {
    color: #000;
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .purposeItem {
    border-radius: 4px;
    border: 1px solid var(--color-border-weak, #cbd6e2);
    padding: 12px 16px;
    min-height: 86px;
  }
}

.listAction {
  display: flex;
  align-items: center;
}

.positionField {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #3f3c4c;
  display: block;
}
