@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.menuWrapper {
  @include flex(center, space-between);
  position: relative;
}

.groupBtn {
  @include flex(center, space-between);
}
