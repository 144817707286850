@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.title {
  font-family: $inter;
  font-weight: 600;
  font-size: 18px;
}

.title_success_planning {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #525252;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  height: 44px;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-indent: 16px;
}

.input::-webkit-input-placeholder {
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8d8d8d;
}

.time_input {
  text-indent: 0;
  text-align: center;
}

.text_error {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.5px;
  color: #d56565;
}

.input_error {
  border-color: #d56565;
}
