.wrapper {
  display: flex;
  gap: 20px;
  padding: 15px 20px;
  text-decoration: none !important;
  background-color: white;
  border-radius: 6px;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      font-weight: 400;
      color: #3f3c4c;
      font-size: 16px;
      word-break: break-word;
    }

    .date {
      color: #b1b9c6;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
