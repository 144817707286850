@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.title {
  display: flex;
  gap: 30px;
  align-items: center;

  h1 {
    font-size: 20px;
    letter-spacing: 0.84;
    margin: 0;
    color: #4a4a4a;
  }

  .view_other {
    display: flex;
    gap: 10px;
    color: #181818;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    label {
      margin-bottom: 0;
    }
  }
}

.addBtn{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.text{
  color: #3f3c5c;
}

.noteText{
  color: $black
}