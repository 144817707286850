@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.SectionName {
  font-family: $inter;
  @include showText(24px, 600, none, 29.05px);
  color: #5e6c84;
  display: flex;
  justify-content: space-between;
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  table {
    margin-bottom: 0;
  }
  &Thead {
    border-bottom: 2px solid #ececec;
  }
  &Thead th {
    font-family: $inter;
    @include showText(14px, 500, none, 16.94px);
    color: #344563;
    border: 1px solid #ececec;
  }
  &Body {
    font-family: $inter;
    @include showText(14px, 400, none, 16.94px);
    color: #344563;
    border-bottom: 1px solid #ececec;
  }
  &BodyStatus {
    text-transform: capitalize;
  }
}
.btn {
  width: 100%;
  height: 72px;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}
.TextBtn {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #344563;
}
.btn:hover {
  background: #e9edf0;
}

.talent_search_form {
  max-width: 1283px !important;

  .talent_search_content {
    padding: 0 20px 24px;
    background-color: #ffffff !important;
  }

  .modal_header {
    border: none;
    margin-bottom: 10px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: #3f3c4c;
      font-family: $inter;
    }

    p {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: #3f3c4c;
      font-family: $inter;
      margin-top: 15px;
    }

    button {
      position: absolute;
      top: 30px;
      right: 30px;
      span {
        width: 20px;
        height: 20px;
      }
    }
  }

  .modal_body {
    height: 100%;
  }
}
