@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.deleteButton {
  color: #ff787f;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.positionCode {
  color: $gray;
}

.positionName {
  color: #3f3c4c;
  font-weight: 700;
}

.approvedDate {
  color: $gray;
  font-size: 13px;
}

.flagMachingCriteria {
  background-color: #f4f5f8;
  border-radius: 6px;
  &__mask {
    &:hover {
      cursor: pointer;
    }
  }
  &__tooltip {
    @include showText(12px, 400, none, 20px);
    font-family: $inter;
  }
}