@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.icons {
  transform: translate(-15%, 20%);

  img {
    margin-right: -12px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    pointer-events: all;
  }

  img:active {
    transform: translateY(3%);
  }
}

.inActiveDelBtn {
  background-color: #e7e7f0;
  color: white;
}

.searchInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8D8D8D;
  font-size: 14px;
  padding: 0 16px;
}
