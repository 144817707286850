@import '../../assets/scss/variables';

.editorContainer {
  display: block;
  position: relative;
  width: 100%;

  .controlToolbar {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 10;

    .toolbarBtn {
      border: none;
      box-shadow: none;
      outline: none;
      background: transparent;
    }
  }
}
