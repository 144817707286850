@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.containerModal {
  max-width: 1347px;
}

.existingAgender {
  &__header {
    padding: 71px 63px 32px;
    position: relative;
    border: none;
  }

  &__title {
    font-family: $inter;
    @include showText(20px, 700, none, 24.2px);
    letter-spacing: 0.83px;
    color: #3f3c4c;
  }

  &__body {
    padding-left: 63px;
    padding-right: 75px;
  }

  &__list {
    margin-bottom: 32px;
  }

  &__label {
    font-family: $inter;
    @include showText(14px, 500, none, 16.94px);
    letter-spacing: 0.5px;
    color: $Silver;
    position: relative;
  }

  &__text {
    font-family: $inter;
    color: $dark-blue;
    @include showText(13px, 400, none, 15.73px);
    letter-spacing: 0.54px;
    align-items: center;
  }
}

.input {
  width: 100%;
  height: 44px;
  min-height: 44px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-indent: 16px;
}

.input::-webkit-input-placeholder {
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8d8d8d;
}

.btn_white {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 30px;

  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }
}

.btn_green {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 30px;

  background: #00a19c;
  border-radius: 4px;
  cursor: pointer;
}

.btn_disabled {
  background: #e7e7f0;
  cursor: no-drop;
}

.text_btn {
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
}

.noAgendata_added {
  border: 1px solid #dedede;
  text-align: center;
  padding: 88px 0;

  span {
    display: block;
  }

  &_img {
    max-width: 105px;
  }

  &_title {
    font-family: $inter;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3f3c4c;
    margin-bottom: 5px;
  }

  &_desc {
    color: #9f9eae;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
  }
}
