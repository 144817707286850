@import '../../../assets/scss/variables';

.proposalWrap {
  padding: 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .mainTitle {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 30px;
  }
}

.proposalItem {
  margin-bottom: 20px;
}

.proposalItemHead {
  h3 {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
  }
}

.proposalItemContent {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 24px;
  background: #fff;
  border: 1px solid var(--Light, #dedede);
  border-radius: 8px;
  padding: var(--font-size-112-px, 12px) 24px;

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .infoLeft {
    display: flex;
    align-items: center;
    width: 272px;
  }

  .avatar {
    margin-right: 20px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .position {
    border-left: solid 1px #cbd6e2;
    padding-left: 24px;
    width: 481px;
  }

  .fieldLabel {
    color: var(--Light-Gray-500, #7a7a7a);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
  }

  .fieldValue {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .type {
    color: var(--Light-Primary-600, var(--color-emerald-600, #00807c));
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

.proposalItemDetail {
  transition: all 200ms;
  position: relative;

  &.collapsed {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow:hidden;
  }

  &.expanded {
    visibility: visible;
    height: auto;
    opacity: 1;
  }
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.listAction {
  display: flex;
  align-items: center;
}
