@import '../../../../../../assets/scss/variables';

.wrapper{
  padding-bottom: 10px;

  .titleInput{
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
    color: $black;
    font-family: $inter;
  }

  .linkInput{
    color: $black;
    font-family: $inter;
  }

  .buttons, .buttonsBottom{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .buttons{    
    margin: 10px 0 30px 0;
  }

  .buttonsBottom{
    margin-top: 30px;
  }

  .videos{
    max-height: 418px;
    overflow-y: auto;
    padding-right: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}