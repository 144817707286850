@import '../../../../assets/scss/variables';

.list {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 30px 20px;
  width: 100%;
  border: 1px solid #d3dee8;
  border-radius: 6px;
  font-family: $inter;

  .titleDetail {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #3f3c4c;
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #525252;
  }

  .input {
    width: 100%;
    border-radius: 4px;
    padding: 10px 16px;
    font-family: $inter;
    border: 1px solid #d9d9d9;
    font-size: 14px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #8d8d8d;
      // opacity: 1; /* Firefox */
    }

    &:hover,
    &:focus {
      border-color: #00a19c;
      box-shadow: 0 0 0 2px rgba(0, 161, 156, 0.2);
    }
  }

  .hideCloseBtn {
    svg {
      display: none !important;
    }
  }
}
