@import '../../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  font-family: $inter;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 30px;
  .step {
    width: 20%;
    padding: 0 8px;

    .topBar {
      border-top: 4px solid #8d8d8d;
    }

    .info {
      display: flex;
      justify-content: space-between;
      .name {
        padding: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
}
