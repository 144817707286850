@import '../../../../assets/scss/variables';

.title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 0;
}

.wrapper {
  color: $white;
  background-color: $white;
  border: 1px solid $geyser;
  border-radius: 6px;
  padding: 28px;
  margin: 32px 0;

  .title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
    margin-bottom: 76px;
  }

  .borderDashed {
    position: relative;
    border-left: none;
    border-radius: 6px;
  }

  .borderDashedBefore {
    position: absolute;
    top: 24px;
    right: 0;
    border: 8px dashed #d3dee8;
    border-left: none;
    width: 85%;

    &::after {
      border: 4px dashed #d3dee8;
      content: '';
      position: absolute;
      height: 300px;
      bottom: -311px;
    }
  }

  .journey {
    width: calc(100% - 115px);
    display: flex;

    .journeyItem {
      position: relative;
      width: 100%;
      text-align: center;
      border: 1px solid $medium-grey;
      border-top: 2px solid #3f3c4c;
      border-left: none;
      background-color: $white;
      cursor: pointer;
      min-width: 70px;

      &:first-child {
        border-left: 1px solid $medium-grey;
      }

      .mask {
        position: absolute;
        top: -20px;
        right: -15px;
      }

      .businessTitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 53px;
        vertical-align: middle;
        color: $teal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .roleTitle {
        position: absolute;
        color: #c4c3d1;
        font-size: 14px;
        font-weight: 700;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.active {
        background-color: #566879;
        border-bottom: 4px solid #6e3990;
        box-shadow: 0px 3px 2px #0000004f;

        .businessTitle {
          color: white;
        }

        .roleTitle {
          color: #3f3c4c;
        }
      }
    }

    .journeyNull {
      position: relative;
      bottom: -22px;
      width: 100%;

      .mask {
        position: absolute;
        top: -23px;
        left: 5px;
      }
    }
  }

  .detailContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 34px;

    .detail {
      width: fit-content;
      border: 1px solid $bright-gray;
      border-radius: 6px;
      position: relative;

      .borderTop {
        border-top: 8px solid #566879;
        position: absolute;
        top: -1px;
        left: -1px;
        border-radius: 6px 6px 0 0;
        width: calc(100% + 2px);
      }

      .content {
        padding: 22px;

        h5 {
          color: $recommnendationText;
          font-weight: 600;
          font-size: 12px;
        }

        ul {
          color: $Gray32;
          font-weight: 500;
          font-size: 12px;
          list-style-image: url(../../../../assets/img/list_style_square.svg);
          padding-left: 15px;
        }
      }
    }
  }
  .formEdit {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    background-color: #feffff;
    position: relative;
  }
}

.wrapperPrint {
  padding-bottom: 28px !important;

  .formPrint {
    position: initial !important;
    bottom: initial !important;
    width: 100% !important;
  }
}

.textareaView {
  margin: 13px 16px 16px 16px;
  color: #8d8d8d;
  font-size: 13px;
  padding: 12px 16px;
}

.mobilityPlanButton {
  justify-content: space-between;
  display: flex;
  margin: 0 17px 17px;
}
