@import '../../../../../assets/scss/variables';

.tooltipContent {
  padding: 5px;

  span {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0168em;
    color: #FFFFFF;
  }
}
