@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.container {
  margin: 20px 0;
}
.title {
  font-family: $inter;
  font-size: 18px;
  font-weight: 700;
  color: #3f3c4c;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 700px 300px;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-areas:
    'line_1st line_2nd line_3rd recommendation'
    'agreed_to_drop agreed_to_drop agreed_to_drop recommendation';
  overflow: hidden;
}

.view_btn {
  color: #00a19c;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  padding: 5px 10px;
  border: 1px solid #00a19c;
  border-radius: 6px;

  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
}

.recommendation {
  height: 100%;
}
