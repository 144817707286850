@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.wrapper {
  padding-top: 60px;

  .title {
    text-align: center;
    color: #3f3c4c;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.83px;
    margin-bottom: 20px;
  }
}

.individual {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: $textColor;
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;

  .avatar {
    padding-right: 25px;
    width: 36px;
    height: 36px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-top: 8px;
    }
  }

  .info {
    color: $silver;
    font-weight: 400;

    .name {
      font-weight: 600;
      color: $black;
    }

    p {
      margin-bottom: 8px;
      line-height: 12px;
      font-size: 12px;
    }
  }
}
