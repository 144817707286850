@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.dropdown {
  position: relative;

  &Select {
    cursor: pointer;
    @include flex(center, space-between);
    max-width: 216px;
    &__label {
      font-family: $inter;
      @include showText(14px, 400, none, 16px);
      color: $dark-blue;
      margin-right: 2px;
    }
  }
  &Content {
    position: absolute;
    top: calc(100%);
    width: 90%;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    &__item {
      padding: 10px 17px 10px 16px;
      cursor: pointer;
      &:hover {
        background-color: #f4f5f8;
      }
    }
    &__itemLabel {
      font-family: $rubik;
      @include showText(14px, 500, none, 22px);
      color: $dark-blue;
    }
    &__visible {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }
  &__loading {
    width: 100%;
    text-align: center;
    padding: 5px 0;
  }
}
