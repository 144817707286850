@import '../../assets/scss/variables';

#root {
  background-color: #e5e5e5;
}

.wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: $rubik;
  font-weight: 400;
  color: $primary;
}

.ant-notification {
  z-index: 1055;
}

.antd-modal-confirm-custom {
  .ant-modal-confirm-body {
    color: '#3F3C4C';
    font-size: 14px;
  }

  .ant-modal-confirm-btns {
    button {
      font-size: 12px;
      border-radius: 6px;

      &:first-child {
        background-color: white;
        border-color: #00a19c;
        color: #00a19c;
      }

      &:last-child {
        background-color: #00a19c;
        border-color: #00a19c;
        color: white;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background: #00a19c;
        border-color: #00a19c;
      }
    }
  }
}

.ant-picker-focused {
  border-color: $teal;
}

.ant-picker {
  &.ant-picker-disabled {
    border-radius: 4px;
    padding: 12px 16px;
    color: #787587;
    background: #e0e0e0;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }

  .ant-picker-input > input[disabled] {
    color: #787587;
  }
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-left: 175px;
}

.ant-pagination-total-text {
  position: absolute;
  left: 0;
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.ant-pagination-prev,
.ant-pagination-next {
  width: 14px;
  min-width: 14px;

  svg {
    fill: #00645c;
  }

  button.ant-pagination-item-link {
    border: none;
    border-color: transparent;
    background-color: transparent;
  }
}

.ant-pagination-disabled {
  svg {
    fill: #bac7d5;
  }
}

.ant-pagination-item-active {
  background: #00a19c;
  border-radius: 4px;
  color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00a19c !important;
  font-weight: 500;
}

.ant-tabs-tab {
  &:hover {
    color: black;
  }
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #00a19c;
  color: #00a19c;
}

.center-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.max-width {
  width: 1287px;
  margin: auto;
  font-family: $inter;
}

.title-global {
  // follow figma: https://www.figma.com/file/WaWDi0vtkTVAOvt22cgjt4/Talent-Petronas?node-id=84099%3A123587

  h3 {
    font-weight: 700;
    color: #3f3c4c;
    font-size: 24px;
    padding: 0;
    margin: 0;
  }

  margin-top: 38px;
  margin-bottom: 48px;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none !important;
}

.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 46px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-color: #d3dee8;
  border-radius: 4px;
  padding: 0 16px;

  .ant-select-selection-placeholder {
    line-height: 44px;
    color: #8d8d8d;
    font-size: 14px;
    font-weight: 400;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 44px;
}

.ant-form-item-control-input {
  height: 44px;
}

.ant-form-item-control-input-content {
  height: 44px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 44px;
}

.ant-select-selection-search {
  height: 44px;
}

.ant-select-selector {
  height: 44px;
}

.ant-select {
  height: 44px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 42px;
  padding: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 44px;
  transition: all 0.3s;
}

.ant-input-status-error {
  border-color: red !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &:hover {
    border-color: red;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-color: #d3dee8;
  border-radius: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-color: #d3dee8;
  border-radius: 4px;
}

.ant-select-item-option {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #d3dee8;
}

.ant-select-dropdown {
  padding: 0;
}

.ant-input:focus {
  outline: none !important;
  border: 1px solid #00a19c;
  box-shadow: 0 0 5px #00a19c;
}

.ant-input:hover {
  outline: none !important;
  border: 1px solid #00a19c;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white;
  font-weight: inherit;
  color: inherit;
}

.customInputDateRangepicker {
  &.hasValue {
    .ant-picker-input {
      &:first-child {
        padding-left: 20px;
      }
    }

    .ant-picker-range-separator {
      &::before {
        display: inline-block;
      }
    }
  }

  .ant-picker-range-separator {
    position: relative;
    line-height: 0;
    border: none;

    .anticon,
    .ant-picker-separator {
      display: none !important;
    }

    &::before {
      display: none;
      content: '-';
      background-color: #000000;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
}

.customDateRangepicker {
  .ant-picker-content {
    tr {
      td {
        &:first-child {
          &::before {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
        }

        &:last-child {
          &::before {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }

    .ant-picker-cell-range-start,
    .ant-picker-cell-range-end {
      &::before {
        background: rgba(52, 69, 99, 0.2) !important;
      }

      .ant-picker-cell-inner {
        background: #00a19c !important;
        border-radius: 50% !important;
        color: #ffffff;
      }
    }

    .ant-picker-cell-range-start {
      &.ant-picker-cell-range-start-single {
        &::before {
          background: transparent !important;
        }
      }
    }

    .ant-picker-cell-in-range {
      color: #3f3c4c;

      &::before {
        background: rgba(52, 69, 99, 0.2) !important;
      }
    }
  }

  .cancelBtn,
  .selectBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    width: 73px;
    height: 30px;
    border: 1px solid #00a19c;
    border-radius: 4px;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }

  .groupBtnBottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cancelBtn {
    background: #ffffff;
    color: #00a19c;
    margin-right: 14px;
  }

  .selectBtn {
    background: #00a19c;
    color: #ffffff;
  }
}

.custom-seclect-option {
  span.option {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.ant-select-item-option-content {
  div {
    border-bottom: none !important;
  }
}

.customMappingGroupTable {
  &.editMode {
    .ant-table-thead tr th:first-child,
    .ant-table-tbody tr td:first-child {
      padding-left: 47px;
    }

    tr.ant-table-row {
      td:nth-child(1) {
        span.text_field {
          visibility: hidden;
        }
      }

      &.only_one_item,
      &.first_item {
        td.ant-table-cell {
          &:nth-child(1) {
            span.text_field {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  tr.ant-table-row {
    &.only_one_item,
    &.first_item {
      td.ant-table-cell {
        &:nth-child(2) {
          span.text_field {
            visibility: visible;
          }
        }
      }
    }

    &.only_one_item,
    &.last_item {
      td {
        border-bottom: 1px solid #d3dee8 !important;
      }
    }

    td {
      &.ant-table-cell {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: none;

        &:nth-child(2) {
          span.text_field {
            visibility: hidden;
          }
        }
      }

      &.custom-text-field {
        font-family: $inter;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.3px;
        color: #999a9c;
      }

      &.bold-text {
        width: '306px';
        font-weight: 700;
        color: '#3F3C4C';
      }
    }
  }
}

.custom-react-select {
  &.active {
    .custom-react-select__control {
      padding-left: 20px;
    }

    .custom-react-select__placeholder {
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }

  .custom-react-select-menu-list {
    max-height: 350px;
    overflow-y: auto;

    &.has-apply-btn {
      padding-bottom: 50px;
    }

    .ant-row {
      flex-wrap: nowrap !important;

      .ant-col {
        &:first-child {
          width: 15px !important;
        }
      }
    }
  }

  .custom-react-select-apply-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    z-index: 10;
  }

  .ant-row {
    gap: 10px;
  }
}

.customLoading {
  > div > .ant-spin {
    max-height: initial !important;
  }
}

.bs-custom-tooltip {
  .tooltip-inner {
    max-width: 300px;
    text-align: left;
    padding: 10px;
  }
}

.ant-tabs-tab-active {
  padding: 14px;
}

.customHistoricalTable {
  .ant-table-thead {
    & > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .headLevel1,
    .headLevel2,
    .headLevel3 {
      font-family: $inter;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      padding: 16px 12px;
      gap: 10px;
      letter-spacing: 0.0168em;
    }

    .headLevel1 {
      font-weight: 700;
      color: #4f5f6e;
      background: #c9d5e3;
      border-width: 0px 2px 1px 0px;
      border-style: solid;
      border-color: #f9fafb;
    }
    .headLevel2 {
      font-weight: 700;
      color: #4f5f6e;
      background: #e7edf3;
      border-right: 2px solid #f9fafb;
    }
    .headLevel3 {
      font-weight: 500;
      color: #4f5f6e;
      background: #f9fafb;

      &:nth-child(2n) {
        border-width: 0px 2px 1px 0px;
        border-style: solid;
        border-color: #e7edf3;
      }
    }
  }

  .ant-table-tbody {
    .headLevel1 {
      padding: 16px 10px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #e7edf3;
    }
    .headLevel2 {
      &.hasBorder {
        border-width: 0px 2px 1px 0px !important;
        border-style: solid;
        border-color: #e7edf3;
        font-weight: 400;
        color: #7a7a7a;
        background: #ffffff;
      }
    }
    .headLevel3 {
      font-weight: 400;
      color: #7a7a7a;
      background: #ffffff;

      &:nth-child(2n) {
        border-width: 0px 2px 1px 0px;
        border-style: solid;
        border-color: #e7edf3;
      }
    }
  }
  .ant-table-sticky-scroll {
    display: none;
  }

  &.isFiveColumns {
    .headLevel3 {
      &:nth-child(2n) {
        border-right: none;
        border-bottom: 1px solid #e7edf3;
      }

      &.hasBorder {
        border-width: 0px 2px 1px 0px !important;
        border-style: solid;
        border-color: #e7edf3;
      }
    }
  }
}

.customSuccessionPlanning {
  .ant-table-thead {
    & > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .headLevel1,
    .headLevel2,
    .headLevel3 {
      font-family: $inter;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      padding: 16px 12px;
      gap: 10px;
      letter-spacing: 0.0168em;
    }

    .headLevel1 {
      font-weight: 700;
      color: #4f5f6e;
      background: #c9d5e3;
      border-width: 0px 2px 1px 0px;
      border-style: solid;
      border-color: #f9fafb;
    }
    .headLevel2 {
      font-weight: 700;
      color: #4f5f6e;
      background: #e7edf3;
      border-right: 2px solid #f9fafb;
    }
    .headLevel3 {
      font-weight: 500;
      color: #4f5f6e;
      background: #f9fafb;

      &:nth-child(3n) {
        border-width: 0px 2px 1px 0px;
        border-style: solid;
        border-color: #e7edf3;
      }
    }
  }

  .ant-table-tbody {
    .headLevel1 {
      padding: 16px 10px;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #e7edf3;
      color: #7a7a7a;
    }
    .headLevel3 {
      font-weight: 400;
      color: #7a7a7a;
      background: #ffffff;

      &:nth-child(3n + 2) {
        border-width: 0px 2px 1px 0px;
        border-style: solid;
        border-color: #e7edf3;
      }
    }
  }
  .ant-table-sticky-scroll {
    display: none;
  }
}

.tooltip-inner {
  max-width: none !important;
}

.dropdown_disabledDuplicateBtn {
  .ant-dropdown-menu-item {
    &:nth-child(2) {
      cursor: no-drop;
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.ant-select.isInvalid {
  border: 1px solid #da2228;
  border-radius: 4px;
  box-shadow: none;
}

.ant-select.isInvalid .ant-select-selector {
  border: none;
  box-shadow: none;
}

.ant-select-item-option-content {
  .recommendation-table_custom_label:hover {
    background-color: #e0f3f3;
  }

  .recommendation-table_custom_label:after {
    padding: 10px 16px;
  }
}

.ant-select-selection-item {
  .recommendation-table_custom_label {
    padding: 5px;
  }
}

.recommendation-table_custom_label {
  padding: 10px 16px;
}
.recommendation-table-options {
  padding: 5px 5px;
  border: none;
  background-color: white;
}

.recommendation-table_dropdown {
  width: 100%;
  font-family: $inter;
  font-size: 14px;
  height: 55px !important;
  margin-top: 8px;
  margin-bottom: 8px;

  .ant-select-selector {
    height: 55px !important;
  }
}

// custom style for free text
table {
  tbody {
    tr {
      td {
        white-space: pre-wrap;
      }
    }
  }
}

.customSelectStatus {
  .ant-select-selection-item {
    background: white !important;
    color: white !important;
    position: relative;

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 6px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &[title='#0B882E'] {
      &::before {
        background: #0b882e;
      }
    }
    &[title='#FDB924'] {
      &::before {
        background: #fdb924;
      }
    }
    &[title='#DA2228'] {
      &::before {
        background: #da2228;
      }
    }
    &[title='#7A7A7A'] {
      &::before {
        background: #7a7a7a;
      }
    }
  }
}

.customReportTable {
  .isRequiredField {
    &.customInput,
    .ant-select-selector {
      border: solid 1px #da2228 !important;
    }
  }
}

.customSelectOption {
  &:hover {
    background: #e0f3f3;
  }
}

.customFinderSelect {
  .ant-select-selector {
    background: white;
  }

  .ant-select-item-option,
  .ant-select-selection-search-input {
    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #181818;
  }
  .ant-select-selection-placeholder {
    color: #8d8d8d;
  }

  .ant-select-arrow {
    & > span {
      position: relative;

      &::after {
        content: '\25BC';
        vertical-align: middle;
        color: #d9d9d9;
        line-height: 0;
        margin-right: 2px;
        font-size: 10px;
        position: absolute;
        right: -5px;
      }
    }

    svg {
      display: none !important;
    }
  }
}

.customFinderOption {
  border: none !important;
}

.manualSelectOption {
  .ant-select-item-option-content {
    padding-left: 30px;

    &::before {
      content: '';
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 17px;
      height: 17px;
      border: solid 1px #c9d5e3;
      border-radius: 4px;
    }
  }

  .ant-select-item-option-state {
    position: absolute;
    left: 10px;
    top: 45%;
    transform: translateY(-50%);

    .anticon-check {
      border: solid 1px #00a19c;
      border-radius: 4px;
      padding: 1px;

      svg {
        fill: #00a19c;
      }
    }
  }

  &.ant-select-item {
    border: none;

    &:hover {
      background-color: #e0f3f3;
    }
  }

  &.ant-select-item-option-selected {
    > .ant-select-item-option-content {
      &::before {
        display: none;
      }
    }
  }
}

.customDateColumn {
  padding: 5px !important;
  > div {
    width: 98px !important;
  }
}

@media print {
  table {
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  button.close {
    display: none !important;
  }

  .mainAvatar {
    max-width: 90px !important;
    max-height: 90px !important;
    border-radius: 50% !important;
  }

  .mainTitleHeader {
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #525252;
    margin-bottom: 38px;
  }

  .itemInfoPosition {
    width: 960px;
  }

  .fontMinimalText {
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #525252;
    padding-bottom: 12px;
  }

  .girdColumnWrapper,
  .girdContainer {
    max-height: unset !important;
  }
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset;
}

.customTooltipPosition {
  div:global(.ant-tooltip-inner) {
    padding: 15px 27px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    color: #f3f3f3;
  }
}

.slick-next::before,
.slick-prev::before {
  content: none;
}

.modal_add_experience {
  .modal-header {
    position: relative;
  }
  button.close {
    position: absolute;
    top: 26px;
    right: 36px;
  }
}
