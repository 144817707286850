.button-login {
  display: inline-block;
  overflow-x: hidden;
  max-width: 100%;
  border: 0;
  border-radius: 99em;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  line-height: 2.44em;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: background-color 0.15s, border-color 0.15s, color 0.15s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  &:focus {
    outline: none;
  }
}

.button-login[disabled],
.button-login.disabled {
  pointer-events: none;
}

.button--primary {
  background-color: $primary;
  padding: 0 1.22em;
  height: 2.44em;
  color: $white;
  &:hover,
  &:focus {
    background-color: #ecf8f6;
    color: $primary;
  }
  .hover,
  .focus {
    background-color: #ecf8f6;
    color: $primary;
  }

  &:active {
    background-color: $dark-aqua;
  }
  .active {
    background-color: $dark-aqua;
  }
}

.button--primary[disabled],
.button--primary.disabled {
  background-color: #ecedef;
}

.button--small {
  font-size: 12px;
}

@media (min-width: 480px) {
  .button-login {
    min-width: 10em;
  }
}

.button--secondary {
  background-color: $white;
  color: $primary;
  padding: 0.3em 1.5em;
  line-height: 1.64em;
  border: 1px solid $primary;
  font-size: 9px;
  &:active {
    background-color: $primary;
    color: $white;
  }
}

.active--secondary {
  background-color: $primary;
  color: $white;
}

.buttonTab {
  background-color: rgba(0, 161, 156, 0.1);
  color: $primary;
  padding: 0.3em 1.5em;
  line-height: 1.64em;
  font-size: 11px;
}
