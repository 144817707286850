@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.view_talent_comparison {
  max-width: 1500px !important;
  max-height: none !important;
  font-family: $inter;

  .modal_header {
    border: none;
  }

  .modal-dialog-scrollable .modal-content{
    max-height: none !important;
    overflow: unset !important;
  }

  .modal_body {
    height: 100%;
    padding: 15px 6px 15px 15px;
  }

  .title_search {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    display: flex;
    align-items: center;

    color: #525252;
  }

  .content_search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn_add_talents {
    background: #00a19c;
    border-radius: 4px;
    height: 44px;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .text_warning {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #e56e18;
  }

  .border{
    padding: 0px 5px;
  }
  
  .content_profile_talent {
    background: #ffffff;

    border: 1px solid #c4c3d1;
    box-sizing: border-box;
    border-radius: 6px;

    padding: 24px 24px 30px 31px;
    max-height: 600px;
    overflow-y: auto;
    &__2__ {
      height: 662px;
      background: #ffffff;
      border: 1px solid #c4c3d1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 24px 24px 30px 31px;
      overflow-y: auto;
      @include flex(center, center);
    }
  }
  .content_profile_talent_loading {
    background: #ffffff;
    border: 1px solid #c4c3d1;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 24px 24px 30px 31px;
    max-height: 600px;
    overflow-y: auto;
    @include flex(center, center);
    &__2__ {
      height: 662px;
      background: #ffffff;
      border: 1px solid #c4c3d1;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 24px 24px 30px 31px;
      overflow-y: auto;
      @include flex(center, center);
    }
  }

  .deleteIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .deleteIcon__container {
    float: right;
  }
  .section_text_profile_talent {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .btn_open_profile {
    background: #00a19c;
    border-radius: 4px;
    height: 30px;
    width: 143px;
    font-weight: 600;
    font-size: 12px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .text_details_left {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #7d8c9f;
  }

  .text_details_right {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;

    color: #344563;
  }

  .text_profile_name {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_profile_unit {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_active {
    background: rgba(0, 161, 156, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.3px;

    color: #00a19c;
  }

  .text_no_active {
    background: rgba(52, 69, 99, 0.05);
    border-radius: 16px;

    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: 0.3px;

    color: #344563;
  }

  .text_no_data {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #344563;
  }
}
.view_talent_comparison > div{
  max-height: none !important;
  overflow: unset !important;
}
