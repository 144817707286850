@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.checkedFilter:checked {
    width: 16px;
    height: 16px;
    transform: translateY(2px);
    outline: none;
    accent-color: #00a19c;
    border-radius: 50%;

}

.checkedFilter:not(:checked) {
    width: 12px;
    height: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: 2px solid #cecccc;
    border-radius: 3px;
    transform: translateX(2px);
    -moz-outline-radius: 5px;
}

.talentImagePDC {
    width: 48px;
    height: 48px;
    border-radius: 32px;
    padding: 4px;
}

.headerTitlePDC {
    font-family: $inter;
    font-weight: 500;
    font-size: 16px;
}

.categoryPDC {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    color: #394655;
    display: flex;
    align-self: center;
}

.remarkPDC {
    font-family: $inter;
    font-weight: 400;
    font-size: 14px;
    color: #394655;
}
