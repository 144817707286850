@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";

$colorChoose: #344563;
$white: #ffffff;
.dropdown {
  position: relative;
  font-family: $inter;
  font-weight: 500;
  color: $colorChoose;
  background: #fbfdff;
  @include flex(center, space-between);
  border-radius: 10px;
  &Select__label {
    @include showText(14px, 500, none, 22px);
    font-family: $rubik;
    color: $dark-blue;
    margin-bottom: 0;
  }
  &Btn {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border: 1px solid #e9edf0;
    padding: 12px 10px;
    background: #fbfdff;
    font-size: 14px;
    @include flex(center, space-around);
    cursor: pointer;
    border-radius: 6px;
    width: 100%;
    max-height: 36px;
    span {
      padding-left: 5px;
      padding-bottom: 3px;
      img {
        filter: invert(37%) sepia(57%) saturate(2560%) hue-rotate(153deg) brightness(96%) contrast(101%);
      }
    }
  }
  
  &Content {
    position: absolute;
    background: $white;
    font-weight: 500;
    top: 0;
    right: 0;
    width: 100%;
    box-shadow: 0px 8px 16px 0px #0000001a;
    border-radius: 2px;
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    &__active {
      visibility: visible;
      opacity: 1;
    }
  }
  &Item {
    font-family: $inter;
    font-weight: 500;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s;
    &__label {
      @include showText(14px, 500, none, 20px);
      font-family: $rubik;
      color: $dark-blue;
      margin-bottom: 0;
    }
    &:hover {
      background-color: $light-pink;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
}
