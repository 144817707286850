@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.remark {
  max-width: 946px !important;
  font-family: $inter;
  .modal_header {
    padding: 30px 30px 0;
    border: none;

    .title {
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.3px;
      color: #3d3d3d;
    }
  }

  .table {
    margin: 30px 30px;
    padding: 0;
    border: 1px solid $tableColor;
    border-radius: 6px;

    &_header {
      @include flex(flex-start, flex-start);
      padding: 15px 20px;
      border-bottom: 1px solid #ececec;
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.0192em;
      color: #3f3c4c;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .modal_body {
      height: 400px;
      padding: 0;
    }

    &_body {
      @include flex(flex-start, flex-start);
      padding: 15px 20px;
      font-family: $inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #3f3c4c;
    }
  }
}

.col_1 {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ellipsis {
  width: 12px;
  height: 18px;
  cursor: pointer;
}

.dropdownContent {
  padding: 0.5rem;
  position: absolute;
  background: $white;
  font-weight: 400;
  top: -5px;
  right: 50px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  font-size: 14px;
  z-index: 10;
  height: 87px;

  .function {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    &:hover {
      border-radius: 4px;
      background: #f2f2f2;
    }
    &:last-child {
      &:hover {
        background: #fbe2e2;
      }
    }
  }
  .content_edit {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0168em;
    color: #181818;
  }
  .editIcon {
    width: 11px;
    height: 12px;
  }
  .content_delete {
    font-family: $inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0168em;
    color: #da2228;
  }
  .deleteIcon {
    width: 10px;
    height: 12px;
  }
}

.lastItem {
  top: -5px;
  right: 50px;
}

.edit_remark {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.input_remark {
  background: #ffffff;
  border: 1px solid #d3dee8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 103px;
  width: 100%;
  resize: none;
  padding: 10px;
  margin-bottom: 11px;
}

.btn_save {
  background: $teal;
  border-radius: 6px;
  height: 36px;
  width: 80px;
  border: 1px solid #00a19c;
}

.btn_cancel {
  background: #fff;
  border-radius: 6px;
  height: 36px;
  width: 80px;
  border: 1px solid #00a19c;
  margin-right: 10px;
}

.text_btn_save {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
}

.text_btn_cancel {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #00a19c;
}

.normalText {
  position: relative;
  padding-left: 10px;
  padding-right: 30px;
  display: inline-block;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #3d3d3d;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 0;
  }
}
