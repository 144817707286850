@import '../../../../assets/scss/variables';

$cell-width: 40px;
$cell-height: 48px;

.wrapper {
  font-family: $inter;
}

.title {
  color: #394655;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.content {
  display: flex;
  gap: 16px;

  .edge {
    background-color: $white;
    display: flex;
    flex-direction: column;
    font-family: $inter;
    padding: 20px;
    flex-grow: 1;

    .desc {
      display: flex;
      gap: 24px;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;
      font-family: $inter;
      font-size: 12px;
      color: #3d3d3d;
      font-weight: 700;
      letter-spacing: 0.0144em;

      .name {
        display: flex;
        gap: 8px;
        align-items: center;
        line-height: 100%;

        .effective {
          display: block;
          width: 26px;
          height: 26px;
        }
      }
    }

    .dot {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      z-index: 1;
    }

    .ladder_wrapper {
      display: flex;
      flex-direction: column;

      .ladder {
        display: flex;
        justify-content: flex-end;

        .ladder_info {
          position: absolute;
          color: #3d3d3d;
          font-size: 12px;
          font-weight: 700;
          left: 0;
          top: -35px;
          white-space: nowrap;
          z-index: 1;
        }

        span {
          position: relative;
          display: block;
          width: $cell-width;
          height: $cell-height;
          background-color: #f0f5fa;
          color: #4f5f6e;
          font-weight: 700;
          font-size: 12px;
          line-height: $cell-height;
          letter-spacing: 0.173px;
          text-align: center;
          border: 1px solid #cbd6e2;
          margin-left: -1px;
          margin-top: -1px;
          overflow: visible;

          &.hidden {
            background-color: transparent;
            border: none;
          }

          &.upper {
            background: #e7edf2;
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      background-color: #f2f4f8;

      .row {
        display: flex;
        align-items: center;

        .parent {
          width: 99px;
          padding-left: 9px;
          line-height: 96px;
          text-align: left;
          vertical-align: middle;
          color: $white;
          font-size: 14px;
          font-weight: 700;
          outline: 1px solid $white;
        }

        .child {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 96px;
          background-color: $white;

          &:first-child {
            border-top: 1px solid #e5edf5;
          }

          .child_wrapper {
            display: flex;
            justify-content: left;

            .child_name {
              padding-left: 12px;
              display: flex;
              gap: 18px;
              align-items: center;
              width: 100%;
              border: 1px solid #e7edf3;

              .child_no {
                flex-shrink: 0;
                width: 28px;
                height: 28px;
                line-height: 28px;
                border-radius: 8px;
                color: $black;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 0.173px;
                text-align: center;
                vertical-align: middle;
              }

              .child_title {
                line-height: 20px;
                vertical-align: middle;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.0168em;
                color: #3d3d3d;
              }
            }

            .value_wrapper {
              background-color: $white;
              display: flex;

              .value_cell {
                position: relative;
                display: flex;
                gap: 6px;
                justify-content: center;
                align-items: center;
                width: $cell-width;
                height: $cell-height;
                border-left: 1px solid #e7edf3;
                border-right: 1px solid #e7edf3;
                border-top: 0.5px solid #e7edf3;
                border-bottom: 0.5px solid #e7edf3;
                margin-left: -1px;

                .dot {
                  width: 10px;
                  height: 10px;
                }

                .effectiveProficiency {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                }
              }
            }
          }
        }
      }
    }
  }

  .remarks {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 16px;
    background-color: $white;
    border-radius: 6px;
    width: 300px;

    .remarkTitle {
      padding-bottom: 10px;
      color: #3f3c4c;
      font-size: 16px;
      font-weight: 600;
    }

    .remarkItemName {
      color: var(--Light-Gray-500, #7a7a7a) !important;
      font-family: var(--font-type-default, $inter);
      font-size: 12px !important;
      font-style: normal;
      font-weight: var(--font-weight-medium, 500);
      line-height: var(--font-line-height-body-sm, 20px); /* 166.667% */
    }

    .inputPlace {
      div {
        margin-bottom: 8px;
        font-size: 12px !important;
      }

      textarea {
        color: #15191e;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
