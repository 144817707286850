@import '../../../../assets/scss/mixin';
@import '../../../../assets/scss/variables';

.assessmentWrapper {
  background-color: #f5f5f5;
  padding: 20px;
  position: relative;
  min-height: 138px;
}

.assessmentHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
}

.assessmentFilterList {
  display: flex;
}

.mainTitle {
  font-family: $inter;
  color: var(--Emphasis-High-N600, #787587);
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
}