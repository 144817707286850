@import '../../../../../../assets/scss/variables';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.textField {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #999a9c;
}

.tooltipContent {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0144em;
  color: #ffffff;
  padding: 10px;

  span {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
}

.normalContent {
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.235px;
  color: var(--light-gray-500, #7a7a7a);
}

.expandWrapper {
  position: relative;
}

.expandContent {
  padding-top: 5px;

  span {
    color: var(--light-gray-700, #575757);
    font-family: $inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.5px;
    display: block;
  }
}

.wapperMatching {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconExpand {
  width: 15px;
  cursor: pointer;
}

.expandLabel {
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding-right: 30px;
  color: #3F3C4C;
}

.expandBtn {
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
}