@import '../../../../../assets/scss/variables';

.editButton {
  color: #3f3c4c;
  margin-left: 10px;
  font-size: 12px;
}

.deleteButton {
  color: #ff787f;
  margin-left: 10px;
  font-size: 12px;
}

.noField {
  color: $gray;
  font-weight: 700;
}

.textField {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #999a9c;
}

.inputFieldWrapper {
  position: relative;
}

.customTooltip {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.tooltipContent {
  display: flex;
  align-items: center;
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;

  span {
    display: inline-block;
    padding-left: 10px;
  }
}
