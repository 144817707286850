.wrapper {
  font-family: 'Inter', sans-serif;
}

.positionCodeTitle {
  font-weight: 500;
  font-size: 12px;
  color: #525252;
  font-family: 'Inter', sans-serif;
}

.tableTitle {
  font-weight: 600;
  font-size: 12px;
  color: #525252;
  font-family: 'Inter', sans-serif;
}

.datafield {
  font-family: 'inter', 'san-serif';
  font-weight: 600;
  font-size: 12px;
  color: #666666;
}

.datafield500 {
  font-family: 'inter', 'san-serif';
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.header {
  padding: 10px;
}

.chevronWrapper {
  width: 5%;
}

.chevron {
  transition: transform 333ms ease-out;
  transform: rotate(0deg);
}

.animate {
  transform: rotate(-180deg);
}
