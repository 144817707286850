@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';

.basicSearch {
  position: relative;

  .advancedIcon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
  }

  .option {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .avatar {
      margin: auto;
      img {
        display: inline-block;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        border: 1px solid #f0f3f7;
      }
    }

    .external_talent {
      width: 90%;
    }
  }
}

.advanced {
  overflow-y: hidden;
  margin-top: 4px;

  .search {
    padding: 20px 16px;
    border: 1px solid #ebebeb;
    border-radius: 4px;

    h5 {
      color: #3f3c4c;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.3px;
    }

    .inputRow {
      display: flex;
      gap: 24px;
    }

    .optionsRow {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 24px;
    }

    .buttonRows {
      display: flex;
      gap: 10px;
    }
  }

  .result {
    margin-top: 24px;

    h5 {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.307px;
    }

    .no {
      color: #181818;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
      display: flex;
      gap: 32px;
      justify-content: space-between;
      align-items: center;

      .talentStatus {
        position: relative;
        img {
          &:first-child {
            display: inline-block;
            border: 2px solid #f0f3f7;
            border-radius: 50%;
            width: 42px;
            height: 42px;
          }
          &:last-child {
            position: absolute;
            width: 13px;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    .name {
      color: #3d3d3d;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.235px;
    }

    .staffId {
      color: #999a9c;
      font-size: 13px;
      letter-spacing: 0.3px;
    }

    .textField {
      color: #181818;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }
}

.tooltipContainer {
  :global {
    .ant-tooltip-arrow-content {
      background: $black;
    }

    .ant-tooltip-content {
      background: $black;
      border-radius: 4px;
    }

    .ant-tooltip-inner {
      font-family: $inter;
      padding: 12px;
      color: $white;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
  }
}
