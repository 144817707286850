.customPagingButton {
  &::before {
    opacity: 0 !important;
    color: rgba(255, 255, 255, 0) !important;
  }
}

.prevNextButton {
  display: block;
  background-color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #181818;
  font-size: 16px;
  text-align: center;
  border: 1px solid #c9d5e3;
  box-shadow: 0px 1px 0px 0px #0000001a;
}
