@import '../../assets/scss/variables';

$white: #fff;
$gray: #e5e5e5;

.notes {
  border-radius: 6px;
  background-color: $white;
  padding: 40px 30px 17px;
  font-family: $inter;

  .menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .content {
      font-size: 13px;
      padding: 15px 0;
      border-bottom: 1px solid $gray;

      & span:nth-child(1) {
        font-weight: 700;
        margin-right: 5px;
      }

      &:last-child {
        border: none;
      }
    }
  }

  .view_more {
    margin-top: 10px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: $primary;
    text-align: end;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      background: url('../../assets/img/pets-chevron-right.svg');
      background-position: center;
      width: 11px;
      height: 11px;
      position: absolute;
      transform: translate(50%, 30%);
    }
  }
}
