@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pill {
  margin-top: 22px;
}

.pillItem {
  display: inline-block;
  width: fit-content;
  height: fit-content;
  background-color: #edf1f8;
  padding: 10px 11px;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;

  span {
    margin-right: 9px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000000;
  }

  img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
