@import '../../assets/scss/variables';

.select_wrap {
  width: 100%;
  position: relative;

  .selected {
    width: 100%;
    border: 1px solid $silver;
    border-radius: 6px;
    margin-bottom: 10px;
    overflow: hidden;

    .user_selected {
      width: 100%;
      min-height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: $white;
    }

    .triangle {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
    }
  }

  .select {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    z-index: 100;

    .active,
    .active:hover {
      background-color: $pink !important;
    }

    .option {
      min-height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      transition: 0.2s linear;
      border: 1px solid $silver;
      border-bottom: none;
      cursor: pointer;
    }

    .option:first-child {
      border-radius: 6px 6px 0 0;
    }

    .option:last-child {
      border-radius: 0 0 6px 6px;
      border-bottom: 1px solid $silver;
    }

    .option:hover {
      background-color: rgb(241, 225, 228);
    }
  }

  .select::before {
    content: '';
    height: 15px;
    background-color: transparent;
    position: absolute;
    right: 0;
    left: 0;
    transform: translateY(-100%);
  }

  .user_group {
    padding-right: 10px;
    display: flex;
    flex-direction: column;

    .user_name {
      font-family: $inter;
      font-weight: 500;
      font-size: 14px;
    }

    .user_role {
      font-family: $inter;
      font-weight: 400;
      font-size: 11px;
    }
  }
}
