@import '../../../assets/scss/variables';

.wrapper {
  background-color: $white;
  font-family: $inter;
  padding: 40px 32px;
  border-radius: 6px;
}

.titleRow {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 24px;
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;

  .buGroupWrap {
    margin-bottom: 40px;
  }

  .businessUnit {
    font-weight: 700;
    color: #3f3c4c;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .businessGroup {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .item {
    cursor: pointer;
    padding: 20px 16px;
    border: 1px solid #cfd6de;
    background-color: $white;
    border-radius: 6px;
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
    text-decoration: none !important;

    .advocatorCol {
      display: flex;
      gap: 20px;
      align-items: center;
      width: 280px;

      .advocator {
        font-size: 14px;
        color: #3f3c4c;

        div:first-child {
          font-weight: 700;
        }
      }
    }

    .statusCol {
      display: flex;
      width: 900px;
      justify-content: space-between;
      font-weight: 600;
      color: #3f3c4c;

      img {
        width: 16px;
        height: 16px;
      }

      .mobilityStatus {
        display: flex;
        gap: 6px;
        align-items: center;
        height: 24px;
        font-weight: 500;
        font-size: 12px;
        padding: 0 10px;
      }

      .btnRow {
        gap: 15px;
      }
    }
  }

  .highlight {
    border: 1px solid #EC2E2E;
  }
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 40px;
}

.btnRow {
  display: flex;
  gap: 10px;
  align-items: center;
}
