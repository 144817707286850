@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';
.containerModal {
  max-width: 1278px;
}
.editTagging {
  color: $dark-blue;
  &__header {
    padding: 26px 55px 34px 29px;
    border-bottom: none;
  }
  &__title {
    font-family: $inter;
    @include showText(20px, 700, none, 24px);
    letter-spacing: 0.83px;
    color: $dark-blue;
  }
  &__table {
    border: 1px solid #979797;
    border-radius: 6px;
    margin-bottom: 29px;
    thead {
      border-bottom: 1px solid #ececec;
    }
  }

  &__body {
    padding: 0 50px 0 28px;
    table {
      width: 100%;
      tbody {
        padding-top: 11px;
      }
      p {
        margin-bottom: 0;
      }
      th {
        padding: 17px 0 13px 0;
        // border-bottom: 1px solid #ececec;
        box-shadow: 0px -1px 0px 0px #ececec inset;
        box-shadow: 0px 0px 0px 0px #ececec inset;
        p {
          font-family: $inter;
          @include showText(14px, 500, none, 16.94px);
          color: $dark-blue;
        }
      }
      th,
      td {
        &:first-child {
          padding-left: 26px;
          width: 277px;
        }
        &:nth-child(2) {
          width: 142px;
        }
        &:nth-child(3) {
          width: 163px;
          text-align: center;
          font-family: $inter;
          @include showText(14px, 400, none, 16.94px);
          color: $dark-blue;
        }
        &:nth-child(4) {
          width: 514px;
          font-family: $inter;
          @include showText(14px, 400, none, 16.94px);
          color: $dark-blue;
        }
        &:last-child {
          width: 78px;
          padding-left: 10px;
        }
      }
      td {
        padding: 11px 0 16px 0;
        border-bottom: 1px solid #ececec;
        box-shadow: 0px -1px 0px 0px #ececec inset;
        box-shadow: 0px 0px 0px 0px #ececec inset;
      }
    }
  }
  &__wrapBtn {
    @include flex(center, flex-end);
    padding-bottom: 28px;
  }
  .scroll {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dBlock {
    display: block;
  }
  &__loading {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
}
