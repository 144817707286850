@import '../../../../../assets/scss/variables';
.wrapper {
  input:disabled {
    background-color: white !important;
    color: black !important;
  }

  :global {
    .ant-picker.ant-picker-disabled {
      background-color: white !important;
    }
    .ant-select-selector {
      background-color: white !important;
      min-height: 44px !important;
      height: fit-content !important;
      color: black !important;
    }

    .ant-select {
      height: fit-content !important;
    }

    .ant-select-selection-search {
      display: none;
    }

    .ant-select-selection-item {
      color: black !important;
    }

    .ant-select-selection-item {
      color: black !important;
      padding: 0 6px !important;
    }
    .ant-select-selection-overflow {
      padding: 0 6px !important;
    }
  }

  .firstForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding: $pdcPrintPadding;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #3f3c4c;
      display: flex;
      justify-content: space-between;
    }
  }

  .divider {
    height: 1px;
    background-color: #cbd6e2;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  $arrowWidth: 31px;
  $connectThickness: 1px;
  $connectRadius: 8px;
  $rowCardSpace: 20px;
  $connectColor: #aabcd0;
  $borderConnect: $connectThickness solid $connectColor;
  $pdcCardWidthPrint: 342px;

  .mobilitySection {
    background-color: $white;
    border-radius: 6px;
    font-family: $inter;
    padding: $pdcPrintPadding;
    margin-bottom: 10px;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #3f3c4c;
    }

    .selectMobilityOption {
      display: flex;
      align-items: center;
      height: 32px;
      gap: 6px;
    }

    .topBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .left {
        display: flex;
        gap: 20px;
        align-items: center;

        .setAsMain {
          display: flex;
          align-items: center;
          gap: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #181818;
        }

        .moreEditBtn {
          padding: 2px 10px;
          margin-left: -10px;
          cursor: pointer;
        }

        .moreOption {
          font-size: 14px;
          font-weight: 400;
          color: #181818;
          display: flex;
          gap: 6px;
          align-items: center;
          padding: 2px;

          img {
            width: 14px;
            height: 14px;
          }
        }
      }

      .right {
        display: flex;
        gap: 16px;
        width: 563px;
        justify-content: flex-end;

        .select {
          width: 300px;
        }
      }
    }

    :global {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
      .ant-select-selector {
        background-color: #f0f5fa !important;
        height: 32px !important;
      }
      .ant-select-selection-item {
        color: #5d7083 !important;
        padding: 0 6px !important;
      }
    }
  }

  .listCardSingle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .arrow {
      width: $arrowWidth;
    }

    .rowOdd,
    .rowEven {
      display: flex;
    }

    .rowOdd {
      flex-direction: row-reverse;
    }

    .rowConnectArrow {
      width: $pdcCardWidthPrint;
      display: flex;
      justify-content: center;

      img {
        height: $arrowWidth;
      }
    }
  }

  .listCardMultiple {
    display: flex;
    flex-direction: column;
    gap: $rowCardSpace;

    .tempCard {
      width: $pdcCardWidthPrint;
    }

    .rows {
      display: flex;
      justify-content: center;
      gap: $arrowWidth;
    }
  }

  //Draw Connect Line Multiple Mobility
  .wrapCard {
    display: flex;
    position: relative;
  }

  .connector {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    width: $arrowWidth;
  }

  .leftConnect,
  .rightConnect {
    position: absolute;
    top: 50%;
    width: calc($arrowWidth / 2 + $connectThickness);
    height: 8px;
    border-bottom: $borderConnect;
  }

  .leftConnect {
    border-bottom-right-radius: $connectRadius;
    border-right: $borderConnect;
    left: 100%;
  }

  .rightConnect {
    border-bottom-left-radius: $connectRadius;
    border-left: $borderConnect;
    right: 100%;

    span {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 5px solid $connectColor;
      position: absolute;
      bottom: 4px;
      right: 0;
      transform: translateY(7px);
    }
  }

  .lineBetween,
  .lineBetweenFirst,
  .lineBetweenLast {
    position: absolute;
    box-sizing: border-box;
    width: calc($arrowWidth / 2 + $connectThickness);
    border-width: $connectThickness;
    &.left {
      border-right: $borderConnect;
    }
    &.right {
      border-left: $borderConnect;
    }
  }

  .lineBetweenFirst {
    top: 50%;
    transform: translateY(calc(-1 * $connectThickness / 2));
    height: calc(50% + $connectThickness);
    border-top: $borderConnect;

    &.left {
      border-top-right-radius: $connectRadius;
      left: calc(100%);
      border-right: $borderConnect;
      width: calc($arrowWidth / 2);
    }
    &.right {
      border-top-left-radius: $connectRadius;
      left: calc(100% + $arrowWidth / 2);
      border-left: $borderConnect;
    }
  }

  .lineBetween {
    bottom: 0;
    height: calc(100% + $rowCardSpace);

    &.left {
      left: 100%;
    }
    &.right {
      right: 100%;
    }
  }

  .lineBetweenLast {
    bottom: 50%;
    transform: translateY(calc($connectThickness / 2));
    height: calc(50% + $rowCardSpace + $connectThickness);
    border-bottom: $connectThickness solid #aabcd0;
    &.left {
      border-bottom-right-radius: $connectRadius;
      left: 100%;
    }
    &.right {
      border-bottom-left-radius: $connectRadius;
      right: 100%;

      span {
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 5px solid $connectColor;
        position: absolute;
        bottom: 4px;
        right: 0;
        transform: translateY(7px);
      }
    }
  }

  .talentCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #e7e7f0;
    width: $pdcCardWidthPrint;
    background-color: $white;
    position: relative;

    .cardOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .info {
      .vacant {
        & > div:first-child {
          width: 302px;
          height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed #aabcd0;
          border-radius: 6px;
          background-color: #f7fafd;
          font-size: 10px;
          font-weight: 400;
          color: #525252;
          margin-bottom: 10px;
        }

        & > div:nth-child(2) {
          margin-bottom: 10px;
        }
      }

      .row1 {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: space-between;

        .line2 {
          color: $teal;
          font-weight: 500;
          font-size: 10px;
        }

        .line3 {
          color: #3d3d3d;
          font-weight: 500;
          font-size: 10px;
        }

        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }
        .basicInfo {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .line1 {
            color: $teal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            gap: 6px;

            .recommendedIndex {
              display: flex;
              width: 20px;
              height: 20px;
              justify-content: center;
              align-items: center;
              background-color: #00807c;
              font-size: 12px;
              font-weight: 700;
              color: $white;
              border-radius: 50%;
            }

            .matchingPercentage {
              color: $teal;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .btnRow {
          display: flex;
          gap: 10px;
          align-items: flex-start;

          img {
            width: 16px;
            height: 16px;
            padding: 2px;
          }
        }

        .additionalBtn {
          font-size: 14px;
          color: #181818;
        }
      }

      .row2 {
        border-radius: 6px;
        border: 1px solid #cbd6e2;
        background-color: #f7fafd;
        padding: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 10px;
        line-height: 12px;
        margin: 10px 0;

        span {
          color: #3d3d3d;
          font-weight: 700;
        }
      }

      .successorSection {
        font-size: 8px;
        font-weight: 500;
        color: #525252;
      }

      .successor {
        padding: 6px 8px;
        background-color: #e7f4e8;
        color: #0a7a29;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        margin-top: 6px;
        position: relative;
        z-index: 10;

        span {
          background-color: #0a7a29;
          color: $white;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 4px;
        }
      }
    }

    .additionalInput {
      padding: 8px;
      color: #394665;
      font-size: 12px;
      font-weight: 500;
      background-color: #fdd076;
      border-radius: 6px;
      margin-top: 8px;
    }

    .vacantJg {
      font-size: 10px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}
