@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.icons {
  transform: translate(-15%, 20%);

  img {
    margin-right: -12px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    pointer-events: all;
  }

  img:active {
    transform: translateY(3%);
  }
}

.inActiveDelBtn {
  background-color: #E7E7F0;
  color: white
}

.activeDelBtn {
  background-color: #FF787F !important;
  color: white !important;
  border-color: #FF787F !important;
}

.searchInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8D8D8D;
  font-size: 14px;
  padding: 0 16px;
}

.search_list {
  list-style-type: none;
  margin: 0px 0px 7px 4px;
  width: 642px;
  min-height: 36px;
  border: 1px solid #e9edf0;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 5px 20px 0;
  background-color: #fff;
  color: #000;
  position: absolute;
  bottom: 0;
  z-index: 10;
  transform: translateY(102%);

  .search_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 74px;
    gap: 20px;
    padding: 5px;
    border-bottom: 1px solid $borderColor;
    cursor: pointer;

    .avatar {
      img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .individual {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $textColor;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .name {
        color: $nameTextColor;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .dot_flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    transform: translate(100%, 80%);
  }

  .dot_flashing::before,
  .dot_flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot_flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot_flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
}