@import '../../../../../../assets/scss/variables';

.mainTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.8;
}

.subTitle {
  color: #999a9c;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
}

.description {
  width: 518px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999a9c;
}

.buttonBar {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.gridLayout {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-auto-flow: column;
}

.mainTitleLevel2 {
  font-size: 20px;
  font-family: $inter;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1px;
}

.subTitleLevel2 {
  font-size: 16px;
  font-family: $inter;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.307px;
}

.setMatchingName {
  margin-bottom: 30px;

  h3 {
    font-size: 16px;
    font-family: $inter;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.307px;
  }
}

.inputMatchingField {
  width: 300px;
  font-size: 14px;
  font-family: $inter;
  line-height: 20px;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid var(--blue-grays-100, #d3dee8);
  background: var(--general-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
