@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';
.modal {
  max-width: 747px !important;

  .modal_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #3f3c4c;
    font-family: $inter;
    border: none;
    width: 100%;
    position: relative;
    padding: 30px 30px 10px;
    :past h3 {
      color: var(--Emphasis-Body-N800, #3f3c4c);
      font-family: $inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.3px;
    }
  }

  .table {
    padding: 0 30px 10px;
    .label {
      color: var(--Light-Gray-700, #575757);
      /* Body/Small */
      font-family: $inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
      margin-bottom: 5px;
    }

    input {
      color: var(--Base-Black, #181818);
      /* Paragraph/Body 2 */
      font-family: $inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      border-radius: 4px;
      border: 1px solid var(--Light-Blue-Grays-300, #c9d5e3);
      background: var(--Base-White, #fff);
      padding: var(--font-size-112-px, 12px) var(--font-size-316-px, 16px);
      width: 100%;
    }
  }

  .listBtn {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
