@import '../../../../assets/scss/mixin';
@import '../../../../assets/scss/variables';

.selectFieldItemLabel {
  font: $inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--Light-Gray-700, #575757);
}

.selectFieldItem {
  &:first-child {
    margin-right: 10px;
  }
}