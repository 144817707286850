@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.icons {
  width: 32px;
  height: 32px;
  background: #fbfdff;
  border: 1px solid #bac7d5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    pointer-events: all;
    user-select: none;
  }

  &:active {
    transform: translateY(3%);
    border: 1px solid $teal;
  }
  
  &.enable {
    border: 1px solid $teal;
  }
}

.inActiveDelBtn {
  background-color: #e7e7f0;
  color: white;
}

.activeDelBtn {
  background-color: #ff787f !important;
  color: white !important;
  border-color: #ff787f !important;
}

.searchInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d3dee8;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #8d8d8d;
  font-size: 14px;
  padding: 0 16px;
}

.backEdit{
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
