@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.view_note {
  max-width: 1283px !important;
  font-family: $inter;

  .modal_header {
    padding: 32px 32px 21px 32px;
    border: none;
    &_title {
      font-family: $inter;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.3;

      color: #4a4a4a;
    }
  }

  .body {
    padding: 0px 32px 32px 32px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.3;
      margin-bottom: 29px;
    }

    .row {
      display: flex;
      gap: 22px;
      margin-bottom: 12px;
      .col {
        flex-basis: 522px;
        flex-grow: 1;
      }
    }

    .label {
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: #3f3c4c;
      margin-bottom: 4px;
    }

    .content {
      display: flex;
      justify-content: flex-end;
      // margin
    }

    .text_error {
      @include showText(14px, 400, none, 21.78px);
      font-family: $inter;
      color: $danger;
    }

    .formInput {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      height: 44px;
      font-size: 14px;
      border-radius: 4px;
      width: 100%;
      padding: 5px 10px;

      &::placeholder {
        color: #dcdce6;
      }
    }

    .switchInput {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 12px;
    }

    textarea {
      resize: none;
    }
  }
}
