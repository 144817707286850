.searchBar {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 34px;
}

.toolButton {
  display: flex;
  gap: 8px;
}

.textFiel {
  color: #181818;
  font-size: 13px;
  letter-spacing: 0.3px;
}

.addColumnBtn {
  border: 1px solid#00A19C !important;
  border-radius: 4px;
  background: white;
  width: 200px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #00a19c;

  img {
    margin-right: 10px;
  }
}

.groupBottom {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
