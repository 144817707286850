@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

$white: #fff;
$gray: #e5e5e5;
$colorText: #344563;
$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.view_note {
  max-width: 700px !important;
  font-family: $inter;

  .modal_header {
    padding: 46px 52px 15px;
    width: 710px;
    border: none;
  }

  .modal_body {
    padding: 0;
  }

  .menu {
    margin: 20px 0 40px;
    padding: 0 40px;
    list-style-type: none;
    box-sizing: border-box;
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $gray;
      border-radius: 6px;
      padding: 0 25px 0 15px;
      margin-bottom: 10px;

      .old {
        padding: 15px 0;
        font-size: 13px;
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        width: 605px;
        height: 93px;
        margin-bottom: 20px;
        border-radius: 6px;

        .platform {
          background-color: tomato;
          width: 61px;
          height: 61px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          color: $white;
        }

        .right {
          P {
            color: $colorText;
            font-weight: 400;
            font-size: 13px;
          }
          .date {
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.editButton,
.deleteButton {
  font-size: 12px;
  font-weight: 400;
  color: #3f3c4c;
  font-family: $inter;
}
