@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}

.SectionName {
  font-family: $inter;
  @include showText(24px, 600, none, 29.05px);
  color: #5e6c84;
  display: flex;
  justify-content: space-between;
}

.header {
  tr {
    border-bottom: 2px solid #cbd6e2 !important;
  }
}

.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  table {
    margin-bottom: 0;
  }
  &Row {
    border-bottom: 1px solid #ececec;
  }
  &Row th {
    font-family: $inter;
    @include showText(14px, 400, none, 16.94px);
    color: #181818;
    width: 50%;

    span {
      font-weight: 500;
      color: #3f3c4c;
    }
  }
  &Row td {
    font-family: $inter;
    @include showText(14px, 400, none, 16.94px);
    color: #3d3d3d;
    width: 50%;
  }

  .leftItem {
    width: 202px;
  }

  .rightItem {
    width: calc(100% - 202px);
  }
}
