@import '../../../../../assets/scss/variables';

.wrapper {
  color: white;
  font-family: $inter;
  width: 1120px;
  height: 587px;
  display: flex;
  position: relative;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 0 90px;

  .cover {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 328px;
    width: 492px;
  }

  .right {
    width: 406px;
  }

  .name {
    margin-bottom: 30px;
    & > div:first-child {
      font-size: 40px;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 10px;
    }

    & > div:last-child {
      font-weight: 500;
      font-size: 18px;
      word-break: break-word;
    }
  }
  .date {
    top: 290px;
    left: 132px;
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .desc1 {
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    width: 492px;
    margin-bottom: 10px;
  }

  .desc2 {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    width: 492px;
  }

  .endorsed {
    width: 406px;
    min-height: 328px;
    background-color: white;
    border-radius: 6px;
    padding: 30px;

    & > div:nth-child(1) {
      margin-bottom: 10px;
      color: #15191e;
      font-weight: 500;
      font-size: 20px;
    }
    & > div:nth-child(2) {
      height: 144px;
      border: 1px solid #d3dee8;
      margin-bottom: 10px;
      border-radius: 6px;
    }
    & > div:nth-child(3) {
      margin-bottom: 10px;
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      word-break: break-word;
    }
    & > div:nth-child(4) {
      font-size: 12px;
      font-weight: 400;
      color: #7a7a7a;
      word-break: break-word;
    }

    .positionWidth {
      width: 280px;
    }

    .changeBtn {
      text-decoration: underline;
      color: #15191e;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .selection {
    width: 406px;
    height: 242px;
    background-color: white;
    border-radius: 6px;
    padding: 30px;

    & > div:nth-child(1) {
      margin-bottom: 10px;
      color: #15191e;
      font-weight: 500;
      font-size: 20px;
    }
    & > div:nth-child(2) {
      font-size: 12px;
      font-weight: 500;
      color: #394655;
    }
  }

  .logo {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 100px;
    width: 68px;
  }

  .option {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d6d6d6 !important;
    padding: 10px 0;
  }

  .avatar {
    border-radius: 50%;
    width: 48px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .optionInfo {
    & > div:first-child {
      font-weight: 600;
      font-size: 14px;
      color: #3f3c4c;
    }

    & > div:last-child {
      font-weight: 400;
      font-size: 12px;
      color: #7a7a7a;
      white-space: normal;
      width: 258px;
    }
  }

  :global {
    .ant-select-item-option {
      padding: 0 10px;
    }

    .rc-virtual-list-holder {
      max-height: 500px !important;
    }
  }

  @media print {
    .changeBtn {
      display: none;
    }
  }
}

.requireField{
  color: #A91A1F;
  font-size: 14px;
  font-weight: 400;
}