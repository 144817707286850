@import '../../assets/scss/variables';
.wrapper {
  font-family: $inter;
  background-color: #f6f7f9;
  padding: 20px;
  border-radius: 6px;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #5e6c84;
  }

  .text1,
  .text2 {
    color: #3d3d3d;
  }

  .text1 {
    font-weight: 500;
  }
}
