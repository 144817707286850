@import '../../assets/scss/variables';

.container {
  padding: 5px;
  height: 400px;
  overflow-y: auto;

  .task_process {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7px;
    border-start-start-radius: 6px;
    border-start-end-radius: 6px;
  }
  .task_detail {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid #e7e7f0;
    border-top: none;
    padding: 32px 15px 31px 27px;
    margin-bottom: 24px;
    line-height: 16.94px;
    color: #000000;
    gap: 5px;
    cursor: pointer;
  }

  .task_type {
    color: #00a19c;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .title,
  .description {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .step {
    position: absolute;
    right: 17px;
    display: inline-block;
    color: #9f9eae;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
