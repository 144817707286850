.SectionName {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #5e6c84;
}
.SectionPlan {
  padding: 25px 22px 20px 29px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
