@import '../../assets/scss/variables';

$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$colorMale: #64c8bc;
$colorFemale: #6554c0;

@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}

%backgroundBorder {
  background: #feffff;
  border: 1px solid #d3dee8;
  border-radius: 6px;
}

.age_gender {
  height: 351px;
  padding: 23px;
  @extend %backgroundBorder;
  margin-bottom: 14px;
  position: relative;

  .title {
    font-family: 'Rubik', sans-serif;

    .h2 {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.3px;
      margin-bottom: 0;
      line-height: 19px;
      color: #3f3c4c;
    }

    @include flex(flex-start, space-between);
  }

  .chart {
    position: absolute;
    left: 0;
    transform: translate(5%, 10%);
  }
  .legend {
    @include flex(center, center);
    position: absolute;
    left: 0;
    transform: translate(2%, 50%);
    font-weight: 400;
    font-size: 14px;

    .h6 {
      margin-left: 20px;

      .male {
        font-weight: 700;
        color: $colorMale;
      }

      .female {
        font-weight: 700;
        color: $colorFemale;
      }
    }
  }
}
