@import '../../../../assets/scss/variables';

.card_box {
  display: flex;
  flex-basis: 84px;
  width: 100%;
  font-family: $inter;
  font-size: 13px;
  font-weight: 500;
  color: $Gray32;
  border: 1px solid $bright-gray;
  border-radius: 6px;
  min-width: 387px;
  max-width: 387px;
  height: 100%;

  .index {
    font-weight: 900;
    font-size: 14px;
    min-height: 15px;
    align-self: flex-start;
    margin-bottom: 5px;
  }

  .image {
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  span {
    font-weight: 500;
  }

  .icon_card {
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .left {
    background-color: #d9f2f7;
    padding: 10px;
    width: 84px;
    border-radius: 6px 0 0 6px;
    flex-shrink: 0;
    border-right: 1px solid $bright-gray;

    .staffId {
      margin-top: 12px;
      font-size: 10px;
      font-weight: bold;
    }

    .position {
      font-size: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: left;
      gap: 3px;

      &span {
        width: 50%;
      }
    }
  }

  .right {
    padding: 10px 8px;
    flex-grow: 1;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    gap: 10px;

    .name {
      color: $recommnendationText;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;

      .mobilityPlan {
        margin: 0 5px;
        cursor: pointer;
      }
    }

    .releaseChecklist {
      display: inline-block;
      margin-left: 10px;
      width: 12px;
      height: 12px;
      border-radius: 4px;
      background-color: #4caf50;
    }
  }

  .textThin {
    font-weight: 300;
  }

  .additionalInput {
    padding: 5px 5px;
    border: 1px solid #cbd6e2;
    background: #f0f5fa;
    border-radius: 6px;
    margin-top: 10px;

    ul {
      max-width: 230px;
      margin: 0;
      padding: 0;
      padding-left: 15px;
      list-style: none;
      li {
        color: var(--Light-Blue-Grays-800, #262d36);
        font-family: $inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.5px;
        word-wrap: break-word;
        position: relative;
        &::before {
          content: '\2022';
          color: #262d36;
          font-size: 12px;
          position: absolute;
          left: -10px;
          line-height: 10px;
          top: 2px;
        }
      }
    }
  }
}

.auto_width {
  min-width: auto;
  max-width: 366px;
  margin-left: auto;
  margin-right: auto;
}

.click {
  cursor: pointer;
}

.click:active {
  transform: translateY(1px);
}
