@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.header {
  display: flex;
  justify-content: space-between;

  .SectionName {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #5e6c84;
  }

  img {
    cursor: pointer;
  }
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  font-family: $inter;

  table {
    margin-bottom: 0;
    font-family: $inter;
    color: #344563;
    font-size: 14px;
    font-style: normal;
  }

  th {
    font-weight: 500;
    line-height: 17px;
  }

  tbody {
    font-weight: 400;
    line-height: 16px;
  }
}
.btn {
  width: 100%;
  height: 72px;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}
.TextBtn {
  font-family: $inter;
  font-weight: 700;
  font-size: 18px;
  color: #344563;
}
.btn:hover {
  background: #e9edf0;
}

.body {
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3;
    margin-bottom: 29px;
  }

  .label {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #3f3c4c;
    margin-bottom: 4px;
  }

  .content {
    display: flex;
    justify-content: flex-end;
  }

  .text_error {
    @include showText(10px, 500, none, 12px);
    color: #da1e28;
    margin-top: 4px;
  }

  .formInput {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    height: 44px;
    font-size: 14px;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;

    &::placeholder {
      color: #dcdce6;
    }
  }

  .switchInput {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 12px;
  }

  textarea {
    resize: none;
  }
}
