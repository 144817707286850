.searchBar {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  justify-content: space-between;

  .search {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  :global {
    .ant-dropdown-menu {
      min-width: 118px;
      padding: 10px;
    }

    .ant-dropdown-menu-item:hover {
      background-color: #e0f3f3;
    }

    .ant-dropdown-menu-item {
      border-radius: 4px;
      color: #004846;
    }
  }
}

.filterSelected {
  color: #787587;
  margin-bottom: 34px;

  .status {
    display: flex;
    gap: 34px;
    align-items: center;
    margin-top: 12px;

    span {
      color: #00847c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

.toolButton {
  display: flex;
  gap: 8px;
}

.createdDate {
  position: relative;

  span {
    display: flex;
    position: absolute;
    top: -11px;
    left: 0;
    gap: 10px;
    text-wrap: nowrap;
    z-index: 1;
  }

  img {
    cursor: pointer;

    &.desc {
      transform: rotate(180deg);
    }
  }
}

.textField,
.agendaField {
  color: #181818;
  font-size: 13px;
  letter-spacing: 0.3px;
}

.agendaField {
  font-size: 14px;
  font-weight: 500;
}

.mainAgenda {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e7edf3;
  border-radius: 4px;
  color: #414e5c;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.customBtn {
  border: 1px solid #e9edf0 !important;
}
