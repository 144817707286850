@import '../../../assets/scss/variables';

.dashboardContainer {
  padding-top: 80px;
}

.dashboardTab {
  display: flex;
  color: $black;
  gap: 20px;
  width: 100%;
  position: relative;
  font-family: $inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 35px;

  &::after {
    content: '';
    width: 100%;
    border-bottom: 1px solid #edf1f8;
    position: absolute;
    transform: translateY(42px);
  }

  .active {
    color: $emeraldColor;
    padding-bottom: 4px;
    border-bottom: 3px solid $emeraldColor;
  }
}

.dashboardContent {
  width: 100%;
}
