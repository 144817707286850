@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.CareerJourney {
  padding: 25px 26px 30px 28px;
  border-radius: 6px;
  background: #f4f5f8;
  margin-top: 18px;
  &__h3 {
    @include showText(24px, 600, none, 29.05px);
    color: $dark-gray;
    font-family: $inter;
    padding-bottom: 32px;
  }
  &__milestone {
    @include flex(flex-start, space-between);
  }
  &__left {
    margin-left: 28px;
    width: 100%;
  }
  &__list {
    list-style-type: none;
    display: flex;
    height: 116px;
    padding-top: 5px;
    background: rgba(0, 0, 0, 0.0001);
    box-shadow: inset 0px -1px 0px #cccccc;
    margin-bottom: 0;
    width: 100%;
    min-width: 620px;
  }
  &__item {
    position: relative;
    p {
      margin-bottom: 13px;
    }
  }
  &__tooltip {
    @include showText(12px, 700, none, 14.52px);
    font-family: $inter;
  }
  &__point {
    font-family: $inter;
    color: $dark-blue;
    @include showText(12px, 700, none, 14.52px);
    &_last {
      margin-left: -3px;
      color: $dark-blue;
      font-family: $inter;
      @include showText(12px, 700, none, 14.52px);
    }
    letter-spacing: 0.3px;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      top: 20px;
      height: 2px;
      background-color: $dark-blue;
    }
  }
  &__mask {
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    height: 23px;
    margin-bottom: 0;
    @include flex(center, center);
    width: 100%;
    font-family: $inter;
    @include showText(14px, 700, none, 16.94px);
    color: white;
  }
  &__detail {
    width: 100%;
    height: 55px;
    font-family: $inter;
    @include flex(center, center, column);
  }
  &__years {
    @include showText(14px, 700, none, 16.94px);
    line-height: 0.3px;
    color: $dark-blue;
  }
  &__position {
    @include showText(12px, 500, none, 14.52px);
    line-height: 0.3px;
    color: $dark-blue;
  }
  .tenYears {
    @include flex(center, center, column);
    .logoPET {
      width: 61px;
      height: 61px;
      background-color: white;
      border-radius: 50%;
      @include flex(center, center);
    }
    .label {
      margin-top: 8px;
      font-family: $inter;
      color: $dark-blue;
      text-align: center;
      @include showText(12px, 700, none, 14.52px);
      letter-spacing: 0.3px;
    }
  }
  &__listPosition {
    padding-top: 28px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    p {
      margin-bottom: 19px;
      position: relative;
      @include flex(center);
    }
  }
  &__expItem {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
  }
  &__expIcon {
    margin-right: 14px;
  }
  &__listTagPosition {
    display: flex;
    align-items: center;
    margin-top: 15px;

    p {
      font-family: $inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.173px;
      background-color: #ebebeb;
      color: var(--Light-Gray-800, #3D3D3D);
      padding: 10px 20px;
      margin: 0;

      &:last-child {
        padding-left: 10px;
      }
    }

    img {
      margin-right: 10px;
    }
  }
  &__titleExp {
    padding-top: 20px;
    @include showText(24px, 600, none, 29.05px);
    color: $dark-blue;
    font-family: $inter;
  }
}
