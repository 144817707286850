#businessChart {
  .apexcharts-legend {
    width: 750px;
    margin: auto;
  }
  .apexcharts-legend-series {
    width: 230px;
  }
  .apexcharts-legend-text {
    line-height: 12px;
  }
  .apexcharts-xaxis-label {
    line-height: 12px;
  }
  .apexcharts-tooltip {
    padding: 2px 5px;
    height: 20px;
  }
}
