@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';

.maxWidth {
  width: 1287px;
  margin: auto;
}

.control {
  width: 100%;
  margin-bottom: 22px;

  .title_page {
    font-family: $inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.3px;
    color: #3f3c4c;
  }

  .btnPrint {
    font-family: $inter;
    font-weight: 600;
    font-size: 12px;
    text-align: center;

    background-color: #c4c4c4;
    border-radius: 6px;
    padding: 5px 10px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 77px;
    height: 36px;
    cursor: pointer;
    user-select: none;

    &:active {
      transform: translateY(2px);
    }
  }
}

.breadcrumb {
  @include flex(center, flex-start);
  margin-top: 10px;
  p {
    font-family: $inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $light_green;
  }

  img {
    transform: translateY(-40%);
  }

  .talent_profile {
    font-family: $inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    text-transform: capitalize;
  }
}

.dashboardSP {
  background-color: white;
  border-radius: 6px;
  padding: 23px 32px;
  margin-bottom: 22px;

  .title {
    font-family: $inter;
    font-weight: 600;
    font-size: 18px;
  }
}

@media print {
  .dashboardSP {
    padding: 0 20px;
  }
}
