@import '../../../../../assets/scss/variables';

.wrapper {
  border-bottom: 1px solid #d6d6d6;
  
  .addButton {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    margin-top: 10px;
  }

  :global {
    .ant-select-selector {
      padding: 0;
      margin-bottom: 2px;
    }
    .rc-virtual-list {
      padding: 0 12px 0 16px;
      margin-top: 16px;
      margin-right: 16px;
      max-height: 236px !important;

    }

    .ant-select-selection-overflow {
      padding-left: 0 !important;
    }

    .ant-select-selection-placeholder {
      left: 0;
      color: #7a7a7a;
    }

    .ant-select-selection-search-input {
      margin-top: 10px !important;
      margin-left: -6px !important;
    }

    .ant-select-dropdown {
      width: 500px !important;
    }

    .rc-virtual-list-holder {
      // max-height: 236px !important;
    }

    .rc-virtual-list-holder-inner {
      padding: 0;

      .ant-select-item-option {
        border: none;
        border-top: 1px solid #ebebeb;
        padding: 0;

        &:first-child {
          border-top: none;
        }
      }
    }

    // .rc-virtual-list-scrollbar {
    //   width: 4px !important;
    //   background-color: rgba(196, 196, 196, 0.5);
    //   visibility: visible !important;
    //   border-radius: 4px;

    //   .rc-virtual-list-scrollbar-thumb {
    //     border-radius: 4px;
    //     background-color: rgba(74, 74, 74, 0.5) !important;
    //   }
    // }

    .ant-checkbox-wrapper {
      position: relative;
      z-index: -1;
      gap: 12px;

      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.173px;
      }
    }

    .ant-tooltip {
      min-width: 300px;
    }

    .ant-tooltip-inner {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.173px;
      padding: 12px;
      border-radius: 4px;

      ul {
        font-weight: 500;
        margin: 0;
        padding-left: 22px;
      }
    }
  }

  .option {
    position: relative;
    z-index: 100;
    padding: 8px 4px;
  }

  .added {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .positionName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $white;
      border: 1px solid #ebebeb;
      border-radius: 4px;
      border-left: 8px solid #66b2c4;
      color: $teal;
      padding: 8px 10px 8px 12px;
      min-height: 62px;

      span {
        font-weight: 600;
      }

      img {
        cursor: pointer;
      }
    }
  }

}
