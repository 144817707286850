@import '../../assets/scss/variables';
.title {
  font-weight: 600;
  font-size: 20px;
  color: #5e6c84;
}

.text2 {
  color: #3d3d3d;
}

.text1, .text1 * {
  font-family: $inter !important;
  font-weight: 500 !important;
  color: #3d3d3d !important
}

.customTable{
  vertical-align: top;
  p{
    margin-bottom: 5px !important;
  }
}
