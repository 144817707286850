@import '../../../../../../assets/scss/variables';
@import '../../../../../../assets/scss/mixin';

$btnColor: #344563;
$white: #fff;
$tableColor: rgba(151, 151, 151, 0.5);

.collapse {
  position: relative;

  .collapseHeader {
    width: 100%;
    height: 33px;
    margin-top: 10px;

    font-family: $inter;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 0 20px;

    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      transition: transform 333ms ease-out;
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    .animate {
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3f3c4c;
    font-size: 12px;
    font-family: $inter;
    font-weight: 400;

    div {
      width: 50%;
      padding: 10px;
      border: 1px solid #e7e7f0;
      border-bottom: 2px solid #e7e7f0;
      text-align: center;
      box-sizing: border-box;
      transition: border-bottom 333ms ease-in-out;
      cursor: pointer;
    }

    .active {
      border-bottom: 2px solid #50a4b9;
      color: #50a4b9;
      font-weight: 600;
    }
  }

  .body {
    padding: 20px 20px;
    background-color: #fffaef;
    border: 1px solid #e7e7f0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: $inter;
    font-weight: 400;
    font-size: 12px;
    color: #50a4b9;

    div {
      padding: 8px 12px;
      border: 1px solid #50a4b9;
      border-radius: 4px;
      cursor: pointer;
    }

    .activeFilter {
      color: white;
      background-color: #50a4b9;
    }
  }

  .table {
    margin-top: 20px;
    // max-height: 700px;
    // overflow-y: overlay;

    table {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      width: 100%;
    }

    td {
      text-align: center;
      padding: 0;
    }

    tbody {
      display: block;
      max-height: 1000px;
      border-bottom: 1px solid #e7e7f0;
      border-radius: 6px;
      overflow-y: overlay;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }

    tbody > tr:last-child {
      border-bottom: none;
    }

    .bodyCollapse {
      max-height: 1000px;
      border-bottom: 1px solid #e7e7f0;
      border-radius: 6px;
      overflow-y: overlay;
      background-color: #fffaef;
      border: 1px solid #e7e7f0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .tableHeader {
      background-color: #50a4b9;
      border: 1px solid #50a4b9;
      padding: 10px;
      color: white;
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .nameHeader {
        text-align: left;
      }
    }

    .tableBody {
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      border: 1px solid #e7e7f0;
      cursor: pointer;
      display: flex;
      align-items: center;
      min-height: 35px;
      padding: 7px;
      .name {
        font-weight: 600;
        color: #50a4b9;
        text-align: left;
      }

      img {
        transition: transform 333ms ease-out;
        -ms-transform: rotate(0);
        transform: rotate(0);
      }

      .animate {
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

    .tableBodyCard {
      font-family: $inter;
      font-weight: 500;
      font-size: 12px;
      height: 30px;
      border: 1px solid #e7e7f0;

      .name {
        font-weight: 600;
        color: #50a4b9;
        text-align: left;
      }

      .card_box {
        font-family: $inter;
        font-size: 13px;
        font-weight: 400;
        color: #525252;

        div {
          margin-bottom: 5px 0;
        }

        .number {
          font-weight: bold;
          font-size: 14px;
          color: #3f3c4c;
          line-height: 20px;
        }

        span {
          font-weight: 500;
        }

        .icon_card {
          img {
            margin-left: 10px;
          }
        }

        .left {
          background-color: #d9f2f7 !important;
          padding: 10px;
          font-weight: 400;

          .position {
            font-size: 10px;
            margin-top: 5px;
            font-weight: 500;
          }
        }

        .right {
          padding: 10px;
          display: flex;
          flex-direction: column;
          text-align: left;
          font-weight: 400;

          span {
            font-weight: 500;
          }
        }

        .image {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.recommendation {
  &__name {
    text-decoration: none;
    font-family: $inter;
    @include showText(12px, 600, none, 20px);
    color: $recommnendationText;
    &:hover {
      text-decoration: none;
    }
  }
}

.set_options {
  margin-top: 15px;
  .label {
    font-family: $inter;
    font-size: 14px;
    font-weight: 500;
  }
}

.box_main {
  font-size: 16px;
  color: #414e5c;
  padding: 12px;
  background-color: #e7edf3;
  border-radius: 5px;
  margin-left: 10px;
}
.card_box {
  // width: 49%;
  font-family: $inter;
  font-size: 13px;
  font-weight: 400;
  color: #525252;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  .approve {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    text-align: center;
    letter-spacing: 0.25px;
    margin-bottom: 10px;
  }

  .image,
  .viewImage {
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }

  .viewImage {
    img {
      border: solid 1px white;
    }
  }

  .name {
    color: #50a4b9;
    font-family: $inter;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 20px;
  }

  .number,
  .viewNumber {
    font-weight: 900;
    font-size: 14px;
    color: #3f3c4c;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .viewNumber {
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }

  span {
    font-weight: 400;
  }
  .icon_card {
    img {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .left {
  
    padding: 0;
    .sp_counter {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }

  .right {
    margin-top: 10px;
    width: 100%;
  }

  .mainAgendaStatus {
    padding: 6px 8px;
    border-radius: 4px;
    color: #3d3d3d;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;
    background-color: #ebebeb;
    width: fit-content;
  }

 
}