@import '../../../../../assets/scss/variables';

.controlBar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 32px;
}

.subText {
  display: block;
  font-family: $rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999a9c;
}

.customTooltip {
  position: absolute;
  top: 38px;
  left: 18px;
}

.tooltipContent {
  display: flex;
  align-items: center;
  font-family: $inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 10px;

  span {
    display: inline-block;
    padding-left: 10px;
  }
}
