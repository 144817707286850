@import '../../../../assets/scss/variables';

.container {
  padding: 50px 30px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 456px;
  height: 1141px;
  overflow-y: auto;
  flex: 1;
  background-color: #fff;
  border-radius: 6px;
}

.mainTitle {
  display: flex;
  align-items: center;
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 77px;
}

.addPosition {
  width: 100%;
  height: 100%;
  min-height: 164px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e7e7f0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: $inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #787587;
  user-select: none;
  text-align: center;
  padding: 20px;
}

.addPositionError {
  border: 2px solid #ff787f;
}

.editTitleBtn {
  background: #fbfdff;
  border: 1px solid #e9edf0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 12px;
  margin-left: 15px;
  cursor: pointer;
}

.inputTextTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  border: 1px solid #e9edf0;
  border-radius: 4px;
  padding: 10px;
}
