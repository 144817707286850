$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$colorBolderBackground: #d3dee8;
$colorTotal: #4a4a4a;
$colorTotalNumber: #666769;

@mixin flex($algin: flex-start, $justify: flex-start, $direction: row, $warp: nowrap) {
  display: flex;
  align-items: $algin;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $warp;
}

%backgroundBorder {
  background: #feffff;
  border: 1px solid $colorBolderBackground;
  border-radius: 6px;
}

.title {
  font-family: 'Rubik', sans-serif;
  @include flex(flex-start, space-between);

  .h2 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    line-height: 19px;
    color: #3f3c4c;
  }
}

.talent_category {
  padding: 23px;
  height: 351px;
  @extend %backgroundBorder;
  margin-bottom: 16px;
  position: relative;
}

.chart {
  position: relative;
}
.text {
  position: absolute;
  @include flex(center, center);
  flex-direction: column;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);

  p {
    margin: 0;
  }

  .total {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: $colorTotal;
  }

  .number_total {
    font-size: 36px;
    line-height: 42px;
    color: $colorTotalNumber;
  }
}
