@import "../../assets/scss/variables";
@import "../../assets/scss/mixin";
.CareerAspiration {
  padding: 29px 0 13px 33px;
  background-color: $light-blue;
  border-radius: 6px;
  &__h3 {
    font-family: $inter;
    @include showText(24px, 600, none, 29.05px);
    color: $dark-gray;
    padding-bottom: 18px;
  }
  &__item {
    font-family: $inter;
    @include showText(14px, 500, none, 16.94px);
    letter-spacing: 0.3px;
    position: relative;
    padding-left: 23px;
    &::before {
      position: absolute;
      content: " ";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $cyan-blue;
      top: 50%;
      left: 5px;
      transform: translate(-50%, -50%);
    }
  }
}
