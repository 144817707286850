@import '../../../../../assets/scss/variables';

.wrapper {
  padding: $pdcPrintPadding;
  font-family: $inter;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: #3f3c4c;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.content {
  border-radius: 8px;
  border: 1px solid #cbd6e2;

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
}

.noColumn {
  flex-basis: 40px !important;
  flex-grow: 0 !important;

  .noText {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: black;
    margin-top: 22px;
  }
}
