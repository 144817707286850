//Font text
$inter: 'Inter', sans-serif;
$rubik: 'Rubik', sans-serif;

//Colors to be used
$dark-aqua: #0c877a;
$light-aqua: #9ad8d2;
$black: #000000;
$charcoal: #333436;
$grey: #666769;
$silver: #999a9c;
$pale-grey: #f8f8f8;
$medium-grey: #cccccc;
$sand: #edab3d;
$twilight: #58478d;
$cobalt: #1c2c73;
$indigo: #2f1a48;
$light-purple: #cfcade;
$tomato: #bc242d;
$cranberry: #9b151d;
$KeyBorderRight: #e75552;
$KeyThemeBG: #e2e4e7;
$KeThemeListBox: #fafafa;
$BoxBorder: #cbcbcb;
$cyan-blue: #364765;
$light-blue: #f4f5f8;
$light-gray: #d9d9d9;
$Silver: #c5c5c5;
$Gray32: #525252;
$dark-blue: #344563;
$blue-gray: #566879;
$dark-gray: #5e6c84;
$white: #ffffff;
$teal: #00a19c;
$pink: #c4c4c4;
$gray: #999a9c;
$green: #64c8bc;
$silver: #e9edf0;
$light-pink: #e5e5e5;
$blue: #637ab8;
$light_green: #00a19c;
$chetwode_blue: #5c6bc0;
$bright-gray: #e7e7f0;
$geyser: #d3dee8;
//Defining Scheme
$primary: $dark-blue;
$secondary: $dark-gray;
$border-nofication-number: $teal;
$warning: $tomato;
$textColor: #344563;
$bgBtnColor: #c4c4c4;
$bgSideColor: #f4f5f8;
$Concrete: #f3f3f3;
// $success: #45b649;
$danger: #f85032;

// $info: #00c9ff;
$bgTargetProgessBar: #dff1f1;
$bgTargetColorBar: #41b2ae;
$bgColorBarSmall: #e4e8f3;
$colorGreen: #6fc27b;
$colorYellow: #f6db7d;
$colorBlueEx: #5e6c84;
$colorBlueBolder: #344563;
$tableColor: #979797;
$recommnendationText: #50a4b9;
$emeraldColor: #009089;

//PDC Mobility
$pdcCardWidth: 372px;
$pdcApprovedSPColor: #68b172;
$pdcAIRecommendColor: #fdb924;

//PrintPDC
$pdcPrintPadding: 16px;
