@import '../../assets/scss/variables';

.container {
  padding: 5px;
  height: 400px;
  overflow-y: auto;
  cursor: pointer;

  .notification_detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid #e7e7f0;
    padding: 15px;
    margin-bottom: 10px;
    line-height: 16.94px;
    color: #000000;
    gap: 5px;
  }

  .unseen_noti {
    background-color: rgba(0, 161, 156, 0.08);
    position: relative;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #00A19C;
      position: absolute;
      right: 5%;
      top: 50%;
      border-radius: 50%;
    }
  }

  .sent_at {
    font-weight: 400;
    font-size: 10px;
    line-height: 12.1px;
    color: #8d8d8d;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .notifcation_type {
    color: #00a19c;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;

    img {
      margin-left: 15px;
      transform: translateY(-18%)
    }
  }

  .title {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
  }

  .description {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
  }
}