@import '../../../../assets/scss/variables';

.wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  font-family: $inter;

  .selected {
    color: #007b74;
  }

  div {
    width: 96px;
  }

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 32px !important;
      padding: 0px;
      border: 1px solid $teal !important;

      .ant-select-selection-placeholder{
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        color: #007b74 !important;

        &::after{
          display: none;
        }
      }

      .ant-select-item-option{
        padding: 10px !important;
        color: #007b74;
        font-size: 14px;
      }

      .ant-select-dropdown{
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}
