@import '../../../../../../assets/scss/variables';

.wrapper {
  .titleInput {
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
    color: $black;
    font-family: $inter;
  }

  .linkInput {
    color: $black;
    font-family: $inter;
  }

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.https_msg {
  font-family: 'Inter', sans-serif;
  color: var(--Base-White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.info_circle {
  margin-left: 4px;
  margin-right: 4px;
}
