@import '../../../../../assets/scss/variables';
@import '../../../../../assets/scss/mixin';

.container {
  margin-top: 32px;
}

.form {
  background: #e2f1fc;
  border: 1px solid #003f9a;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  height: 187px;
  border-radius: 0 4px 4px 0;
}

.title_info {
  font-family: $inter;
  font-weight: 700;
  font-size: 16px;
  color: #003f9a;
  padding: 22px 0px 0px 14px;
}

.content {
  font-family: $inter;
  font-weight: 500;
  font-size: 14px;
  color: #003f9a;
  margin: 12px 0 0 6px;
}

.content_li {
  font-weight: 400;
  margin: 8px 0 0 25px;
}

.accent_border {
  width: 4px;
  height: 187px;
  background: #003F9A;
  position: absolute;
}
