@import '../../assets/scss/variables';
@import '../../assets/scss/mixin';

.header {
  display: flex;
  justify-content: space-between;
  .SectionName {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #5e6c84;
  }
}

.SectionPlan {
  padding: 25px 22px 20px 22px;
  background: #f4f5f8;
  width: 100%;
  border-radius: 6px;
}
.tablePlan {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  table {
    margin-bottom: 0;
  }
  &Thead th {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3f3c4c;
  }
  &Body {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3f3c4c;

    .detail {
      margin-bottom: 18px;
      max-width: 300px;
      white-space: pre-wrap;
    }

    .date {
      color: #787587;
      font-size: 10px;
    }
  }
}
.btn {
  width: 100%;
  height: 72px;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
}
.TextBtn {
  font-family: $inter;
  font-weight: 700;
  font-size: 18px;
  color: #344563;
}
.btn:hover {
  background: #e9edf0;
}

.title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #3f3c4c;
}

.body {
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3;
    margin-bottom: 29px;
  }

  .label {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #3f3c4c;
    margin-bottom: 4px;
  }

  .content {
    display: flex;
    justify-content: flex-end;
  }

  .text_error {
    @include showText(10px, 500, none, 12px);
    color: #da1e28;
    margin-top: 4px;
  }

  .formInput {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    height: 44px;
    font-size: 14px;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    resize: none;

    &::placeholder {
      color: #dcdce6;
    }
  }

  .switchInput {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 12px;
  }

  textarea {
    resize: none;
  }
}

.positionCards {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 8px;
  margin-bottom: 20px;

  :global {
    .ant-tooltip-inner {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0.173px;
      padding: 12px;
      border-radius: 4px;

      ul {
        font-weight: 500;
        margin: 0;
        padding-left: 22px;
      }
    }
  }

  .card {
    border: 1px solid #ebebeb;
    border-left: 8px solid #66b2c4;
    border-radius: 4px;
    padding: 12px;
    color: #00a19c;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;

    span {
      font-weight: 400;
    }
  }
}

.divider {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 20px;
}
