@import '../../../../assets/scss/variables';

.wrapper {
  padding: 20px 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;

  .title {
    font-weight: 700;
    color: #3f3c4c;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .add {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 20px;
      color: #007874;
      font-weight: 500;
      background-color: transparent;
      border: none;
      user-select: none;
    }

    .text{
      color: #9f9eae;
      line-height: 30px;
      font-size: 14px;
    }
  }

  .viewMore{
    position: relative;
    height: 30px;

    button{
      background: transparent;
      border: none;
      font-size: 12px;
      font-weight: 700;
      color: #3F3C4C;
      position: absolute;
      padding: 10px 0;
      right: 0;

      img{
        margin: 0 0 3px 2px;
      }
    }
  }
}
