@import '../../assets/scss/variables';
$borderColor: #eeeeee;
$textColor: #999a9c;
$nameTextColor: #000;

.search_box {
  position: relative;

  .search_input {
    width: 515px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #e9edf0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
    padding-left: 35px;
  }

  .search_input::-webkit-input-placeholder {
    font-family: $rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    opacity: 0.5;
    user-select: none;
  }

  .search_input::-webkit-search-cancel-button {
    /* Remove default */
    // -webkit-appearance: none;
    /* Now your own custom styles */
    cursor: pointer;
    margin-right: 10px;
  }

  .search_talent {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: 1px solid #d3dee8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background: #fff;
    box-sizing: border-box;
    text-indent: 10px;
  }

  .search_talent::-webkit-input-placeholder {
    font-family: $rubik;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.5;
    user-select: none;
  }

  .search_talent::-webkit-search-cancel-button {
    /* Remove default */
    // -webkit-appearance: none;
    /* Now your own custom styles */
    cursor: pointer;
    margin-right: 10px;
  }

  .lookup {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(50%, -50%);
    cursor: pointer;
  }

  .search_list {
    list-style-type: none;
    margin: 0;
    width: 520px;
    min-height: 36px;
    border-radius: 6px;
    border: 1px solid #e9edf0;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.04);
    padding: 5px 20px 0;
    background-color: #fff;
    color: #000;

    position: absolute;
    bottom: 0;
    z-index: 10;
    transform: translateY(102%);
    overflow-y: scroll;
    max-height: 230px;

    .search_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // height: 74px;
      gap: 20px;
      padding: 5px;
      border-bottom: 1px solid $borderColor;
      cursor: pointer;

      .avatar {
        img {
          width: 35px;
          height: 35px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .invidual {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $textColor;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .name {
          color: $nameTextColor;
          font-weight: 600;
          line-height: 16px;
        }
      }
    }

    .dot_flasing {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
      transform: translate(100%, 80%);
    }

    .dot_flasing::before,
    .dot_flasing::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot_flasing::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    .dot_flasing::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #9880ff;
      color: #9880ff;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: #9880ff;
      }
      50%,
      100% {
        background-color: #ebe6ff;
      }
    }
  }
}
