.wrapper {
  display: flex;
  gap: 8px;
}

.pill {
  background-color: #edf1fe;
  display: inline-block;
  border-radius: 16px;
  height: 32px;
  width: fit-content;
  padding: 0 9px;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  vertical-align: middle;

  span {
    color: black !important;
    margin-right: 9px;
    margin-left: 7px;
  }

  img {
    margin-bottom: 1px;
    cursor: pointer;
  }
}
