.wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .searchBar{
        display: flex;
        gap: 8px;
        align-items: flex-end;        

        input{
            width: 693px;
        }
    }
}