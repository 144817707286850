@import '../../../assets/scss//variables';
@import '../../../assets/scss/mixin';
.dropdown {
  // width: 73px;
  position: relative;
  &__select {
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    height: 29px;
    cursor: pointer;
    background: white;
    p {
      margin-bottom: 0;
      @include showText(12px, 700, none, 16px);
      font-family: $inter;
      color: $dark-blue;
    }
    img {
      width: 7px;
      height: 4px;
      margin-left: 8px;
    }
    width: 100%;
    padding: 7px 12px 6px 17px;
    @include flex(center, space-between);
  }
  &__wrapper {
    position: absolute;
    top: 30px;
    background-color: #ffffff;
    // padding: 14px 0 0 26px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    z-index: 10;
  }
  &__list {
    overflow-y: scroll;
    max-height: 300px;
  }
  &__item {
    &:first-child {
      padding-top: 14px;
    }
    padding-bottom: 14px;
    padding-left: 26px;
  }
  &__apply {
    border-top: 1px solid #d8d8d8;
    @include flex(center, flex-end);
    padding: 8px 7px 8px 0px;
  }
  &__btn {
    border: none;
    outline: none;
    width: 97px;
    height: 19px;
    border-radius: 22px;
    background-color: $teal;
    font-family: $inter;
    @include showText(10px, 400, none, 18px);
    color: white;
  }
  &__icon {
    padding: 0 4px;
    cursor: pointer;
    img {
      color: #8896a7;
    }
  }
}
