@import '../../../../../assets/scss/variables';

.name {
  display: flex;
  gap: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #3d3d3d;
  margin-bottom: 20px;
  justify-content: space-between;

  & > div {
    display: flex;
  }
}

.row,
.rowMulti {
  margin-bottom: 16px;
}

.rowMulti {
  display: flex;
  gap: 20px;
}

.col {
  width: 25%;
}

.wrapper {
  padding: $pdcPrintPadding;
  

  input:disabled {
    background-color: white !important;
    color: black !important;
  }

  :global {
    .ant-picker.ant-picker-disabled {
      background-color: white !important;
    }
    .ant-select-selector {
      background-color: white !important;
      min-height: 44px !important;
      height: fit-content !important;
      color: black !important;
    }

    .ant-select{
      height: fit-content !important;
    }

    .ant-select-selection-search{
      display: none;
    }

    .ant-select-selection-item {
      color: black !important;
    }
  }
}
