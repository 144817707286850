@import '../../../../assets/scss/variables';

.filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.dot {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  z-index: 1;
}

.desc {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  font-family: $inter;
  font-size: 12px;
  color: #3d3d3d;
  font-weight: 700;
  letter-spacing: 0.0144em;

  .name {
    display: flex;
    gap: 8px;
    align-items: center;
    line-height: 100%;

    .effective {
      display: block;
      width: 26px;
      height: 26px;
    }
  }
}

.table {
  display: flex;

  & > div {
    display: flex;
    flex-direction: column;
  }

  .descColumn {
    flex-grow: 1;
    min-width: 447px;

    & > div {
      display: flex;
      outline: 1px solid #cbd6e2;
    }
  }

  .dataColumn {
    display: flex;
    flex-direction: column;
  }
}

.talentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #15191e;
  width: 130px;
  background-color: $white;

  img {
    border-radius: 50%;
    width: 74px;
    height: 74px;
  }

  .name {
    font-size: 13px;
    font-weight: 700;
    text-align: center;

    div:last-child {
      font-weight: 600;
      font-size: 10px;
    }
  }

  .jg {
    font-size: 10px;
    font-weight: 400;
  }
}

.rowName {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div {
    display: flex;
    align-items: center;
  }

  .parent {
    width: 99px;
    padding-left: 9px;
    line-height: 96px;
    text-align: left;
    vertical-align: middle;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    outline: 1px solid #cbd6e2;
  }

  .childWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: $white;
    flex-grow: 1;
    outline: 1px solid #e7edf3;

    .child {
      display: flex;
      justify-content: left;

      &:last-child {
        outline: 1px solid #e7edf3;
      }

      .childName {
        padding-left: 12px;
        display: flex;
        gap: 18px;
        align-items: center;
        width: 100%;

        .childNo {
          flex-shrink: 0;
          width: 28px;
          height: 28px;
          line-height: 28px;
          border-radius: 8px;
          color: $black;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.173px;
          text-align: center;
          vertical-align: middle;
        }

        .childTitle {
          line-height: 20px;
          vertical-align: middle;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.0168em;
          color: #3d3d3d;
        }
      }
    }
  }
}

.ladders {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: flex-end;

    .cell {
      background-color: #e7edf3;
      outline: 1px solid #cbd6e2;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #575757;
      font-size: 12px;
      font-weight: 700;
      position: relative;

      .nameLevel {
        position: absolute;
        top: -16px;
        left: 0;
        white-space: nowrap;
      }

      &.hide {
        background-color: white;
        outline: none;
      }
    }
  }
}

.values {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    justify-content: flex-end;

    .cell {
      background-color: $white;
      outline: 1px solid #e7edf3;
      display: flex;
      gap: 6px;
      justify-content: center;
      align-items: center;
      color: #575757;
      font-size: 12px;
      font-weight: 700;
      position: relative;

      .nameLevel {
        position: absolute;
        top: -16px;
        left: 0;
        white-space: nowrap;
      }

      &.hide {
        background-color: transparent;
        border: none;
      }

      span{
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .sma{
        background-color: #5d7d8e;
      }

      .external{
        background-color: #ffbd76;
      }
    }
  }
}
