@import '../../../../../assets/scss/variables';

.mainTitle {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 32px;
}

.noteTitle {
  font-family: $rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999a9c;
}

.bottomBtnBar {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fullPageLoading {
  position: static;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
}

.criteriaTabWrapper {
  padding-top: 20px;
}

.criteriaTab {
  display: flex;
  color: $black;
  gap: 20px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  &::after {
    content: '';
    width: 100%;
    border-bottom: 1px solid #c9d5e3;
    position: absolute;
    transform: translateY(46px);
  }

  .active {
    padding-bottom: 4px;
    border-bottom: 3px solid $emeraldColor;

    span {
      color: var(--light-primary-700, #007874);
      text-align: center;
      font-size: 14px;
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  span {
    color: #181818;
    text-align: center;
    font-size: 14px;
    font-family: $inter;
    line-height: 20px;
    position: relative;
  }

  .isMain {
    &::before {
      content: '';
      width: 5px;
      height: 5px;
      background: #763f98;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: -14px;
    }
  }
}

.criteriaTabContent {
  width: 100%;
}

.tooltipContent {
  font-family: $inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.0144em;
  color: #ffffff;
  padding: 10px;

  span {
    display: inline-block;
    font-weight: 700;
    margin-bottom: 5px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 20px;
  }
}
