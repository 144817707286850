@import '../../../assets/scss/variables';

.proposalWrap {
  padding: 30px;
  background-color: $white;
  border-radius: 6px;
  font-family: $inter;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .mainTitle {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-bottom: 30px;
  }
}

.proposalPositionList {
  .proposalItemContent {
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

.proposalItem {
  margin-bottom: 20px;
}

.proposalItemHead {
  h3 {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
}

.proposalItemContent {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 24px;
  background: #fff;
  border: 1px solid var(--Light, #dedede);
  border-radius: 8px;
  padding: var(--font-size-112-px, 12px) 24px;

  &.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .infoLeft {
    display: flex;
    width: 50%;
  }

  .avatar {
    margin-right: 20px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: solid 1px #dedede;
    }
  }

  .fieldItem {
    padding-left: 24px;
    width: 18%;
  }

  .fieldName {
    color: var(--Light-Primary-500, #00a19c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    margin-bottom: 5px;
  }

  .fieldNameDiff {
    border-radius: 4px;
    border: 1px dashed var(--color-border-disabled, #aabcd0);
    background: var(--color-background-weak, #f7fafd);
    display: flex;
    width: 320px;
    height: 28px;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    span {
      color: var(--Grays-500, #525252);
      text-align: center;
      font-family: $inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .fieldDesc {
    color: var(--Light-Blue-Grays-800, #262d36);
    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }

  .normalText {
    color: var(--Light-Blue-Grays-800, #262d36);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
  }

  .fieldLabel {
    color: var(--Light-Gray-500, #7a7a7a);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
  }

  .fieldValue {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .greyLabel {
    color: var(--Grays-500, #525252);
    font-family: $inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
  }

  .boldValue {
    color: var(--Light-Gray-800, #3d3d3d);
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 6.5px; /* 54.167% */
  }
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.listAction {
  display: flex;
  align-items: center;
}
