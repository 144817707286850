@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.btnRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.topControls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 36px;

  .rowItem {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title {
    color: var(--Emphasis-Body-N800, #3f3c4c);
    font-family: $inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    max-width: 920px;
    word-break: break-word;
  }

  .status {
    color: var(--Communication-Green-800, #007724);
    text-align: center;
    font-family: $inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.25px;
    background: #e5f4e7;
    border-radius: 4px;
    padding: 5px 10px;

    img {
      width: 14px;
      margin-right: 10px;
    }
  }
}
