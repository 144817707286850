@import '../../../../../assets/scss/variables';

.wrapper {
  font-family: $inter;
  padding: $pdcPrintPadding;
}

.title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  color: #3f3c4c;
  margin-bottom: 16px;
  align-items: center;
}
