@import '../../../../assets/scss/variables';

.talentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e7e7f0;
  width: $pdcCardWidth;
  background-color: $white;
  position: relative;

  .cardOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .info {
    .vacant {
      & > div:first-child {
        width: 302px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dashed #aabcd0;
        border-radius: 6px;
        background-color: #f7fafd;
        font-size: 10px;
        font-weight: 400;
        color: #525252;
        margin-bottom: 10px;
      }

      & > div:nth-child(2) {
        margin-bottom: 10px;
      }
    }

    .row1 {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      justify-content: space-between;

      .line2 {
        color: $teal;
        font-weight: 500;
        font-size: 10px;
      }

      .line3 {
        color: #3d3d3d;
        font-weight: 500;
        font-size: 10px;
      }

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      .basicInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .line1 {
          color: $teal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          gap: 6px;

          .recommendedIndex {
            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            background-color: #00807c;
            font-size: 12px;
            font-weight: 700;
            color: $white;
            border-radius: 50%;
          }

          .matchingPercentage {
            color: $teal;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .approvedTag {
          border-radius: 6px;
          background-color: #e0f3f3;
          width: 128px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 8px;
          font-weight: 500;
          color: #007874;
          margin-top: 6px;
        }
      }

      .btnRow {
        display: flex;
        gap: 10px;
        align-items: flex-start;

        img {
          width: 16px;
          height: 16px;
          padding: 2px;
        }
      }

      .additionalBtn {
        font-size: 14px;
        color: #181818;
      }
    }

    .row2 {
      border-radius: 6px;
      border: 1px solid #cbd6e2;
      background-color: #f7fafd;
      padding: 8px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      font-size: 10px;
      line-height: 12px;
      margin: 10px 0;

      span {
        color: #3d3d3d;
        font-weight: 700;
      }
    }

    .successorSection {
      font-size: 8px;
      font-weight: 500;
      color: #525252;
    }

    .successor {
      padding: 6px 8px;
      background-color: #e7f4e8;
      color: #0a7a29;
      font-size: 10px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      margin-top: 6px;
      position: relative;
      z-index: 10;

      span {
        background-color: #0a7a29;
        color: $white;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
      }

      img {
        width: 12px;
        height: 12px;
        padding: 2px;
        opacity: 0;
        transition-duration: 0.2s;
      }

      &:hover img {
        opacity: 1;
      }
    }
  }

  .additionalInput {
    padding: 8px;
    color: #394665;
    font-size: 12px;
    font-weight: 500;
    background-color: #fdd076;
    border-radius: 6px;
    margin-top: 8px;
    position: relative;
    padding-right: 30px;
    transform: all 0.2s;

    &:hover {
      .removeBtn {
        visibility: visible;
        opacity: 1;
      }
    }

    .removeBtn {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 7px;
      right: 7px;
      width: 20px;
      height: 20px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }

  .vacantJg {
    font-size: 10px;
    font-weight: 500;
    margin-top: 10px;
  }
}

.additionalModal {
  padding-top: 60px;

  .title {
    color: #3f3c4c;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.85px;
    text-align: center;
    margin-bottom: 20px;
  }
}
