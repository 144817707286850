@import '../../../../assets/scss/variables';

.label {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* gray/700 */

  color: #374151;
}
