#successorChart {
  .apexcharts-legend {
    width: 800px;
    margin: auto;
  }
  .apexcharts-legend-series {
    width: 250px;
  }
  .apexcharts-tooltip {
    padding: 2px 5px;
    height: 20px;
  }
}
