@import '../../../../assets/scss/mixin';
@import '../../../../assets/scss/variables';

.listContent {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.mainTitle {
  font-family: $inter;
  color: var(--Emphasis-High-N600, #787587);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
