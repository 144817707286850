@import '../../../../../assets/scss/variables';

.formWrapper {
  margin-bottom: 50px;
}

.labelRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: $teal;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 19px;
  position: relative;

  .title {
    position: absolute;
    top: -30px;
  }
  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
  }
}

.labelRowCustom {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.title {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #3f3c4c;
  margin-bottom: 76px;
}

.inputRow {
  border: 1px solid $geyser;
  border-top: none;
  margin-bottom: 0;
  padding: 13px;
  position: relative;
  .editBtn {
    padding: 9px;
    margin-top: 34px;
    margin-left: 29px;
    border-radius: 6px;
    border: 1px solid #e9edf0;
    cursor: pointer;
  }

  .textareaViewOnly {
    border: none;
    color: #3f3c4c;
    opacity: 1;
    cursor: initial;
  }
}

.inputRowBorder {
  position: relative;

  // &::after {
  //   border: 4px dashed #d3dee8;
  //   content: '';
  //   position: absolute;
  //   height: 70px;
  //   bottom: -70px;
  //   left: 162px;
  // }
}

.inputWrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 16px;

  .inputCell,
  .viewCell {
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 0 16px;
    width: 33.33%;
  }

  .viewCell {
    padding: 16px;
  }
}

.textareaField {
  width: 100%;
  border: none;
  color: #000;
  font-size: 14px;
  resize: none;
  word-break: break-word;
  white-space: pre-wrap;
  height: fit-content;
  padding: 0;
  position: static;

  &::placeholder {
    color: #7a7a7a;
  }

  &:hover,
  &:active,
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.mobilityPlanContent {
  display: flex;
  align-items: stretch;
  width: 100%;
}

// Recommendation Category
.recommendationCategoryWrapper {
  position: relative;

  :global {
    .ant-collapse-header {
      padding-bottom: 0 !important;
    }
    .ant-collapse-content,
    .ant-collapse-content-box {
      padding-top: 0 !important;
    }
  }
}
.recommendationCategory {
  margin-bottom: 24px;
}

.recommendationCategoryTitle {
  color: var(--Emphasis-Body-N800, #3f3c4c);
  font-family: $inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
}

.recommendationType,
.recommendationCategoryContent {
  display: grid;
  grid-template-columns: 50% auto;
  gap: 20px;
}

.recommendationType {
  margin-bottom: 30px;
}

.customLabel {
  color: var(--Base-Black, #181818);
  font-family: $inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: initial;

  :global {
    .ant-radio-checked {
      .ant-radio-inner {
        border-width: 5px !important;
        border-color: #00a19c !important;
        box-shadow: none !important;
        &::after {
          display: none !important;
        }
      }
    }
  }
}

.customNoteLabel {
  font-weight: 400;
}

.separatedLabel {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 2px dashed #fff;
  z-index: 1;
  right: 362px;
  background: transparent;
  transition: right 250ms;
}

.separatedContent {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 2px dashed #d6d6d6;
  z-index: 1;
  right: 362px;
  transition: right 250ms;
  background: transparent;
}
