@import '../../../../assets/scss/variables';
@import '../../../../assets/scss/mixin';

.detail_list_section {
  margin: 20px 0;
  font-family: $inter;
  letter-spacing: 0.3px;

  thead > tr > th {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #8896a7;

    &::before {
      content: none !important;
    }
  }
  .table_except_name {
    font-weight: 400;
    font-size: 13px;
    line-height: 15.73px;
    color: #999a9c;
  }
  .table_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: #3f3c4c;
  }
}

.action {
  font-family: $inter;
  font-weight: 600;
  font-size: 12px;
  width: 100px;
  height: 32px;

  background-color: #00a19c;
  color: #fff;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  &:active {
    transform: translateY(1px);
  }
}

.disabled {
  font-family: $inter;
  font-weight: 600;
  font-size: 12px;
  width: 100px;
  height: 32px;

  background-color: #e5e5e5;
  color: #b1b1b1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.avatar_wrapper {
  position: relative;
}
